import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, Input, Button, message, Upload } from "antd";
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import { UploadOutlined } from '@ant-design/icons';
import { VCSAPI } from "../../apis/VCSAPI";
import readXlsxFile from 'read-excel-file'

function ViewVCSImport() {

    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState([]);

    const [reports, setReports] = useState([]);
    const [diffCount, setDiffCount] = useState(0);
    const [compaireData, setCompaireData] = useState([]);

    const columns = [
        'Reference',
        'Name',
        'Goods',
        'BWP',
        'BP',
        'RC',
        'Response',
        'Authorision Date',
        'Settlement Date',
        'Settlement Delayed',
        'Email Address'
    ];

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "viewVCSImport.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    temp[columns[idx].name] = v
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `viewVCSImport`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const comp_columns = [
        'Policy Number',
        'Reference',
        'Payment Reference',
        'Name',
        'Goods',
        'BWP',
        'BP',
        'RC',
        'Response',
        'Authorision Date',
        'Settlement Date',
        'Settlement Delayed',
        'Email Address'
    ];

    const comp_options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        setRowProps: row => {
            console.log(row[1], row[1])
            if (row[1] != row[2]) {
                return { style: { background: "red" } };
            }
            // else {
            //     return { style: { background: "green" } };
            // }
        },
        downloadOptions: { filename: "compaireVCSImport.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    temp[columns[idx].name] = v
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `compaireVCSImport`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };


    const showReport = async () => {
        setLoading(true)
        await VCSAPI.getViewVCSXLSX()
            .then(res => {

                let data = [];

                res.data.records.forEach(report => {

                    let resData = [
                        report.reference,
                        report.name,
                        report.goods,
                        report.bwp,
                        report.bc,
                        report.rc,
                        report.response,
                        report.authorision_date != null ? moment(report.authorision_date).format('YYYY-MM-DD HH:mm') : '',
                        report.settlement_date != null ? moment(report.settlement_date).format('YYYY-MM-DD HH:mm') : '',
                        report.settlement_delayed,
                        report.email_address
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
            })
    }

    const handleChange = async (e) => {
        if (e.target.files[0] != undefined) {
            readXlsxFile(e.target.files[0]).then((rows) => {
                setSelectedFile(rows)
            })

            // const fileReader = new FileReader();
            // fileReader.readAsDataURL(e.target.files[0])
            // fileReader.onload = () => {
            //     // console.log(fileReader.result);
            //     setSelectedFile(fileReader.result)
            // }
            // fileReader.onerror = (error) => {
            //     console.log(error);
            // }
        } else {
            setSelectedFile([])
        }
    };

    const handleSubmit = async () => {
        setLoading(true)
        await VCSAPI.importVCSXLSX(selectedFile)
            .then(res => {
                setTimeout(() => {
                    showReport();
                    setLoading(false);
                }, 1000 * 10)
            })
    };

    const onClickCompaireData = async () => {
        setLoading(true)
        await VCSAPI.getViewVCSCcompaireData()
            .then(res => {

                let data = [];

                res.data.records.forEach(report => {

                    let resData = [
                        report.policyNumber,
                        report.reference,
                        report.referenceNumber,
                        report.name,
                        report.goods,
                        report.bwp,
                        report.bc,
                        report.rc,
                        report.response,
                        report.authorision_date != null ? moment(report.authorision_date).format('YYYY-MM-DD HH:mm') : '',
                        report.settlement_date != null ? moment(report.settlement_date).format('YYYY-MM-DD HH:mm') : '',
                        report.settlement_delayed,
                        report.email_address
                    ];

                    data = [resData, ...data]
                })
                setDiffCount(res.data.diffCount);
                setCompaireData(data);
            })

        setLoading(false)
    };

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content text-end">
                        {/* <Upload {...props}>
                            <Button icon={<UploadOutlined />}>Upload png only</Button>
                        </Upload> */}

                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Choose File</label>
                                <Input onChange={handleChange} type="file" accept=".xlsx, .xls" />
                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={handleSubmit}
                                        disabled={selectedFile.length == 0} className="btn btn-primary">Submit</Button>
                                </div>
                            </Col>

                            {
                                reports.length > 0 &&
                                <Col xs={24} lg={5} className="select_content">
                                    <label>&nbsp;</label>
                                    <div className="button_search_content">
                                        <Button onClick={onClickCompaireData}
                                            className="btn btn-primary">Compaire Data</Button>
                                    </div>
                                </Col>
                            }
                        </Row>
                    </div>

                    <MUIDataTable
                        title="VCS Import Data"
                        data={reports}
                        columns={columns}
                        options={options}
                    />

                    <br /><br />
                    {
                        compaireData.length > 0 &&
                        <MUIDataTable
                            title={`Compaire VCS Import Data [Different Count (${diffCount})]`}
                            data={compaireData}
                            columns={comp_columns}
                            options={comp_options}
                        />
                    }
                </div>
            </Spin>
        </Fragment>
    )
}

export default ViewVCSImport
