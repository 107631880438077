import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, Select, Button } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import moment from "moment";
import { useSelector } from "react-redux";
const { Option } = Select;

function ClaimReport() {
    const countryCode = useSelector(state => state.mainCountry)
    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);

    const [products, setProducts] = useState([]);
    const [claim_status, setClaimStatus] = useState([]);
    const [Caim_types, setClaimTypes] = useState([]);

    const [fProductID, setFProductID] = useState('-1');
    const [fClaimStatus, setFClaimStatus] = useState('-1');
    const [fClaimType, setFClaimType] = useState('-1');

    useEffect(async () => {
        setLoading(true)
        const data = {
            fProductID: fProductID,
            fClaimStatus: fClaimStatus,
            fClaimType: fClaimType,
            countryCode
        }

        await ReportAPI.getFetchClaimReport(data)
            .then(res => {
                setProducts(res.data.products);
                setClaimStatus(res.data.claimStatus);
                setClaimTypes(res.data.claimTypes);

                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });

        // await onClickFilter();

    }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "ClaimReport.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    temp[columns[idx].name] = v
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `ClaimReport`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "ID",
        "Policy Number",
        "Claim Number",
        "Customer ID",
        "Claim Type",
        "Status",
        "Created Date",
        "Created Time",
        "Cellphone",
        "Customer Name",
        "Total Reserves",
        "Total Payments"
    ];

    const onClickFilter = () => {
        const data = {
            fProductID: fProductID,
            fClaimStatus: fClaimStatus,
            fClaimType: fClaimType,
            countryCode
        }

        setLoading(true)
        ReportAPI.getFetchClaimReport(data)
            .then(res => {
                var data = [];
                res.data.records.forEach(report => {
                    let customerName = report.customerName?.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                        return letra.toUpperCase();
                    });
                    let resData = [
                        report.id,
                        report.policyNumber,
                        report.claim_number,
                        report.customer_id,
                        report.claim_type,
                        report.status,
                        moment(report.created_at).format('YYYY-MM-DD'),
                        moment(report.created_at).format('hh:mm A'),
                        report.cellphone,
                        customerName,
                        report.reserve_amt,
                        report.payment_amt
                    ];

                    data = [resData, ...data];
                });
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                console.log(error);
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Product</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setFProductID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    {
                                        products.map((product) => {
                                            return (
                                                <Option key={Math.random()} value={product.id}>{product.name}</Option>
                                            )
                                        })
                                    }
                                </Select>

                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Claim Status</label>
                                <Select style={{ width: '100%' }}
                                    onChange={(val) => setFClaimStatus(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    {
                                        claim_status.map((status) => {
                                            return (
                                                <Option key={Math.random()} value={status.status}>{status.status}</Option>
                                            )
                                        })
                                    }
                                </Select>

                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Claim Types</label>
                                <Select style={{ width: '100%' }}
                                    onChange={(val) => setFClaimType(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    {
                                        Caim_types.map((type) => {
                                            return (
                                                <Option key={Math.random()} value={type.claim_type}>{type.claim_type}</Option>
                                            )
                                        })
                                    }
                                </Select>

                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Claim Report"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default ClaimReport
