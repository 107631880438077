import axios from "axios";
import { Config } from "../Config";

export const AuthAPI = {
    login: async (data) => {
        
        // return await axios.post(`https://devgraphite.alphadirect.co.bw/api/createToken`, data);
        return await axios.post(`${Config.apiURL}/signIn`, data);
    },
    logout: () => {
        // const config = { headers: { Authorization: `Bearer ${getToken()}` } };
        // return Axios.get(BASE_API_URL + '/api/logout', config);
        return true;
    }
}