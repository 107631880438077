import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, DatePicker, Button } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'

const { RangePicker } = DatePicker;

function TransactionSummaryProductReport() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);

    const [fdateRange, setFdateRange] = useState([]);

    // useEffect(async () => {
    //     setLoading(true)
    //     await onClickFilter();
    // }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "TransactionSummaryProductReport.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    temp[columns[idx].name] = v
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `TransactionSummaryProductReport`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "PRODUCT NAME ",
        "CANCEL",
        "ENDORSE",
        "NEWBUSINESS",
        "RENEW",
        "TOTAL"
    ];

    const onClickFilter = () => {
        const data = { fdateRange: fdateRange }

        setLoading(true)
        ReportAPI.getTransactionSummaryProductReport(data)
            .then(res => {
                let data = [];

                res.data.records.forEach(report => {

                    let status = 'Deactive';
                    if (report.id !== null) {
                        if (report.status === 1) {
                            status = report.status;
                        }
                    }

                    let total = 0;
                    if (report.id != null) {
                        total = report.product_count + report.status;
                    }
                    let productName = report.productName.toLowerCase().replace(/\b[a-z]/g, function(letra) {
                        return letra.toUpperCase();
                    });

                    let resData = [
                        productName,
                        report.product_count,
                        0,
                        status,
                        0,
                        total
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date   </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Transaction Summary Product Report"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default TransactionSummaryProductReport
