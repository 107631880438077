import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, DatePicker, Button } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import { useSelector } from 'react-redux';
const { RangePicker } = DatePicker;

function CellphoneDevicePreInspectionReport() {
    const countryCode = useSelector(state => state.mainCountry);
    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);

    const [fdateRange, setFdateRange] = useState([]);

    // useEffect(async () => {
    //     setLoading(true)

    //     await onClickFilter();

    // }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "cellphoneDevicePreInspectionReport.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    if (columns[idx].name === 'Value') {
                        temp[columns[idx].name] = parseFloat(v);
                    } else {
                        temp[columns[idx].name] = v;
                    }
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `cellphoneDevicePreInspectionReport`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "Policy Number",
        "Status",
        "Customer Name",
        "Agent Name",
        "Agency Name",
        "Make",
        "Model",
        "Value",
        "Front",
        "Back",
        "Left",
        "Right",
        "Top",
        "Bottom"
    ];

    const onClickFilter = () => {
        console.log(countryCode);
        const data = { fdateRange: fdateRange, countryCode }

        setLoading(true)
        ReportAPI.getCellphoneDevicePreInspectionReports(data)
            .then(res => {
                let data = [];
                if (res.data.reports) {
                    res.data.reports.forEach(report => {

                        let customerName = report.customerName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });

                        let agentName = report.agentName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });

                        let resData = [
                            report.policyNumber,
                            report.status !== null ? report.status : 'NA',
                            customerName,
                            agentName,
                            report.agencyName ? report.agencyName : '-',
                            report.cell_phone_make,
                            report.cell_phone_model,
                            report.phone_value,
                            report.cell_phone_front ? 'Uploaded' : 'Not Uploaded',
                            report.cell_phone_back ? 'Uploaded' : 'Not Uploaded',
                            report.cell_phone_left ? 'Uploaded' : 'Not Uploaded',
                            report.cell_phone_right ? 'Uploaded' : 'Not Uploaded',
                            report.cell_phone_top ? 'Uploaded' : 'Not Uploaded',
                            report.cell_phone_bottom ? 'Uploaded' : 'Not Uploaded'
                        ];

                        data = [resData, ...data]
                    })
                }

                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date   </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Cellphone Device PreInspection Report"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default CellphoneDevicePreInspectionReport
