import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Col, Row, DatePicker, Button, Spin } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import { Config } from "../../../Config";
import { useSelector } from "react-redux";

const { RangePicker } = DatePicker;
export default function MotorComprehensiveReport() {
    const countryCode = useSelector(state => state.mainCountry)
    const [loading, setLoading] = useState(false);
    const [policies, setPolicies] = useState([]);
    const [fdateRange, setFdateRange] = useState([]);

    const [search, setSearch] = useState(null);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);

    const options = {
        selectableRows: 'none',
        // download: false,
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100, 200, 500],
        downloadOptions: { filename: "MotorComprehensiveReport.csv", separator: "," },
        elevation: 6,
        // onChangeRowsPerPage: (numberOfRows) => {
        //     loadData(numberOfRows, page, search)
        // },
        // serverSide: true,
        // count: Math.ceil(count),
        // page: page,
        // onTableChange: (action, tableState) => {
        //     switch (action) {
        //         case 'changePage':
        //             loadData(limit, tableState.page, search)
        //             break;
        //         case 'search':
        //             loadData(limit, tableState.page, tableState.searchText)
        //             break;
        //     }
        // },
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    if (columns[idx].name === 'Premium Charged' || columns[idx].name === 'Rate') {
                        temp[columns[idx].name] = parseFloat(v);
                    } else {
                        temp[columns[idx].name] = v;
                    }
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `MotorComprehensiveReport`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "Policy Number",
        "Customer Name",
        "Cellphone",
        "Date Of Birth",
        "Vehicle Registration",
        "Make",
        "Model",
        "Manufacturing Year",
        "Import/Local",
        "Value",
        "Frequency",
        "Premium Charged",
        "Rate",
        "Agent",
        "Agency Name",
        "Store",
        "Lead Source",
        "Policy Status",
        "KYC Status",
        "Payment Status",
        "Source Of Income",
        "Created Date",
        "Created Time"
    ];

    const loadData = async (limit, page, search) => {
        const data = {
            isDownload: 'no',
            search: search,
            limit: limit,
            page: page,
            fdateRange: fdateRange,
            countryCode
        }

        setSearch(search)
        setLimit(limit)
        setPage(page)
        setLoading(true)

        await ReportAPI.fetchFilterMotorComprehensiveReports(data)
            .then(res => {

                const policies = res.data.policies
                if (policies.length > 0) {
                    setCount(policies[0].totalCount)
                }

                let data = [];

                policies.forEach(policy => {

                    let sourceOfIncome = '';
                    if (policy.sourceOfIncome != '' && policy.sourceOfIncome != 'null') {
                        if (typeof policy.sourceOfIncome == 'string') {
                            if (policy.sourceOfIncome.substring(0, 1) == '{') {
                                policy.sourceOfIncome = JSON.parse(policy.sourceOfIncome)
                                let outerJSON = Object.keys(policy.sourceOfIncome)
                                if (typeof policy.sourceOfIncome == 'object') {
                                    outerJSON.forEach((oval) => {
                                        try {
                                            let innerJSON = Object.keys(policy.sourceOfIncome[oval]);
                                            innerJSON.forEach(ival => {
                                                sourceOfIncome += `#${oval} => ${ival}:${policy.sourceOfIncome[oval][ival]}; `;
                                            });
                                        } catch (err) {
                                            console.log(err)
                                        }
                                    });
                                }
                            }
                        }

                    } else {
                        sourceOfIncome = policy.sourceOfIncome;
                    }
                    let paymentStatus;
                    if (policy.paymentStatus === "SUCCESS" || policy.archivePaymentStatus == "SUCCESS") {
                        paymentStatus = "Payment Successful";
                    } else if (
                        policy.paymentStatus === "PENDING" || policy.paymentStatus === 'A'
                        || policy.archivePaymentStatus === "PENDING" || policy.archivePaymentStatus === 'A'
                    ) {
                        paymentStatus = "Payment Pending";
                    } else if (policy.paymentStatus === "PROCESSING" || policy.archivePaymentStatus === "PROCESSING") {
                        paymentStatus = "Payment Processing";
                    } else if (
                        policy.paymentStatus === "CANCELLED" || policy.paymentStatus === 'I'
                        || policy.archivePaymentStatus === "CANCELLED" || policy.archivePaymentStatus === 'I'
                    ) {
                        paymentStatus = "Payment Cancelled";
                    } else {
                        paymentStatus = 'Payment Unsuccessful';
                    }

                    let importstatus;
                    if (policy.importstatus) {
                        if (policy.importstatus === 1) {
                            importstatus = "Yes";
                        } else {
                            importstatus = 'No';
                        }
                    } else {
                        importstatus = 'N/A';
                    }

                    let policyStatus;
                    if (policy.policyStatus === 1) {
                        policyStatus = "Activated";
                    } else if (policy.policyStatus === 2) {
                        policyStatus = "Cancelled";
                    } else {
                        policyStatus = 'Deactivated';
                    }

                    let kycStatus;
                    if (policy.kycStatus != null) {
                        if (policy.kycStatus === 1)
                            kycStatus = 'Compliant';
                        else if (policy.kycStatus === 0)
                            kycStatus = 'Pending Verification';
                        else if (policy.kycStatus === 2)
                            kycStatus = 'Non-compliant';
                        else
                            kycStatus = 'Status not found';
                    } else {
                        kycStatus = 'N/A';
                    }

                    let freq;
                    if (policy.freq != null) {
                        if (policy.freq === "1")
                            freq = 'Monthly';
                        else if (policy.freq === "2")
                            freq = 'Three Instalments';
                        else if (policy.freq === "3")
                            freq = 'Annual';
                        else
                            freq = 'Frequency not described';
                    } else {
                        freq = 'Frequency not found';
                    }

                    if (policy.customerName !== 'N/A' && policy.customerName !== null) {
                        policy.customerName = policy.customerName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (policy.agentName !== 'N/A' && policy.agentName !== null) {
                        policy.agentName = policy.agentName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (policy.make !== 'N/A' && policy.make !== null) {
                        policy.make = policy.make.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (policy.model !== 'N/A' && policy.model !== null) {
                        policy.model = policy.model.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (policy.storeName !== 'N/A' && policy.storeName !== null) {
                        policy.storeName = policy.storeName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    let resData = [
                        policy.policyNumber,
                        policy.customerName ? policy.customerName : "N/A",
                        policy.cellphone ? policy.cellphone : "N/A",
                        policy.dob ? moment(policy.dob).format('DD-MM-YYYY') : "N/A",
                        policy.plate ? policy.plate : "N/A",
                        policy.make ? policy.make : "N/A",
                        policy.model ? policy.model : "N/A",
                        policy.vehicleyear ? policy.vehicleyear : "N/A",
                        importstatus,
                        policy.value ? policy.value : "N/A",
                        freq,
                        policy.premium ? policy.premium : "N/A",
                        policy.rate ? policy.rate : "N/A",
                        policy.agentName,
                        policy.agencyName ? policy.agencyName : "N/A",
                        policy.storeName ? policy.storeName : "N/A",
                        policy.leadSource ? policy.leadSource : "Not Found",
                        policyStatus,
                        kycStatus,
                        paymentStatus,
                        sourceOfIncome,
                        moment(policy.generated).format('DD-MM-YYYY'),
                        moment(policy.generated).format('HH:mm:ss')
                    ];

                    data = [resData, ...data]
                });

                setLoading(false)
                setPolicies(data)
            }).catch(error => {
                setLoading(false)
            });
    }

    // const onClickDownload = async () => {

    //     const reqData = {
    //         isDownload: 'yes',
    //         fdateRange: fdateRange
    //     }

    //     setLoading(true)
    //     ReportAPI.fetchFilterMotorComprehensiveReports(reqData)
    //         .then(res => {
    //             window.location.href = `${Config.apiNodeURL}/storage/MotorComprehensiveReport.xlsx`;
    //             setLoading(false);
    //         }).catch(error => {
    //             setLoading(false);
    //         });
    // }

    const onClickSerach = () => {
        loadData(limit, 0, search)
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date range </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>

                            <Col xs={12} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickSerach} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>

                            {/* <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickDownload} className="btn btn-primary">Download</Button>
                                </div>
                            </Col> */}
                        </Row>
                    </div>
                    <div className="muidTable">
                        <MUIDataTable
                            title={"Motor Comprehensive Report"}
                            data={policies}
                            columns={columns}
                            options={options}
                        />
                    </div>
                </div>
            </Spin>
        </Fragment>
    )
}
