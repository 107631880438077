import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, Select, Button } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'

const { Option } = Select;

function UserReportList() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [roles, setRoles] = useState([]);

    const [fPolicyStatus, setFPolicyStatus] = useState('-1');
    const [fRole, setFRole] = useState('-1');

    useEffect(async () => {
        setLoading(true)
        const data = {
            fPolicyStatus: fPolicyStatus,
            fRole: fRole
        }
        await ReportAPI.getUserReportLists(data)
            .then(res => {
                setRoles(res.data.roles)
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });

        // await onClickFilter();

    }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "UserReportList.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    temp[columns[idx].name] = v
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `UserReportList`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };


    const columns = [
        "Agency Name",
        "Agent ID",
        "Agent Names",
        "DOB",
        "Omang ID / Passport",
        // mang ID / passport , Address
        
        "Account ID",
        "Mobile",
        "FaxNo.",
        "Principle",
        "Principle Email",
        "Product",
        "UnderWriter",
        "ServiceRep",
        "Address",
        // "AddressLine2",
        "City",
        "Country",
        "PostaCode",
        "s_LicenseNo",
        "Email",
        "User Role",
        "Status",
    ];

    const onClickFilter = () => {
        const data = {
            fPolicyStatus: fPolicyStatus,
            fRole: fRole
        }

        setLoading(true)
        ReportAPI.getUserReportLists(data)
            .then(res => {
                let data = [];

                res.data.records.forEach(record => {

                    let status;
                    if (record.active == 1) {
                        status = 'Active';
                    } else if (record.active == 2) {
                        status = 'Suspended';
                    } else {
                        status = "Not-active";
                    }

                    if(record.userFullName != '' && record.userFullName != null){
                        record.userFullName = record.userFullName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    let idNumber = record.userOmang
                    if( record.userOmang == ''){
                        idNumber = record.userPassport 
                    }
                    let resData = [
                        record.agencyName,
                        // "ALPHA DIRECT INSURANCE CO (HQ)",
                        record.agentId,
                        record.userFullName,
                        record.dob ? moment(record.dob).format('DD-MM-YYYY') : '',
                        idNumber,
                        "ALPHA",
                        record.cellphone,
                        "(267) 392-8265",
                        "Arun Iyer",
                        "aiyer@alphadirect.co.bw",
                        "Domestic All Risk",
                        "Arun P. Iyer",
                        "Arun P. Iyer",
                        record.address ? record.address : 'NA',
                        // "NA",
                        "GABORONE",
                        "BOTSWANA",
                        "GABORONE",
                        "2/9/179",
                        record.email,
                        record.role_name !== null ? record.role_name : 'NA',
                        status
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>

                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Policy Status</label>
                                <Select style={{ width: '100%' }}
                                    onChange={(val) => setFPolicyStatus(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    <Option value="1">Active</Option>
                                    <Option value="2">Suspended</Option>
                                </Select>

                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Roles</label>
                                <Select style={{ width: '100%' }}
                                    onChange={(val) => setFRole(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    {
                                        roles.map((role) => {
                                            return (
                                                <Option key={Math.random()} value={role.id}>{role.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"User Report List"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default UserReportList
