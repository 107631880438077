import React, { Fragment, useState, useEffect } from "react";
import { Spin, Col, Row, DatePicker, Button, Table } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import generatePDF from "../../../services/PDFGenerator";

const { RangePicker } = DatePicker;

function ReinsuranceRiskProfilesSummary() {

    const [loading, setLoading] = useState(false);

    const [accidentalData, setAccidentalData] = useState([]);
    const [motorThirdPartyData, setMotorThirdPartyData] = useState([]);
    const [motorComprehensiveData, setMotorComprehensiveData] = useState([]);

    const [fdateRange, setFdateRange] = useState([]);

    useEffect(async () => {
        onClickFilter()
    }, []);

    const onClickFilter = async () => {
        const data = { fdateRange: fdateRange }
        setLoading(true)
        await ReportAPI.getReInsuranceRiskProfileSummary(data)
            .then(res => {

                let lifeData = [{
                    key: '0',
                    policycount: res.data.life.policy_count,
                    premium: res.data.life.premium,
                    claimcount: res.data.life.claim_count,
                    payments: res.data.life.payment ? `P ${res.data.life.payment}` : `P 0`,
                    totalreserve: res.data.life.reserve ? `P ${res.data.life.reserve}` : `P 0`
                }];

                setAccidentalData(lifeData);

                let vehicleData = [];

                res.data.vehicle.forEach((veh, i) => {

                    let resData = {
                        key: i,
                        riskband: veh.risk_band,
                        suminsured: veh.sum_assured.toFixed(2),
                        policycount: veh.policy_count,
                        premium: veh.premium,
                        claimcount: veh.claim_count,
                        payments: veh.payment ? `P ${veh.payment}` : `P 0`,
                        totalreserve: veh.reserve ? `P ${veh.reserve}` : `P 0`
                    }

                    vehicleData[i] = resData
                })

                setMotorThirdPartyData(vehicleData);

                let motorCompData = [];

                res.data.motorComp.forEach((comp, i) => {

                    let resData = {
                        key: i,
                        riskband: comp.risk_band,
                        suminsured: `P ${comp.sum_assured.toFixed(2)}`,
                        policycount: comp.policy_count,
                        premium: `P ${comp.premium}`,
                        claimcount: comp.claim_count,
                        payments: comp.payment ? `P ${comp.payment}` : `P 0`,
                        totalreserve: comp.reserve ? `P ${comp.reserve}` : `P 0`
                    }

                    motorCompData[i] = resData
                })

                setMotorComprehensiveData(motorCompData);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const accidentalColumns = [
        {
            title: 'Policy Count',
            dataIndex: 'policycount'
        },
        {
            title: 'Premium',
            dataIndex: 'premium'
        },
        {
            title: 'Claim Count',
            dataIndex: 'claimcount'
        },
        {
            title: 'Payments',
            dataIndex: 'payments'
        },
        {
            title: 'Total Reserve',
            dataIndex: 'totalreserve'
        },
    ];

    const motorThirdPartyColumns = [
        {
            title: 'Risk Band',
            dataIndex: 'riskband'
        },
        {
            title: 'Sum Insured',
            dataIndex: 'suminsured'
        },
        {
            title: 'Policy Count',
            dataIndex: 'policycount'
        },
        {
            title: 'Premium',
            dataIndex: 'premium'
        },
        {
            title: 'Claim Count',
            dataIndex: 'claimcount'
        },
        {
            title: 'Payments',
            dataIndex: 'payments'
        },
        {
            title: 'Total Reserve',
            dataIndex: 'totalreserve'
        },
    ];

    const motorComprehensiveColumns = [
        {
            title: 'Risk Band',
            dataIndex: 'riskband'
        },
        {
            title: 'Sum Insured',
            dataIndex: 'suminsured'
        },
        {
            title: 'Policy Count',
            dataIndex: 'policycount'
        },
        {
            title: 'Premium',
            dataIndex: 'premium'
        },
        {
            title: 'Claim Count',
            dataIndex: 'claimcount'
        },
        {
            title: 'Payments',
            dataIndex: 'payments'
        },
        {
            title: 'Total Reserve',
            dataIndex: 'totalreserve'
        },
    ];

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="form_wapper_content">
                    {/* <h3>Reinsurance Risk Profiles Summary</h3> */}

                    <Row>
                        <Col xs={24} lg={5} className="select_content">
                            <label >Filter By date   </label>
                            <RangePicker allowClear
                                format="YYYY-MM-DD"
                                onChange={
                                    (value, dateString) => {
                                        value !== null ? setFdateRange(dateString) : setFdateRange([])
                                    }
                                }
                            />
                        </Col>
                        <Col xs={24} lg={5} className="select_content">
                            <label>&nbsp;</label>
                            <div className="button_search_content">
                                <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                            </div>
                        </Col>

                        <Col xs={24} lg={24} className="select_content">
                            <div className="button_search_content" style={{ float: "left" }}>
                                <h3>Reinsurance Risk Profiles Summary</h3>
                            </div>
                            <div className="button_search_content" style={{ float: "right" }}>
                                <Button onClick={
                                    () => {
                                        let accidental = { accidentalColumns: accidentalColumns, accidentalData: accidentalData }
                                        let motorThirdParty = { motorThirdPartyColumns: motorThirdPartyColumns, motorThirdPartyData: motorThirdPartyData }
                                        let motorComprehensive = { motorComprehensiveColumns: motorComprehensiveColumns, motorComprehensiveData: motorComprehensiveData }
                                        generatePDF(accidental, motorThirdParty, motorComprehensive)
                                    }
                                } className="btn btn-primary">PDF+</Button>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <h3>Accidental</h3>

                        <Table columns={accidentalColumns} dataSource={accidentalData} pagination={false} />

                        <br /><br />
                        <h3>Motor Third Party</h3>

                        <Table columns={motorThirdPartyColumns} dataSource={motorThirdPartyData} pagination={false} />
                        <br /><br />
                        <h3>Motor Comprehensive</h3>

                        <Table columns={motorComprehensiveColumns} dataSource={motorComprehensiveData} pagination={false} />
                    </div>

                </div>
            </Spin>
        </Fragment>
    )
}

export default ReinsuranceRiskProfilesSummary
