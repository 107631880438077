import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Col, Row, DatePicker, Button, Spin, notification, Select } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import { Config } from "../../../Config";
import { useSelector } from "react-redux";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function TransactionReportByProduct() {
    const countryCode = useSelector(state => state.mainCountry)
    const [loading, setLoading] = useState(false);
    const [policies, setPolicies] = useState([]);
    const [genratedDateRange, setGenratedDateRange] = useState([]);

    const [fdateRange, setFdateRange] = useState([]);
    const [fPreDownloadReportID, setPreDownloadReportID] = useState('-1');

    const [search, setSearch] = useState(null);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);

    useEffect(() => {
        setLoading(true)
        const reportName = "Transaction Report By Product Quote";
        ReportAPI.fetchProducts(reportName, countryCode)
            .then(res => {
                // setProducts(res.data.products);
                setGenratedDateRange(res.data.genratedDateRange);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }, [])

    const options = {
        selectableRows: 'none',
        download: false,
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100, 500],
        downloadOptions: { filename: "TransactionReportByProductQuote.csv", separator: "," },
        elevation: 6,
        onChangeRowsPerPage: (numberOfRows) => {
            loadData(numberOfRows, page, search)
        },
        serverSide: true,
        count: Math.ceil(count),
        page: page,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    loadData(limit, tableState.page, search)
                    break;
                case 'search':
                    loadData(limit, tableState.page, tableState.searchText)
                    break;
            }
        },
        // onDownload: (buildHead, buildBody, columns, values) => {
        //     const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        //     const fileExtension = '.xlsx'
        //     // build the json, this is one way to do it
        //     const json = values.reduce((result, val) => {
        //         const temp = {}
        //         val.data.forEach((v, idx) => {
        //             temp[columns[idx].name] = v
        //         })
        //         result.push(temp)
        //         return result
        //     }, [])

        //     const fileName = `TransactionReportByProductQuote`
        //     const ws = utils.json_to_sheet(json)
        //     const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
        //     const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
        //     const data = new Blob([excelBuffer], { type: fileType })
        //     saveAs(data, fileName + fileExtension)
        //     // cancel default  CSV download from table
        //     return false
        // }
    };

    const columns = [
        "Policy Number",
        "Insured Name",
        "Transaction",
        "Renew. Plan",
        "Tran.Sub",
        "Service Rep",
        "Address",
        "County",
        "City",
        "State",
        "Cov. A",
        "Term Start",
        "Term End",
        "Act.Date",
        "Inforce",
        "Pre.Change",
        "UpdatedDate",
        "Note",
        "Anniversary Start",
        "Anniversary End",
        "AnniversarySeque",
        "TermResetCounter"
    ];

    const loadData = async (limit, page, search) => {
        const data = {
            search: search,
            limit: limit,
            page: page,
            fdateRange: fdateRange,
            isDownload: 'no',
            countryCode
        }

        setSearch(search)
        setLimit(limit)
        setPage(page)
        setLoading(true)

        ReportAPI.fetchFilterTransactionReportByProduct(data)
            .then(res => {

                const policies = res.data.policies
                let data = [];

                if (policies.length > 0) {
                    setCount(policies[0].totalCount)
                }

                policies.forEach((policy, i) => {

                    let freq;
                    if (policy.premium_freq === 1) {
                        freq = 'Monthly';
                    } else if (policy.premium_freq === 2) {
                        freq = 'Three Instalsments';
                    } else {
                        freq = 'Annual';
                    }

                    let trans_type = moment().diff(policy.created_at, 'years', false)
                    if (trans_type === 0) {
                        trans_type = "New Business";
                    } else {
                        trans_type = "Renew";
                    }

                    let trans_sub_type;

                    if (policy.invoice_no) {
                        let trans_sub_type = policy.invoice_no.substr(1);
                        if (trans_sub_type === '001') {
                            trans_sub_type = 'Agent Business';
                        } else if (trans_sub_type !== '001') {
                            trans_sub_type = 'Renewal';
                        } else {
                            trans_sub_type = 'NonPay';
                        }
                    } else {
                        trans_sub_type = 'NonPay';
                    }

                    let term_end;
                    if (policy.billingStartDate === 'N/A') {
                        term_end = 'N/A';
                    } else if (policy.premium_freq > 1) {
                        term_end = moment(policy.billingStartDate, 'YYYY-MM-DD').add(1, 'years').subtract(1, 'days').format('DD-MM-YYYY');
                    } else {
                        term_end = moment(policy.billingStartDate, 'YYYY-MM-DD').add(1, 'months').subtract(1, 'days').format('DD-MM-YYYY');
                    }

                    let resData = [
                        policy.policyNumber,
                        policy.customerName,
                        trans_type,
                        freq,
                        trans_sub_type,
                        policy.service_rep,
                        policy.address,
                        policy.country,
                        policy.city,
                        policy.state,
                        policy.policy_ledger_id !== '' ? '-' : '',
                        policy.billingStartDate === 'N/A' ? 'N/A' : moment(policy.billingStartDate, 'DD-MM-YYYY').format('DD-MM-YYYY'),
                        term_end,
                        policy.policy_ledger_id !== '' ? 'N/A' : '',
                        policy.status === 1 ? 'Yes' : 'No',
                        policy.policy_ledger_id !== '' ? 'N/A' : '',
                        policy.updated_at === null ? 'N/A' : moment(policy.updated_at, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                        policy.note,
                        policy.created_at === null ? 'N/A' : moment(policy.created_at).format('DD-MM-YYYY'),
                        policy.created_at === null ? 'N/A' : moment(policy.created_at, 'YYYY-MM-DD').add(1, 'years').subtract(1, 'days').format('DD-MM-YYYY'),
                        policy.policy_ledger_id !== '' ? '-' : '',
                        policy.policy_ledger_id !== '' ? 'N/A' : ''

                    ];

                    data = [resData, ...data]
                });

                setLoading(false)
                setPolicies(data)
            }).catch(error => {
                setLoading(false)
            });
    }

    const onClickDownload = async () => {
        if (fdateRange.length == 0) {
            return notification.error({ message: "Please select the date." });
        }

        const currenFinancialDate = moment(Config.currentFinancialDate)
        const selectedStartDate = moment(fdateRange[0]);
        const selectedEndDate = moment(fdateRange[1]);

        if (
            (currenFinancialDate >= selectedStartDate && currenFinancialDate > selectedEndDate)
            || (currenFinancialDate <= selectedStartDate && currenFinancialDate < selectedEndDate)
        ) {
            const reqData = {
                fdateRange: fdateRange,
                isDownload: 'yes',
                countryCode
            }

            setLoading(true);
            await ReportAPI.fetchFilterTransactionReportByProduct(reqData)
                .then(res => {
                    window.location.href = `${Config.apiNodeURL}/${res.data.filePath}`;

                    setLoading(false);
                }).catch(error => {
                    setLoading(false);
                });
        } else {
            return notification.error({ message: "Please select the date range between financial year." });
        }
    }

    const onClickPreDownload = async () => {
        if (fPreDownloadReportID == '-1') {
            return notification.error({ message: "Please select the Pre Generated Report." });
        }
        const reqData = {
            fPreDownloadReportID,
            isPreDownload: 'yes'
        }

        setLoading(true);

        await ReportAPI.fetchFilterTransactionReportByProduct(reqData)
            .then(res => {
                window.location.href = `${Config.apiNodeURL}/${res.data.filePath}`;

                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickSerach = () => {
        if (fdateRange.length == 0) {
            return notification.error({ message: "Please select the date." });
        }

        const currenFinancialDate = moment(Config.currentFinancialDate)
        const selectedStartDate = moment(fdateRange[0]);
        const selectedEndDate = moment(fdateRange[1]);

        if (
            (currenFinancialDate >= selectedStartDate && currenFinancialDate > selectedEndDate)
            || (currenFinancialDate <= selectedStartDate && currenFinancialDate < selectedEndDate)
        ) {
            loadData(limit, 0, search)
        } else {
            return notification.error({ message: "Please select the date range between financial year." });
        }
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Pre-Generated Report</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setPreDownloadReportID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content"
                                >
                                    <Option value="-1">Select Report Range</Option>
                                    {
                                        genratedDateRange.map((dr) => {
                                            return (
                                                <Option key={Math.random()} value={dr.id}>{`${dr.start_date} - ${dr.end_date}`}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col>
                                <div className="button_search_content">
                                    <Button onClick={onClickPreDownload} className="btn btn-success">Download Generated</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date range </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>

                            <Col xs={24} lg={1} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickSerach} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>

                            <Col xs={24} lg={1} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickDownload} className="btn btn-primary">Download</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Transaction Report By Product Quote"}
                        data={policies}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}
