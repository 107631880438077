import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, DatePicker, Button, notification } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import moment from "moment";


const { RangePicker } = DatePicker;

function RevioUpload() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);

    const [fdateRange, setFdateRange] = useState([]);

    // useEffect(async () => {
    //     setLoading(true)

    //     await onClickFilter();

    // }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "RevioUpload.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    temp[columns[idx].name] = v
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `RevioUpload`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "id",
        "email",
        "created_at",
        "updated_at",
        "brand_name",
        "product_reference",
        "product_status",
        "product_inception_date",
        "product_name",
        "full_name",
        "street_address",
        "city",
        "country",
        "zip_code",
        "personal_code",
        "phone",
        "recurring_frequency",
        "recurring_day",
        "recurring_amount",
        "account_balance",
        "unsubscribed",
        "allow_dunning",
        "allow_downgrade",
        "allow_reinstatement",
        "allow_email",
        "allow_sms",
        "allow_whatsapp",
        "whatsapp_opt_in",
        "language_IETF_code",
        "timezone"
    ];

    const onClickFilter = () => {
        if (fdateRange.length == 0) {
            return notification.error({ message: "Please select the date." });
        }

        const data = { fdateRange: fdateRange }

        setLoading(true)
        ReportAPI.getRevioUpload(data)
            .then(res => {
                let data = [];

                res.data.records.forEach(record => {

                    if (record.full_name) {
                        record.full_name = record.full_name.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (record.email) {
                        record.email = record.email.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    let resData = [
                        record.id,
                        record.email,
                        moment(record.created_at).unix(),
                        moment(record.updated_at).unix(),
                        record.brand_name,
                        // "Revio Insure",
                        record.product_reference,
                        record.product_status,
                        record.product_inception_date ? moment(record.product_inception_date).unix() : '',
                        record.product_name,

                        record.full_name,
                        record.street_address,
                        record.city,
                        record.country ? record.country : 'Botswana',
                        "00000",
                        record.personal_code,
                        record.phone,
                        // record.recurring_frequency,
                        "monthly",
                        record.recurring_day ? moment(record.recurring_day).format('DD') : '',
                        record.recurring_amount,
                        record.account_balance || 0,
                        "FALSE",
                        "TRUE",
                        "FALSE",
                        "FALSE",
                        "TRUE",
                        "TRUE",
                        "TRUE",
                        "TRUE",
                        "en",
                        "Africa/Johannesburg"
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date   </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Revio Upload"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default RevioUpload
