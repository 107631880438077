import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, DatePicker, Select, Button, notification } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import moment from "moment";
import { Config } from "../../../Config";

const { Option } = Select;
const { RangePicker } = DatePicker;

function TransactionReportRealpay() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [genratedDateRange, setGenratedDateRange] = useState([]);

    const [fdateRange, setFdateRange] = useState([]);
    const [fPolicyStatus, setFPolicyStatus] = useState('-1');
    const [fPreDownloadReportID, setPreDownloadReportID] = useState('-1');

    const [search, setSearch] = useState(null);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);

    useEffect(() => {
        setLoading(true)
        const reportName = "Realpay Transaction Report";
        ReportAPI.fetchProducts(reportName)
            .then(res => {
                // setProducts(res.data.products);
                setGenratedDateRange(res.data.genratedDateRange);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }, [])

    const options = {
        selectableRows: 'none',
        download: false,
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "RealpayTransactionReport.csv", separator: "," },
        elevation: 6,
        onChangeRowsPerPage: (numberOfRows) => {
            loadData(numberOfRows, page, search)
        },
        serverSide: true,
        count: Math.ceil(count),
        page: page,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    loadData(limit, tableState.page, search)
                    break;
                case 'search':
                    loadData(limit, tableState.page, tableState.searchText)
                    break;
            }
        },
        // onDownload: (buildHead, buildBody, columns, values) => {
        //     const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        //     const fileExtension = '.xlsx'
        //     // build the json, this is one way to do it
        //     const json = values.reduce((result, val) => {
        //         const temp = {}
        //         val.data.forEach((v, idx) => {
        //             if (columns[idx].name === 'First Premium' || columns[idx].name === 'Premium') {
        //                 temp[columns[idx].name] = parseFloat(v);
        //             } else {
        //                 temp[columns[idx].name] = v;
        //             }
        //         })
        //         result.push(temp)
        //         return result
        //     }, [])

        //     const fileName = `RealpayTransactionReport`
        //     const ws = utils.json_to_sheet(json)
        //     const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
        //     const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
        //     const data = new Blob([excelBuffer], { type: fileType })
        //     saveAs(data, fileName + fileExtension)
        //     // cancel default  CSV download from table
        //     return false
        // }
    };

    const columns = [
        "ID",
        "Policy Number",
        "Reference Number",
        "Contract Number",
        "Product",
        "Plan",
        "First Premium",
        "Premium",
        "Frequency",
        "Agent",
        "Agency Name",
        "Store",
        "Billing Day",
        "Billing Date",
        "Policy Status",
        "Payment Status"
    ];

    const loadData = async (limit, page, search) => {
        const data = {
            search: search,
            limit: limit,
            page: page,
            fdateRange: fdateRange,
            fPolicyStatus: fPolicyStatus,
            isDownload: 'no'
        }

        setSearch(search)
        setLimit(limit)
        setPage(page)
        setLoading(true)

        ReportAPI.getTransactionReportRealpay(data)
            .then(res => {
                let data = [];
                const records = res.data.records

                if (records.length > 0) {
                    setCount(records[0].totalCount)
                }
                records.forEach(report => {

                    let premium_freq;
                    if (parseInt(report.premium_freq) === 1) {
                        premium_freq = 'Monthly';
                    } else if (parseInt(report.premium_freq) === 2) {
                        premium_freq = 'Three Installments';
                    } else if (parseInt(report.premium_freq) === 3) {
                        premium_freq = 'Annual';
                    } else {
                        premium_freq = 'Frequency not described';
                    }

                    let payment = '';
                    if (report.paymentStatusCount === 0) {
                        payment = 'Failed';
                    } else {
                        payment = `Active: ${report.activePaymentStatusCount},\n Success: ${report.successPaymentStatusCount},\n Failed: ${report.failedsPaymentStatusCount}`;
                    }

                    let product_name = report.product_name.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                        return letra.toUpperCase();
                    });
                    if (report.agentName !== '-' && report.agentName !== null) {
                        report.agentName = report.agentName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    let resData = [
                        report.id,
                        report.policyNumber,
                        report.policyNumber,
                        report.policy_id,
                        product_name,
                        report.product_plans_name,
                        report.first_premium !== null ? report.first_premium : '-',
                        report.premium_value !== null ? report.premium_value : '-',
                        premium_freq,
                        report.agentName,
                        report.agencyName ? report.agencyName : "-",
                        report.store_name !== null ? report.store_name : '-',
                        report.billing_day !== null ? report.billing_day : '-',
                        report.billingStartDate !== null ? moment(report.billingStartDate).format('DD-MM-YYYY') : '-',
                        report.policy_status,
                        payment
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickDownload = async () => {

        const reqData = {
            fdateRange: fdateRange,
            fPolicyStatus: fPolicyStatus,
            isDownload: 'yes'
        }

        setLoading(true);
        await ReportAPI.getTransactionReportRealpay(reqData)
            .then(res => {
                window.location.href = `${Config.apiNodeURL}/${res.data.filePath}`;

                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickPreDownload = async () => {
        if (fPreDownloadReportID == '-1') {
            return notification.error({ message: "Please select the Pre Generated Report." });
        }
        const reqData = {
            fPreDownloadReportID,
            isPreDownload: 'yes'
        }

        setLoading(true);

        await ReportAPI.getTransactionReportRealpay(reqData)
            .then(res => {
                window.location.href = `${Config.apiNodeURL}/${res.data.filePath}`;

                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickSerach = () => {
        loadData(limit, 0, search)
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Pre-Generated Report</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setPreDownloadReportID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content"
                                >
                                    <Option value="-1">Select Report Range</Option>
                                    {
                                        genratedDateRange.map((dr) => {
                                            return (
                                                <Option key={Math.random()} value={dr.id}>{`${dr.start_date} - ${dr.end_date}`}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col>
                                <div className="button_search_content">
                                    <Button onClick={onClickPreDownload} className="btn btn-success">Download Generated</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Policy Status</label>
                                <Select style={{ width: '100%' }}
                                    onChange={(val) => setFPolicyStatus(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    <Option value="0">Deactivated</Option>
                                    <Option value="1">Activated</Option>
                                    <Option value="2">Cancel</Option>
                                </Select>

                            </Col>
                            <Col xs={24} lg={1} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickSerach} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>

                            <Col xs={24} lg={1} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickDownload} className="btn btn-primary">Download</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Realpay Transaction Report"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default TransactionReportRealpay
