export const Token = {
    saveToken: (data) => {
        setToken(data.token);
        setUserID(data.user_id);
        setAgencyID(data.agency_id);
        storeFullName(data.name);
        storeUserRole(data.role_name);
        storeAccessAll(data.access_all);
    },
    getUserID: () => {
        return getUserID()
    },
    getAgencyID: () => {
        return getAgencyID()
    },
    getUserRole: () => {
        return getUserRole()
    },
    getAccessAll: () => {
        return getAccessAll()
    },
    isLoggedIn: () => {
        if (getToken()) {
            return true;
        }
        return false;
    },
    clearStorage: () => {
        return localStorage.clear();
    },
    setMainCountry: (country) => {
        setMainCountry(country)
    }
}

export const setToken = (token) => {
    localStorage.setItem('token', token);
}

export const getToken = () => {
    return localStorage.getItem('token');
}

const setUserID = (user_id) => {
    localStorage.setItem('user_id', user_id);
}

const getUserID = () => {
    return localStorage.getItem('user_id');
}

const setAgencyID = (agency_id) => {
    localStorage.setItem('agency_id', agency_id);
}

const getAgencyID = () => {
    return localStorage.getItem('agency_id');
}

export const storeFullName = (name) => {
    localStorage.setItem('full_name', name);
}

export const getFullName = () => {
    return localStorage.getItem('full_name');
}

export const storeUserRole = (role) => {
    localStorage.setItem('user_role', role);
}

export const getUserRole = () => {
    return localStorage.getItem('user_role');
}

export const storeAccessAll = (access_all) => {
    localStorage.setItem('access_all', access_all);
}

export const getAccessAll = () => {
    return localStorage.getItem('access_all');
}

export const setMainCountry = (country) => {
    localStorage.setItem('country', country);
}

export const getMainCountry = () => {
    return localStorage.getItem('country');
}