import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, Select, Button, DatePicker } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'

const { Option } = Select;
const { RangePicker } = DatePicker;

function KYCReportActivatedPolicies() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [products, setProducts] = useState([]);

    const [fProductIds, setFProductIds] = useState([]);
    const [fdateRange, setFdateRange] = useState([]);

    useEffect(async () => {
        setLoading(true)
        // await onClickFilter();
        ReportAPI.fetchProducts()
            .then(res => {
                setProducts(res.data.products);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });

    }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "KYCReportActivatedPolicies.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    temp[columns[idx].name] = v
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `KYCReportActivatedPolicies`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "Policy Number",
        "Customer Name",
        "Product Name",
        "Policy Status",
        "KYC Status",
        "Compliance",
        "Id Number",
        "Remark",
        "Reason"
    ];

    const onClickFilter = () => {
        const data = {
            fdateRange: fdateRange,
            fProductIds: fProductIds
        }
        setLoading(true)
        ReportAPI.getKYCReportActivatedPolicies(data)
            .then(res => {
                let data = [];

                res.data.reports.forEach(report => {

                    if (report.Customer) {
                        report.Customer = report.Customer.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (report.customer_kyc_status) {
                        report.customer_kyc_status = report.customer_kyc_status.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (report.productID == '2') {
                        if (
                            report.omang == '' ||
                            report.omangBack == '' ||
                            report.passport == '' ||
                            report.proof_residence == '' ||
                            report.driving_license == ''
                        ) {
                            return;
                        }

                    } else if (report.productID == '3') {
                        if (
                            report.omang == '' ||
                            report.omangBack == '' ||
                            report.passport == '' ||
                            report.proof_residence == '' ||
                            report.proof_income == '' ||
                            report.driving_license == ''
                        ) {
                            return;
                        }
                    } else {
                        if (
                            report.omang == '' ||
                            report.omangBack == '' ||
                            report.passport == ''
                        ) {
                            return;
                        }
                    }

                    let idNumber = report.omangNumber;
                    if(report.omangNumber == ''){
                        idNumber = report.passportNumber
                    }

                    let resData = [
                        report.policyNumber,
                        report.Customer,
                        report.productName,
                        report.policyStatus,
                        report.customer_kyc_status,
                        report.compliance,
                        idNumber,
                        report.customer_kyc_remark,
                        report.customer_kyc_reason
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false)
            }).catch(error => {
                setLoading(false)
            });

    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>

                            <Col xs={24} lg={4} className="select_content">
                                <label>Select Product</label>
                                <Select style={{ width: '100%' }}
                                    mode="multiple"
                                    allowClear
                                    onChange={(val) => setFProductIds(val)}
                                    // defaultValue="-1"
                                    optionFilterProp="children"
                                    className="select-content">
                                    {/* <Option value="-1">All</Option> */}
                                    {
                                        products.map(product => {
                                            return (<Option key={product.id} value={product.id}>{product.name}</Option>)
                                        })
                                    }
                                </Select>
                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"KYC Reoport (Activated Policies)"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default KYCReportActivatedPolicies
