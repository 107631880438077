import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, DatePicker, Select, Button, notification } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import { Config } from "../../../Config";
import { useSelector } from "react-redux"
const { Option } = Select;
const { RangePicker } = DatePicker;

function PaymentTransactionReport() {
    const countryCode = useSelector(state => state.mainCountry)
    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [products, setProducts] = useState([]);
    const [genratedDateRange, setGenratedDateRange] = useState([]);

    const [fdateRange, setFdateRange] = useState([]);
    const [fPaymnetMethod, setFPaymnetMethod] = useState('-1');
    const [fPaymnetStatus, setFPaymnetStatus] = useState('-1');
    const [fProductID, setFProductID] = useState('-1');
    const [fPreDownloadReportID, setPreDownloadReportID] = useState('-1');

    const [search, setSearch] = useState(null);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);

    useEffect(async () => {
        setLoading(true)
        const reportName = "Payment Transactions"
        ReportAPI.fetchProducts(reportName, countryCode)
            .then(res => {
                setProducts(res.data.products);
                setGenratedDateRange(res.data.genratedDateRange);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }, []);

    const options = {
        selectableRows: 'none',
        download: false,
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: limit,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "paymentTansactionList.csv", separator: "," },
        elevation: 6,
        onChangeRowsPerPage: (numberOfRows) => {
            loadData(numberOfRows, page, search)
        },
        serverSide: true,
        //count, // Use total number of items
        count: Math.ceil(count), // Unknown number of items
        page: page,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    loadData(limit, tableState.page, search)
                    break;
                case 'search':
                    loadData(limit, tableState.page, tableState.searchText)
                    break;
            }
        },
        onDownload: (buildHead, buildBody, columns, values) => {

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    if (columns[idx].name === 'Amount') {
                        temp[columns[idx].name] = parseFloat(v);
                    } else {
                        temp[columns[idx].name] = v;
                    }
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `paymentTansactionList`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "Policy Number",
        "Customer",
        "Agent Name",
        "Agency Name",
        "Product Name",
        "Reference Number",
        "Amount",
        "Status",
        "Reason",
        "Payment Date",
        "Payment Settlement Date",
        "Payment Method",
        "FIRST/RECURRING"
    ];

    const loadData = async (limit, page, search) => {
        const data = {
            search: search,
            limit: limit,
            page: page,
            fdateRange: fdateRange,
            fPaymnetMethod: fPaymnetMethod,
            fPaymnetStatus: fPaymnetStatus,
            fProductID: fProductID,
            isDownload: 'no',
            countryCode
        }

        setSearch(search)
        setLimit(limit)
        setPage(page)
        setLoading(true)
        ReportAPI.getPaymentTransaction(data)
            .then(res => {
                const records = res.data.records
                if (records.length > 0) {
                    setCount(records[0].totalCount)
                }

                let data = [];
                let fr = [];
                res.data.records.forEach(report => {

                    let frStatus = null;
                    if (report.status == 'Success' || report.status == 'SUCCESS' || report.status == 'S') {
                        // if ((!fr[report.policyNumber] && report.F_PID == report.id) || report.amount == '1.00') {
                        //     fr[report.policyNumber] = 'FIRST';
                        // } else {
                        //     fr[report.policyNumber] = 'RECURRING';
                        // }

                        if (report.amount == '1' || report.amount == '1.0' || report.amount == '1.00') {
                            frStatus = 'FIRST';
                        } else {
                            frStatus = 'RECURRING';
                        }

                    } else if (frStatus) {
                        frStatus = null;
                    }

                    if (report.customerName !== 'N/A' && report.customerName !== null) {
                        report.customerName = report.customerName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (report.agentName) {
                        report.agentName = report.agentName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (report.agencyName) {
                        report.agencyName = report.agencyName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    let resData = [
                        report.policyNumber,
                        report.customerName,
                        report.agentName,
                        report.agencyName,
                        report.productName,
                        report.referenceNumber,
                        report.amount,
                        report.status,
                        (report.status == 'Failed' || report.status == 'FAILED') ? report.note : '',
                        // report.paymentDate ? moment(report.paymentDate).format('DD-MM-YYYY') : '-',
                        // report.paymentDate,
                        report.new_payment_date ? moment(report.new_payment_date).format('DD-MM-YYYY') : '-',
                        report.paymentSettlementDate ? moment(report.paymentSettlementDate).format('DD-MM-YYYY') : '-',
                        report.paymentMethod,
                        // fr[report.policyNumber] ? fr[report.policyNumber] : "-"
                        frStatus ? frStatus : "-"
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickDownload = async () => {
        if (fdateRange.length == 0) {
            return notification.error({ message: "Please select the date." });
        }

        const currenFinancialDate = moment(Config.currentFinancialDate)
        const selectedStartDate = moment(fdateRange[0]);
        const selectedEndDate = moment(fdateRange[1]);

        if (
            (currenFinancialDate >= selectedStartDate && currenFinancialDate > selectedEndDate)
            || (currenFinancialDate <= selectedStartDate && currenFinancialDate < selectedEndDate)
        ) {
            const reqData = {
                fdateRange: fdateRange,
                fPaymnetMethod: fPaymnetMethod,
                fPaymnetStatus: fPaymnetStatus,
                fProductID: fProductID,
                isDownload: 'yes',
                countryCode
            }

            setLoading(true);
            await ReportAPI.getPaymentTransaction(reqData)
                .then(res => {
                    window.location.href = `${Config.apiNodeURL}/${res.data.filePath}`;
                    setLoading(false);
                }).catch(error => {
                    setLoading(false);
                });
        } else {
            return notification.error({ message: "Please select the date range between financial year." });
        }
    }

    const onClickPreDownload = async () => {
        if (fPreDownloadReportID == '-1') {
            return notification.error({ message: "Please select the Pre Generated Report." });
        }
        const reqData = {
            fPreDownloadReportID,
            isPreDownload: 'yes',
            countryCode
        }

        setLoading(true);

        await ReportAPI.getPaymentTransaction(reqData)
            .then(res => {
                window.location.href = `${Config.apiNodeURL}/${res.data.filePath}`;

                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickSerach = () => {
        if (fdateRange.length == 0) {
            return notification.error({ message: "Please select the date." });
        }

        const currenFinancialDate = moment(Config.currentFinancialDate)
        const selectedStartDate = moment(fdateRange[0]);
        const selectedEndDate = moment(fdateRange[1]);

        if (
            (currenFinancialDate >= selectedStartDate && currenFinancialDate > selectedEndDate)
            || (currenFinancialDate <= selectedStartDate && currenFinancialDate < selectedEndDate)
        ) {
            loadData(limit, 0, search)
        } else {
            return notification.error({ message: "Please select the date range between financial year." });
        }
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Pre-Generated Report</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setPreDownloadReportID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content"
                                >
                                    <Option value="-1">Select Report Range</Option>
                                    {
                                        genratedDateRange.map((dr) => {
                                            let isInceptionReport = ''
                                            if (dr.is_inception_to_till_date == 1) {
                                                isInceptionReport = '(Current Financial)'
                                            }

                                            return (
                                                <Option key={Math.random()} value={dr.id}>{`${dr.start_date} - ${dr.end_date} ${isInceptionReport}`}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col lg={4}>
                                <div className="button_search_content">
                                    <Button onClick={onClickPreDownload} className="btn btn-success">Download Generated</Button>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="button_search_content">
                                    <Button onClick={() => {
                                        genratedDateRange.map((dr) => {
                                            if (dr.is_inception_to_till_date == 1) {
                                                window.location.href = `${Config.apiNodeURL}/${dr.report_path}`;
                                            }
                                        })
                                    }} className="btn btn-primary">Download From 2022-07-01 to Till Date</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By Payment Date </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Payment Method</label>
                                <Select style={{ width: '100%' }}
                                    onChange={(val) => setFPaymnetMethod(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    <Option value="CASH">CASH</Option>
                                    <Option value="orangeMoney">orangeMoney</Option>
                                    <Option value="RealPay">RealPay</Option>
                                    <Option value="VCS">VCS</Option>
                                    <Option value="DPO">DPO</Option>
                                </Select>
                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label>Payment Status</label>
                                <Select style={{ width: '100%' }}
                                    onChange={(val) => setFPaymnetStatus(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    <Option value="Success">Success</Option>
                                    <Option value="Failed">Failed</Option>
                                </Select>
                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Product </label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setFProductID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    {
                                        products.map((product) => {
                                            return (
                                                <Option key={Math.random()} value={product.id}>{product.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>

                            <Col xs={12} lg={1} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickSerach} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>
                            <Col xs={12} lg={1} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickDownload} className="btn btn-primary">Download</Button>
                                </div>
                            </Col>
                        </Row>
                        {/* <Row>
                        </Row> */}
                    </div>
                    <MUIDataTable
                        title={"Payment Transactions"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment >
    )
}

export default PaymentTransactionReport
