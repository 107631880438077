import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, DatePicker, Button, Select, notification } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'

const { Option } = Select;
const { RangePicker } = DatePicker;

function PaymentReport() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [products, setProducts] = useState([]);

    const [fdateRange, setFdateRange] = useState([]);
    const [fProductID, setFProductID] = useState('-1');

    useEffect(async () => {
        setLoading(true)
        ReportAPI.fetchProducts()
            .then(res => {

                setProducts(res.data.products);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "PaymentReport.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    if (columns[idx].name === 'Total Amount') {
                        temp[columns[idx].name] = parseFloat(v);
                    } else {
                        temp[columns[idx].name] = v;
                    }
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `PaymentReport`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "Policy Number",
        "Total Amount"
    ];

    const onClickFilter = () => {
        if (fdateRange.length == 0) {
            return notification.error({ message: "Please select the date." });
        }

        const data = { fdateRange: fdateRange, fProductID: fProductID }

        setLoading(true)
        ReportAPI.getPaymentReport(data)
            .then(res => {
                let data = [];

                res.data.policies.forEach(policy => {

                    let totaAamount;
                    if (res.data.paymentWrittenPremiumWithoutRefunds[policy.policyNumber] && res.data.paymentWrittenPremiumWithRefunds[policy.policyNumber]) {
                        totaAamount = res.data.paymentWrittenPremiumWithoutRefunds[policy.policyNumber].total_amount - res.data.paymentWrittenPremiumWithRefunds[policy.policyNumber].total_refund_amount
                    } else if (res.data.paymentWrittenPremiumWithoutRefunds[policy.policyNumber]) {
                        totaAamount = res.data.paymentWrittenPremiumWithoutRefunds[policy.policyNumber].total_amount
                    } else {
                        totaAamount = "0";
                    }


                    if (totaAamount != 1) {
                        let resData = [
                            policy.policyNumber,
                            totaAamount
                        ];

                        data = [resData, ...data];
                    }
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date   </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Product</label>
                                <Select style={{ width: '100%' }}
                                    onChange={(val) => setFProductID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    {
                                        products.map(product => {
                                            return (
                                                <Option key={product.id} value={product.id}>{product.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button
                                        onClick={onClickFilter} className="btn btn-primary"
                                    >
                                        Search
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Payment Report"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default PaymentReport
