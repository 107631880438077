import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, Select, Button, DatePicker } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'

const { Option } = Select;
const { RangePicker } = DatePicker;

function VehiclePreinspctionReport() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [fComplianceStatus, setFComplianceStatus] = useState('-1');
    const [fdateRange, setFdateRange] = useState([]);
    const [fPolicyStatus, setFPolicyStatus] = useState('-1');

    // useEffect( async () => {
    //     await onClickFilter();
    // }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "VehiclePreinspctionReport.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    temp[columns[idx].name] = v
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `VehiclePreinspctionReport`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "Policy Number",
        "Policy Status",
        "Agent",
        "Agency Name",
        // "compliance",
        "Status",
        "Left",
        "Right",
        "Front",
        "Back",
        "Vehicle Valuation",
        "Vehicle Registration"
    ];

    const onClickFilter = () => {
        const data = {
            fComplianceStatus: fComplianceStatus,
            fdateRange: fdateRange,
            fPolicyStatus: fPolicyStatus
        }

        setLoading(true)
        ReportAPI.getVehiclePreinspctionReport(data)
            .then(res => {
                let data = [];

                res.data.reports.forEach(policy => {
                    let agentName = policy.Agent.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                        return letra.toUpperCase();
                    });

                    let policyStatus;
                    if (parseInt(policy.policyStatus) === 1) {
                        policyStatus = "Activated";
                    } else if (parseInt(policy.policyStatus) === 2) {
                        policyStatus = "Cancelled";
                    } else {
                        policyStatus = 'Deactivated';
                    }

                    let resData = [
                        policy.policyNumber,
                        policyStatus,
                        agentName,
                        policy.agencyName ? policy.agencyName : '-',
                        // policy.compliance,
                        policy.vehicle_status,
                        policy.left,
                        policy.right,
                        policy.front,
                        policy.back,
                        policy.vehicle_valuation,
                        policy.vehicleRegistration
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false)
            }).catch(error => {
                setLoading(false)
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Compliance</label>
                                <Select style={{ width: '100%' }}
                                    onChange={(val) => setFComplianceStatus(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    <Option value="0">Pending</Option>
                                    <Option value="1">Approved</Option>
                                    <Option value="2">Rejected</Option>
                                    <Option value="3">Not Uploaded</Option>
                                </Select>
                            </Col>

                            <Col xs={24} lg={4} className="select_content">
                                <label>Policy Status</label>
                                <Select style={{ width: '100%' }}
                                    onChange={(val) => setFPolicyStatus(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    <Option value="0">Deactivated</Option>
                                    <Option value="1">Activated</Option>
                                    <Option value="2">Cancelled</Option>
                                </Select>
                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Vehicle Preinspction Report"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default VehiclePreinspctionReport
