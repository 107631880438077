import axios from "axios";
import { Config } from "../Config";

export const UserAPI = {
    getPermissionData: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-permission-data`, data);
    },
    saveUserPermissions: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/save-user-permissions`, data);
    },
    getUserPermission: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/fetch-user-permissions?db_select=${data.countryCode}`, data);
    }
}