import axios from "axios";
import { Config } from "../Config";

export const VCSAPI = {
    getViewVCSXLSX: async () => {
        return await axios.get(`${Config.apiNodeURL}/view-vcs-xlsx`);
    },
    importVCSXLSX: async (data) => {
        return await axios.post(`${Config.apiNodeURL}/import-vcs-xlsx`, data);
    },
    getViewVCSCcompaireData: async () => {
        return await axios.get(`${Config.apiNodeURL}/view-vcs-compaire-data`);
    },
}