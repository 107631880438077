import React, { Fragment, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, Button, Select, notification } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import { Config } from "../../../Config";
const { Option } = Select;

function MonthlyCollectionPaymentWiseReport() {

    const [loading, setLoading] = useState(false);

    const [month, setMonth] = useState('-1');
    const [year, setYear] = useState('-1');

    const onClickDownloadReport = () => {
        if (month == '-1') {
            return notification.error({ message: "Please select the month." });
        }

        if (year == '-1') {
            return notification.error({ message: "Please select the year." });
        }

        setLoading(true)
        ReportAPI.getMonthlyCollectionPaymentWiseReports({ month, year })
            .then(res => {
                setLoading(false);
                window.location.href = `${Config.apiNodeURL}/storage/MonthlyCollectionPaymentWiseReports.xlsx`;
                notification.success({ message: res.data.message });
            }).catch(error => {
                setLoading(false);
            });
    }


    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={12} className="select_content">
                                <h1>Monthly Collection Payment Wise Report</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Select Month</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setMonth(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">Select Month</Option>
                                    <Option value="01">January</Option>
                                    <Option value="02">February</Option>
                                    <Option value="03">March</Option>
                                    <Option value="04">April</Option>
                                    <Option value="05">May</Option>
                                    <Option value="06">June</Option>
                                    <Option value="07">July</Option>
                                    <Option value="08">August</Option>
                                    <Option value="09">September</Option>
                                    <Option value="10">October</Option>
                                    <Option value="11">November</Option>
                                    <Option value="12">December</Option>
                                </Select>
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Select Year</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setYear(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">Select Year</Option>
                                    <Option value="2023">2023</Option>
                                    <Option value="2022">2022</Option>
                                    <Option value="2021">2021</Option>
                                    <Option value="2020">2020</Option>
                                </Select>
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    {/* <Button onClick={onClickFilter} className="btn btn-primary">Search</Button> */}
                                    <Button onClick={onClickDownloadReport} className="btn btn-primary">Download Report</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </Fragment>
    )
}

export default MonthlyCollectionPaymentWiseReport
