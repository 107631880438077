import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, Input, DatePicker, Select, Button } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'

const { Option } = Select;
const { RangePicker } = DatePicker;

function FailedTransactionReport() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);

    const [products, setProducts] = useState([]);
    const [productPlans, setProductPlans] = useState([]);
    const [agents, setAgents] = useState([]);
    const [transationSTatusRefs, setTransationSTatusRefs] = useState([]);

    const [fProductID, setFProductID] = useState('-1');
    const [fProductPlanID, setFProductPlanID] = useState('-1');
    const [fAgentID, setFAgentID] = useState('-1');
    const [fTransationSTatusRef, setFTransationSTatusRef] = useState('-1');
    const [fPaymentDateRange, setFPaymentDateRange] = useState([]);
    const [fActivationDateRange, setFActivationDateRange] = useState([]);
    const [fPaymnetStatus, setFPaymnetStatus] = useState('-1');
    const [fTransactionType, setFTransactionType] = useState('-1');
    const [fPolicyNumber, setFPolicyNumber] = useState('');

    useEffect(async () => {
        setLoading(true)
        await ReportAPI.getFailedTransactionReportFilters()
            .then(res => {
                setProducts(res.data.products);
                setProductPlans(res.data.productPlans);
                setAgents(res.data.agents);
                setTransationSTatusRefs(res.data.statusRef);

                setLoading(false);

            }).catch(error => {
                setLoading(false);
            });

        // await onClickFilter();
    }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "FailedTransactionList.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    if (columns[idx].name === 'Premium Value') {
                        temp[columns[idx].name] = parseFloat(v);
                    } else {
                        temp[columns[idx].name] = v;
                    }
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `FailedTransactionList`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "ID",
        "Customer Name",
        "Cellphone",
        "Email",
        "Policy No.",
        "Product",
        "Product Plan",
        "Premium Value",
        "Agent",
        "Agency Name",
        "Transaction Type",
        "Transaction",
        "Transaction Reference",
        "Date Of Transaction",
        "Time Of Transaction"

    ];

    const onClickFilter = () => {

        const data = {
            fProductID: fProductID,
            fProductPlanID: fProductPlanID,
            fAgentID: fAgentID,
            fPaymentDateRange: fPaymentDateRange,
            fActivationDateRange: fActivationDateRange,
            fPaymnetStatus: fPaymnetStatus,
            fTransactionType: fTransactionType,
            fPolicyNumber: fPolicyNumber,
            fTransationSTatusRef: fTransationSTatusRef
        }

        setLoading(true)
        ReportAPI.getFailedTransactionReport(data)
            .then(res => {
                let data = [];

                res.data.records.forEach(report => {

                    if(report.customerName !== null){
                        report.customerName = report.customerName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }
                    
                    let product_name = report.product_name.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                        return letra.toUpperCase();
                    });

                    if(report.authorision_Date !== null){
                        if( report.authorision_Date.indexOf("/") !== -1){
                            report.authorision_Date = moment(report.authorision_Date, 'DD/MM/YYYY hh:mm A').format('YYYY-MM-DD hh:mm A');
                        }
                    }

                    let resData = [
                        report.id,
                        report.customerName !== null ? report.customerName : 'N/A',
                        report.cellphone !== null ? report.cellphone : 'N/A',
                        report.email !== '' && report.email !== null ? report.email : 'N/A',
                        report.policyNumber,
                        product_name,
                        report.product_plans_name,
                        report.amount !== null ? report.amount : '0',
                        report.agentName,
                        report.agencyName ? report.agencyName : "-",
                        report.transType,
                        report.paymentStatus,

                        report.statusRef !== null ? report.statusRef : 'N/A',
                        report.authorision_Date !== null ? moment(report.authorision_Date).format("DD-MM-YYYY") : 'N/A',
                        report.authorision_Date !== null ? moment(report.authorision_Date).format("hh:mm A") : 'N/A'
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content ">
                                <label>Filter By Policy Number</label>
                                <div className="">
                                    <Input placeholder="Please enter policy number" onChange={(e) => setFPolicyNumber(e.target.value)} />
                                </div>
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Product</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setFProductID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    {
                                        products.map((product) => {
                                            return (
                                                <Option key={Math.random()} value={product.id}>{product.name}</Option>
                                            )
                                        })
                                    }
                                </Select>

                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Product Plan</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setFProductPlanID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    placeholder="All" className="select-content">
                                    <Option value="-1">All</Option>
                                    {
                                        productPlans.map((productPlan) => {
                                            return (
                                                <Option key={Math.random()} value={productPlan.id}>{productPlan.name}</Option>
                                            )
                                        })
                                    }
                                </Select>

                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Agent</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setFAgentID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    {
                                        agents.map((agent) => {
                                            return (
                                                <Option key={Math.random()} value={agent.id}>{`${agent.firstName} ${agent.lastName}`}</Option>
                                            )
                                        })
                                    }
                                </Select>

                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date of payment </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFPaymentDateRange(dateString) : setFPaymentDateRange([])
                                        }
                                    }
                                />
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By VCS Payment Status</label>
                                <Select style={{ width: '100%' }}
                                    onChange={(val) => setFPaymnetStatus(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    <Option value="Success">Success</Option>
                                    <Option value="Failed">Failed</Option>
                                </Select>

                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Transaction Type</label>
                                <Select style={{ width: '100%' }}
                                    onChange={(val) => setFTransactionType(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    <Option value="First">First</Option>
                                    <Option value="Recurring">Recurring</Option>
                                </Select>

                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date of Policy activation </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            setFActivationDateRange(dateString)
                                        }
                                    }
                                />
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Transaction Reference</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => { setFTransationSTatusRef(val) }}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    {
                                        transationSTatusRefs.map((fref) => {
                                            return (
                                                <Option key={Math.random()} value={fref.statusRef}>{fref.statusRef}</Option>
                                            )
                                        })
                                    }
                                </Select>

                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Failed Transaction List"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default FailedTransactionReport
