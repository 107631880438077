import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Col, Row, DatePicker, Button, Spin, notification } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import moment from "moment";
import { Config } from "../../../Config";

// const { RangePicker } = DatePicker;

export default function SummaryAgeAnalysis() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [fdate, setFdate] = useState(null);

    const [search, setSearch] = useState(null);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);

    useEffect(() => {
        loadData(limit, page, search);
    }, []);

    const columns = [
        "Policy Number",
        "Client Name",
        "Agent Name",
        "Total Invoice",
        "Total Payment",
        "Total Refund",
        "Balance Outstanding",
        "30 Days",
        "60 Days",
        "90 Days",
        "120 Days and above",
        "Frequency",
        "Product Name",
        "Policy Status",
        "Policy Created Date",
        "Policy Created Time"
    ];

    const options = {
        selectableRows: 'none',
        download: false,
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100, 500],
        downloadOptions: { filename: "SummaryAgeAnalytics.csv", separator: "," },
        elevation: 6,
        onChangeRowsPerPage: (numberOfRows) => {
            loadData(numberOfRows, page, search)
        },
        serverSide: true,
        count: Math.ceil(count),
        page: page,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    loadData(limit, tableState.page, search)
                    break;
                case 'search':
                    loadData(limit, tableState.page, tableState.searchText)
                    break;
            }
        }
    };


    const loadData = (limit, page, search) => {
        const data = {
            search: search,
            limit: limit,
            page: page,
            isDownload: 'no'
        }

        setSearch(search)
        setLimit(limit)
        setPage(page)
        setLoading(true)
        ReportAPI.getSummaryAgeAnalysis(data)
            .then(res => {
                let data = [];
                const summaries = res.data.summaries

                if (summaries.length > 0) {
                    setCount(summaries[0].totalCount)
                }

                summaries.forEach(summary => {

                    if (summary.client_name !== null) {
                        summary.client_name = summary.client_name.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }
                    if (summary.agent_name !== null) {
                        summary.agent_name = summary.agent_name.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (summary.policy_status == 0) {
                        summary.policy_status = 'Deactivated';
                    } else if (summary.policy_status == 1) {
                        summary.policy_status = 'Activated';
                    } else if (summary.policy_status == 2) {
                        summary.policy_status = 'Cancelled';
                    }

                    if (summary.product_name) {
                        summary.product_name = summary.product_name.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    let resData = [
                        summary.policyNumber ? summary.policyNumber : '',
                        summary.client_name ? summary.client_name : '',
                        summary.agent_name ? summary.agent_name : '',
                        summary.invoice_total !== '' && summary.invoice_total !== null ? summary.invoice_total : 0,
                        summary.payment_total !== '' && summary.payment_total !== null ? summary.payment_total : 0,
                        summary.refund_total !== '' && summary.refund_total !== null ? summary.refund_total : 0,
                        summary.balance_outstanding !== '' && summary.balance_outstanding !== null ? summary.balance_outstanding : 0,
                        summary.days_30 !== '' && summary.days_30 !== null ? summary.days_30 : 0,
                        summary.days_60 !== '' && summary.days_60 !== null ? summary.days_60 : 0,
                        summary.days_90 !== '' && summary.days_90 !== null ? summary.days_90 : 0,
                        summary.days_120 !== '' && summary.days_120 !== null ? summary.days_120 : 0,
                        summary.premium_freq !== '' ? summary.premium_freq : '',
                        summary.product_name !== '' ? summary.product_name : '',
                        summary.policy_status !== '' ? summary.policy_status : '',
                        summary.policy_created_at !== '' ? moment(summary.policy_created_at).format('DD-MM-YYYY') : '',
                        summary.policy_created_at !== '' ? moment(summary.policy_created_at).format('HH:mm:ss') : ''
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false)
            }).catch(error => {
                setLoading(false)
            });
    }
    
    const onClickGenerate = async () => {

        if (fdate === null || fdate === '') {
            return notification.error({ message: "Please select the date." });
        }

        setLoading(true)
        const data = { fdate: fdate }

        await ReportAPI.generateSummaryAgeAnalysis(data)
            .then(res => { setLoading(false) }).catch(error => { setLoading(false) });

        setTimeout(() => {
            setLoading(false)
            loadData();
        }, 5 * 60 * 1000); //5 minute

    }

    const onClickDownload = async () => {

        const reqData = {
            isPreDownload: 'yes'
        }

        setLoading(true);
        await ReportAPI.getSummaryAgeAnalysis(reqData)
            .then(res => {
                window.location.href = `${Config.apiNodeURL}/${res.data.filePath}`;

                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >As Of Date </label>
                                <DatePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            setFdate(dateString)
                                        }
                                    }
                                />
                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickGenerate} className="btn btn-primary">Generate</Button>
                                </div>
                            </Col>

                            <Col xs={24} lg={1} className="select_content"> 
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickDownload} className="btn btn-primary">Download</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Summary Age Analytics"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}
