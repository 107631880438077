import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Col, Row, DatePicker, Button, Spin, Select, notification } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import { Config } from "../../../Config";

const { Option } = Select;
const { RangePicker } = DatePicker;
export default function BookOfBusinessReport() {

    const [loading, setLoading] = useState(false);
    const [policies, setPolicies] = useState([]);
    const [genratedDateRange, setGenratedDateRange] = useState([]);

    const [fdateRange, setFdateRange] = useState([]);
    const [fPreDownloadReportID, setPreDownloadReportID] = useState('-1');

    const [search, setSearch] = useState(null);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);

    useEffect(() => {
        setLoading(true)
        const reportName = "Book Of Business Report";
        ReportAPI.fetchProducts(reportName)
            .then(res => {
                // setProducts(res.data.products);
                setGenratedDateRange(res.data.genratedDateRange);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }, [])

    const options = {
        selectableRows: 'none',
        download: false,
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100, 200, 500],
        downloadOptions: { filename: "BookOfBusinessReport.csv", separator: "," },
        elevation: 6,
        onChangeRowsPerPage: (numberOfRows) => {
            loadData(numberOfRows, page, search)
        },
        serverSide: true,
        count: Math.ceil(count),
        page: page,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    loadData(limit, tableState.page, search)
                    break;
                case 'search':
                    loadData(limit, tableState.page, tableState.searchText)
                    break;
            }
        },
    };

    const columns = [
        "Customer Name",
        "Agent Name",
        "Agency Name",
        "Policy No",
        "Inception",
        "Term",
        "Tran Type",
        "Tran SubType",
        "Status",
        "Inforce Prem.",
        "Agent ID",
        "Term Start",
        "Term End"
    ];

    const loadData = async (limit, page, search) => {
        const data = {
            isDownload: 'no',
            search: search,
            limit: limit,
            page: page,
            fdateRange: fdateRange
        }

        setSearch(search)
        setLimit(limit)
        setPage(page)
        setLoading(true)

        ReportAPI.fetchFilterBookOfBusinessReports(data)
            .then(res => {
                const policies = res.data.policies
                if (policies.length > 0) {
                    setCount(policies[0].totalCount)
                }

                let data = [];
                let nowDate = moment();
                policies.forEach(policy => {

                    let tran_type = policy.status;
                    if (tran_type === 2) {
                        tran_type = 'CANCEL';
                    } else {
                        tran_type = 'RENEW';
                    }

                    let status = policy.status;
                    if (status === 0) {
                        status = "Active";
                    } else if (status === 1) {
                        status = "InActive";
                    } else {
                        status = "Cancel";
                    }

                    let inforce_prem = policy.premium;
                    if (inforce_prem != null) {
                        inforce_prem = policy.premium;
                    } else {
                        inforce_prem = '-';
                    }

                    let term_start = null;
                    let term_end = null;

                    if (policy.billingStartDate) {
                        term_start = moment(policy.billingStartDate, 'YYYY-MM-DD').format('DD-MM-YYYY');

                        if (policy.premium_freq > 1) {
                            term_end = moment(policy.billingStartDate, 'YYYY-MM-DD').add(1, 'years').subtract(1, 'days').format('DD-MM-YYYY');
                        } else {
                            term_end = moment(policy.billingStartDate, 'YYYY-MM-DD').add(1, 'months').subtract(1, 'days').format('DD-MM-YYYY');
                        }
                    }
                    if (policy.customerName !== '-' && policy.customerName !== null) {
                        policy.customerName = policy.customerName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (policy.agentName !== '-' && policy.agentName !== null) {
                        policy.agentName = policy.agentName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    let resData = [
                        policy.customerName,
                        policy.agentName,
                        policy.agencyName ? policy.agencyName : "-",
                        policy.policyNumber,
                        moment(policy.created_at).format('DD-MM-YYYY'),
                        nowDate.diff(policy.created_at, 'years', false),
                        tran_type,
                        '-',
                        status,
                        inforce_prem,
                        policy.agent_id,
                        term_start,
                        term_end
                    ];

                    data = [resData, ...data]
                });

                setLoading(false)
                setPolicies(data)
            }).catch(error => {
                setLoading(false)
            });
    }

    const onClickDownload = async () => {

        const reqData = {
            isDownload: 'yes',
            fdateRange: fdateRange
        }

        setLoading(true)
        ReportAPI.fetchFilterBookOfBusinessReports(reqData)
            .then(res => {
                window.location.href = `${Config.apiNodeURL}/${res.data.filePath}`;
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickPreDownload = async () => {
        if (fPreDownloadReportID == '-1') {
            return notification.error({ message: "Please select the Pre Generated Report." });
        }
        const reqData = {
            fPreDownloadReportID,
            isPreDownload: 'yes'
        }

        setLoading(true);

        await ReportAPI.fetchFilterBookOfBusinessReports(reqData)
            .then(res => {
                window.location.href = `${Config.apiNodeURL}/${res.data.filePath}`;

                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickSerach = () => {
        loadData(limit, 0, search)
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Pre-Generated Report</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setPreDownloadReportID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content"
                                >
                                    <Option value="-1">Select Report Range</Option>
                                    {
                                        genratedDateRange.map((dr) => {
                                            return (
                                                <Option key={Math.random()} value={dr.id}>{`${dr.start_date} - ${dr.end_date}`}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col>
                                <div className="button_search_content">
                                    <Button onClick={onClickPreDownload} className="btn btn-success">Download Generated</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date range </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>

                            <Col xs={24} lg={1} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickSerach} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>

                            <Col xs={24} lg={1} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickDownload} className="btn btn-primary">Download</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Book Of Business Report"}
                        data={policies}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}
