import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, DatePicker, Button } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'

const { RangePicker } = DatePicker;

function AgentLoginReport() {

    const [loading, setLoading] = useState(false);
    const [tblTitle, setTblTitle] = useState('Agent Login Report');
    const [isShowActiveInacive, setIsShowActiveInacive] = useState(false);
    const [reports, setReports] = useState([]);
    const [columns, setColumns] = useState([
        "Agent Name",
        "Agency Name",
        "Store Name",
        "Sold Policies By Agent",
        "Sold Policies At Store"
    ]);

    const [fdateRange, setFdateRange] = useState([]);

    // useEffect(async () => {
    //     setLoading(true)
    //     await onClickFilter();
    // }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "AgentLoginReport.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    temp[columns[idx].name] = v
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `AgentLoginReport`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const onClickFilter = () => {
        setIsShowActiveInacive(false)
        setTblTitle('Agent Login Report');
        setColumns([
            "Agent Name",
            "Agency Name",
            "Store Name",
            "Sold Policies By Agent",
            "Sold Policies At Store"
        ]);
        const data = { fdateRange: fdateRange }

        setLoading(true)
        ReportAPI.getAgentLoginReport(data)
            .then(res => {
                let data = [];

                res.data.records.forEach(report => {

                    let agentName = report.agentName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                        return letra.toUpperCase();
                    });
                    let storeName = report.storeName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                        return letra.toUpperCase();
                    });

                    let resData = [
                        agentName,
                        report.agencyName ? report.agencyName : '-',
                        storeName,
                        report.sold_policies_by_agent,
                        report.sold_policies_at_store
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickActiveIncative = (status) => {
        setIsShowActiveInacive(true)
        setColumns([
            "Agent Name",
            "Agency Name",
            "Store Name"
        ]);
        if (status === 'active') {
            setTblTitle('Active agents / stores');
        } else {
            setTblTitle('Inactive agents / stores');
        }

        setLoading(true)
        const data = { status: status }
        ReportAPI.getAgentLoginActiveIncativeReport(data)
            .then(res => {
                let data = [];

                res.data.records.forEach(report => {

                    if(report.agentName){
                        report.agentName = report.agentName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }
                    
                    if(report.storeName){
                        report.storeName = report.storeName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    let resData = [
                        report.agentName,
                        report.agencyName ? report.agencyName : '-',
                        report.storeName,
                        report.sold_policies_by_agent,
                        report.sold_policies_at_store
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            {
                                !isShowActiveInacive &&
                                <>
                                    <Col xs={24} lg={5} className="select_content">
                                        <label >Filter By date   </label>
                                        <RangePicker allowClear
                                            format="YYYY-MM-DD"
                                            onChange={
                                                (value, dateString) => {
                                                    value !== null ? setFdateRange(dateString) : setFdateRange([])
                                                }
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} lg={5} className="select_content">
                                        <label>&nbsp;</label>
                                        <div className="button_search_content">
                                            <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                        </div>
                                    </Col>
                                </>
                            }
                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={() => onClickActiveIncative('active')} className="btn">Today Active Agents / Stores</Button>
                                </div>
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={() => onClickActiveIncative('inactive')} className="btn" danger>Today Inactive Agents / Stores</Button>
                                </div>
                            </Col>
                            {
                                isShowActiveInacive &&
                                <Col xs={24} lg={5} className="select_content">
                                    <label>&nbsp;</label>
                                    <div className="button_search_content">
                                        <Button onClick={onClickFilter} className="btn btn-primary">Reset</Button>
                                    </div>
                                </Col>
                            }
                        </Row>
                    </div>

                    <MUIDataTable
                        title={tblTitle}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default AgentLoginReport
