import React, { Fragment, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, DatePicker, Button, Select, notification } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import { Config } from "../../../Config";

const { RangePicker } = DatePicker;
const { Option } = Select;

function CardExpiryReport() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);

    const [fYear, setFYear] = useState('-1');
    const [fMonth, setFMonth] = useState('-1');

    const [search, setSearch] = useState(null);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "cardExpiry.csv", separator: "," },
        elevation: 6,
        onChangeRowsPerPage: (numberOfRows) => {
            loadData(numberOfRows, page, search)
        },
        serverSide: true,
        count: Math.ceil(count),
        page: page,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    loadData(limit, tableState.page, search)
                    break;
                case 'search':
                    loadData(limit, tableState.page, tableState.searchText)
                    break;
            }
        }
    };

    const columns = [
        "Customer Name",
        "Cellphone",
        "Agent Name",
        "Agency Name",
        "Plan Name",
        "Policy Number",
        "Premium",
        "Policy Status",
        "Expiry Year",
        "Expiry Month"
    ];

    const loadData = async (limit, page, search) => {
        const data = {
            search: search,
            limit: limit,
            page: page,
            fYear: fYear,
            fMonth: fMonth,
            isDownload: 'no'
        }

        setSearch(search)
        setLimit(limit)
        setPage(page)

        setLoading(true)
        ReportAPI.getCardExpiryReport(data)
            .then(res => {
                let data = [];
                const records = res.data.records

                if (records.length > 0) {
                    setCount(records[0].totalCount)
                }

                records.forEach(policy => {

                    if (policy.customerName) {
                        policy.customerName = policy.customerName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (policy.agentName) {
                        policy.agentName = policy.agentName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    let resData = [
                        policy.customerName,
                        policy.cellphone,
                        policy.agentName,
                        policy.agencyName,
                        policy.product_plans_name,
                        policy.policyNumber,
                        policy.premium_value,
                        policy.policy_status,
                        policy.exYear,
                        policy.exMonth
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickDownload = async () => {

        const reqData = {
            fYear: fYear,
            fMonth: fMonth,
            isDownload: 'yes'
        }

        setLoading(true);
        await ReportAPI.getCardExpiryReport(reqData)
            .then(res => {
                window.location.href = `${Config.apiNodeURL}/storage/cardExpiry.xlsx`;

                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickSerach = () => {
        loadData(limit, 0, search)
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            {/* <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date   </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col> */}

                            <Col xs={24} lg={5} className="select_content">
                                <label>Select Year</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setFYear(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    placeholder="All" className="select-content">
                                    <Option value="-1">Select Year</Option>
                                    {
                                        [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33].map((year) => {
                                            return (
                                                <Option key={Math.random()} value={year}>{year}</Option>
                                            )
                                        })
                                    }
                                </Select>

                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label>Select Month</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setFMonth(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    placeholder="All" className="select-content">
                                    <Option value="-1">Select Month</Option>
                                    {
                                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
                                            return (
                                                <Option key={Math.random()} value={month}>{month}</Option>
                                            )
                                        })
                                    }
                                </Select>

                            </Col>

                            <Col xs={24} lg={1} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickSerach} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>

                            <Col xs={24} lg={1} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickDownload} className="btn btn-primary">Download</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Card Expiry"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default CardExpiryReport
