import React, { Fragment, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, DatePicker, Button } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
const { RangePicker } = DatePicker;

function RenewalPolicy() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);

    const [fdateRange, setFdateRange] = useState([]);

    // useEffect(async () => {
    //     // await onClickFilter();
    // }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        pagination: false,
        // rowsPerPage: 100,
        // rowsPerPageOptions: [10, 25, 50, 100, 500, 1000],
        setRowProps: row => {
            if (row[13] == 'Yes') {
                if (parseFloat(row[7]) > parseFloat(row[9])) {
                    return { style: { background: "red" } };
                } else {
                    return { style: { background: "green" } };
                }
            }
        },
        downloadOptions: { filename: "renewalPolicyList.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    temp[columns[idx].name] = v
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `renewalPolicyList`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "Policy Number",
        "Customer Name",
        "Cellphone",
        "Email",
        "Policy Expiry Date",
        "Frequency",
        "Actual Debited",
        "Old Premium",
        "DeAnn1",
        "New Premium",
        "DeAnn2",
        "Claim Count",
        "Premium Rate",
        "Rerated",
        "Renewed",
        "Was Email Sent ?",
        "Was SMS Send ?"
    ];

    const onClickFilter = () => {
        const data = {
            fdateRange: fdateRange
        }

        setLoading(true)
        ReportAPI.getRenewalPolicyList(data)
            .then(res => {
                let data = [];

                res.data.records.forEach((report, i) => {
                    if(report.customerName){
                        report.customerName = report.customerName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    let premiumRate;
                    if (report.is_rated == 1) {
                        let diffAmount = parseFloat(report.new_premium) - parseFloat(report.old_premium);
                        premiumRate = `${((diffAmount / parseFloat(report.old_premium)) * 100).toFixed(2)}%`;
                    } else {
                        premiumRate = '-';
                    }

                    let DeAnn1;
                    let DeAnn2;
                    if (report.premium_freq == 3) {
                        report.premium_freq = 'Yearly';
                        DeAnn1 = report.old_premium;
                        DeAnn2 = report.new_premium;
                    } else if (report.premium_freq == 2) {
                        report.premium_freq = '3 Installments';
                        DeAnn1 = parseFloat(report.old_premium) / 3;
                        DeAnn1 = DeAnn1.toFixed(2);
                        DeAnn2 = parseFloat(report.new_premium) / 3;
                        DeAnn2 = DeAnn2.toFixed(2);
                    } else {
                        report.premium_freq = 'Monthly';
                        DeAnn1 = (parseFloat(report.old_premium) * 1.08) / 12;
                        DeAnn1 = DeAnn1.toFixed(2);
                        DeAnn2 = (parseFloat(report.new_premium) * 1.08) / 12;
                        DeAnn2 = DeAnn2.toFixed(2);
                    }

                    let resData = [
                        report.policyNumber,
                        report.customerName,
                        report.cellphone,
                        report.email,
                        report.expiry_date,
                        report.premium_freq,
                        report.premium,
                        report.old_premium,
                        DeAnn1,
                        report.new_premium,
                        DeAnn2,
                        report.claim_count,
                        premiumRate,
                        report.is_rated == 1 ? 'Yes' : 'No',
                        report.is_renewed == 1 ? 'Yes' : 'No',
                        report.email_sent != null ? 'Yes' : 'No',
                        report.sms_sent != null ? 'Yes' : 'No',
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>

                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By Policy Expiry Date</label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Renewal Policy List"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default RenewalPolicy
