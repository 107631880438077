import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, DatePicker, Button, notification } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'

const { RangePicker } = DatePicker;

function OrangeMoneyReport() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);

    const [fdateRange, setFdateRange] = useState([]);

    // useEffect(async () => {
    //     setLoading(true)
    //     await onClickFilter();
    // }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "OrangeMoneyReport.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    if (columns[idx].name === 'Amount') {
                        temp[columns[idx].name] = parseFloat(v);
                    } else {
                        temp[columns[idx].name] = v;
                    }
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `OrangeMoneyReport`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "ID",
        "Policy Number",
        "Reference Number",
        "Amount",
        "Status",
        "Payment Date",
        "Payment Method",
        "Cash Recipient",
        "Note",
        "Payment Proof Link",
        "Is Ledger",
        "Payment Logged By",
        "Payment Frequency",
        "Number Of Instalments Paid",
        "Refunded By",
        "Reason",
        "Is Refund",
        "Created Date",
        "Created Time",
        "Updated Date",
        "Updated Time",
    ];

    const onClickFilter = () => {
        if (fdateRange.length == 0) {
            return notification.error({ message: "Please select the date." });
        }

        const data = { fdateRange: fdateRange }

        setLoading(true)
        ReportAPI.getOrangeMoneyReport(data)
            .then(res => {
                let data = [];

                res.data.records.forEach(record => {

                    let resData = [
                        record.id,
                        record.policyNumber,
                        record.referenceNumber,
                        record.amount,
                        record.status,
                        record.paymentDate ? moment(record.paymentDate).format('DD-MM-YYYY') : '-',
                        record.paymentMethod,
                        record.cashRecipient ? record.cashRecipient : '-',
                        record.note ? record.note : '-',
                        record.payment_proof_link ? record.payment_proof_link : '-',
                        record.is_ledger,
                        record.paymentLoggedBy ? record.paymentLoggedBy : '-',
                        record.paymentFrequency ? record.paymentFrequency : '-',
                        record.numberOfInstalmentsPaid ? record.numberOfInstalmentsPaid : '-',
                        record.refunded_by ? record.cashRecipient : '-',
                        record.reason ? record.cashRecipient : '-',
                        record.is_refund,
                        moment(record.created_at).format('DD-MM-YYYY'),
                        moment(record.created_at).format('HH:mm:ss'),
                        moment(record.updated_at).format('DD-MM-YYYY'),
                        moment(record.updated_at).format('HH:mm:ss')
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date   </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Orange Money Report"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default OrangeMoneyReport
