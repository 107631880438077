import React, { Fragment, useState } from 'react'
import LogoImg from '../assets/images/new_logo.png'
import '../assets/scss/Login.scss'
import { Form, Input, Button, Card, Typography, notification, Spin } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { AuthAPI } from '../apis/AuthAPI';
import Layout from 'antd/lib/layout/layout';
import { Token } from '../services/Token';
import { useHistory } from "react-router-dom";

export default function Login() {

    let history = useHistory();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        
        await AuthAPI.login(values)
            .then(async (res) => {
                let data = {
                    token: res.data.userDetails.auth_key,
                    user_id: res.data.userDetails.id,
                    agency_id: res.data.userDetails.agency_id,
                    name: res.data.userDetails.Name,
                    role_name: res.data.userDetails.role_name,
                    access_all: res.data.userDetails.access_all
                }
                
                Token.saveToken(data);
                notification.success({ message: `Welcome ${res.data.userDetails.Name}` });
                await setLoading(false);
                // history.push('/dashboard');
                window.location.href = '/dashboard';
            }).catch(err => {
                notification.error({ message: err.response.data.error });
                setLoading(false);
            })
    };

    return (
        <Fragment>
            <Layout className="loginFrom_section">
                <Typography className="logo_content">
                    <img src={LogoImg} alt="login_img" />
                </Typography>
                <Spin spinning={loading} delay={500}>

                    <Form
                        name="normal_login"
                        className="login-form"
                        onFinish={onFinish}
                    >
                        <Card className="login_content">
                            <div className="Profile_Contentusear">
                                <div className="Usear_profile">
                                    <UserOutlined />
                                </div>
                                <h4>Have an account?</h4>
                            </div>

                            <Form.Item
                                name="email"
                                rules={[
                                    { required: true, message: 'Please input your email.' },
                                    { type: "email", message: 'Please input your valid email.' }
                                ]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    { required: true, message: 'Please input your Password.' },
                                    { min: 6, message: 'Password must be minimum 6 characters.' }
                                ]}
                            >
                                <Input
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>

                        </Card>
                        <Form.Item className="Login_btn_content">
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Log in
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Layout>
        </Fragment>
    )
}
