import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
// import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
var moment = require('moment-timezone');
// moment().tz("Africa/Gaborone").format();

function AgentLoginReportV2() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);


    useEffect(async () => {
        const data = { date: moment().format('YYYY-MM-DD') }
        // const data = { date: '2022-09-02' }

        setLoading(true)
        ReportAPI.getAgentLoginReportV2(data)
            .then(res => {
                let data = [];

                res.data.records.forEach(report => {

                    let agentName = report.agentName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                        return letra.toUpperCase();
                    });
                    let storeName = report.storeName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                        return letra.toUpperCase();
                    });

                    //get values
                    var valuestart = report.policyCreatedAt;
                    var valuestop = moment().tz("Africa/Gaborone").format('YYYY-MM-DD HH:mm:ss');

                    var timeStart = new Date(valuestart).getTime();
                    var timeEnd = new Date(valuestop).getTime();
                    var hourDiff = timeEnd - timeStart; //in ms
                    var secDiff = hourDiff / 1000; //in s
                    var minDiff = hourDiff / 60 / 1000; //in minutes
                    var hDiff = hourDiff / 3600 / 1000; //in hours
                    var humanReadable = {};
                    humanReadable.hours = Math.floor(hDiff);
                    humanReadable.minutes = (minDiff - 60 * humanReadable.hours).toFixed(0);
                    // console.log(humanReadable); //{hours: 0, minutes: 30}

                    if(humanReadable.hours == 0){
                        humanReadable.hours = ''
                    }else{
                        humanReadable.hours = humanReadable.hours + ' hours';
                    }

                    if(humanReadable.minutes == 0){
                        humanReadable.minutes = ''
                    }else{
                        humanReadable.minutes = humanReadable.minutes + ' minutes';
                    }

                    let resData = [
                        report.agentID,
                        agentName,
                        report.agencyName ? report.agencyName : '-',
                        storeName,
                        moment(report.last_activity).format('YYYY-MM-DD'),
                        moment(report.last_activity).format('HH:mm:ss'),
                        report.policyCreatedAt == null ? null : `${humanReadable.hours} ${humanReadable.minutes} ago`
                        // moment(report.last_activity).tz("Africa/Gaborone").fromNow('MM'),
                        // hourDiff
                        // moment(report.last_activity).format('YYYY-MM-DD HH:mm:ss')

                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }, []);

    const columns = [
        "Agent ID",
        "Agent Name",
        "Agency Name",
        "Store Name",
        "Login Date",
        "Login Time",
        "Last Activity"
    ];

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "AgentLoginReportV2.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    temp[columns[idx].name] = v
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `AgentLoginReportV2`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <MUIDataTable
                        title={'Agent Login Report : Version-2'}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default AgentLoginReportV2
