import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, Select, Button, DatePicker, notification } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import { Config } from "../../../Config";

const { Option } = Select;
const { RangePicker } = DatePicker;

function CustomerKYCReportV3() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [products, setProducts] = useState([]);
    const [genratedDateRange, setGenratedDateRange] = useState([]);

    const [fdateRange, setFdateRange] = useState([]);
    const [fPreDownloadReportID, setPreDownloadReportID] = useState('-1');
    const [fProductIds, setFProductIds] = useState([]);

    const [search, setSearch] = useState(null);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);

    useEffect(async () => {
        setLoading(true)
        const reportName = "Customer KYC Report - V3";
        ReportAPI.fetchProducts(reportName)
            .then(res => {
                setProducts(res.data.products);
                setGenratedDateRange(res.data.genratedDateRange);

                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });

    }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "CustomerKYCReportV3.csv", separator: "," },
        elevation: 6,
        onChangeRowsPerPage: (numberOfRows) => {
            loadData(numberOfRows, page, search)
        },
        serverSide: true,
        count: Math.ceil(count),
        page: page,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    loadData(limit, tableState.page, search)
                    break;
                case 'search':
                    loadData(limit, tableState.page, tableState.searchText)
                    break;
            }
        }
    };

    const columns = [
        "Customer Name",
        "Cellephone",
        "Email",
        "Policy Number",
        "Product Name"
    ];

    const loadData = async (limit, page, search) => {
        const data = {
            search: search,
            limit: limit,
            page: page,
            fdateRange: fdateRange,
            fProductIds: fProductIds,
            isDownload: 'no'
        }

        setSearch(search)
        setLimit(limit)
        setPage(page)
        setLoading(true)

        ReportAPI.getCustomerKYCReportV3(data)
            .then(res => {
                let data = [];
                const reports = res.data.reports

                if (reports.length > 0) {
                    setCount(reports[0].totalCount)
                }

                res.data.reports.forEach(report => {

                    if (report.Customer) {
                        report.Customer = report.Customer.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    let resData = [
                        report.Customer,
                        report.cellphone,
                        report.email,
                        report.policyNumber,
                        report.productName
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false)
            }).catch(error => {
                setLoading(false)
            });
    }

    const onClickDownload = async () => {

        const reqData = {
            fdateRange: fdateRange,
            fProductIds: fProductIds,
            isDownload: 'yes'
        }

        setLoading(true);
        await ReportAPI.getCustomerKYCReportV3(reqData)
            .then(res => {
                window.location.href = `${Config.apiNodeURL}/${res.data.filePath}`;

                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickPreDownload = async () => {
        if (fPreDownloadReportID == '-1') {
            return notification.error({ message: "Please select the Pre Generated Report." });
        }
        const reqData = {
            fPreDownloadReportID,
            isPreDownload: 'yes'
        }

        setLoading(true);

        await ReportAPI.getCustomerKYCReportV3(reqData)
            .then(res => {
                window.location.href = `${Config.apiNodeURL}/${res.data.filePath}`;

                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickSerach = () => {
        loadData(limit, 0, search)
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Pre-Generated Report</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setPreDownloadReportID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content"
                                >
                                    <Option value="-1">Select Report Range</Option>
                                    {
                                        genratedDateRange.map((dr) => {
                                            return (
                                                <Option key={Math.random()} value={dr.id}>{`${dr.start_date} - ${dr.end_date}`}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col>
                                <div className="button_search_content">
                                    <Button onClick={onClickPreDownload} className="btn btn-success">Download Generated</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>

                            <Col xs={24} lg={4} className="select_content">
                                <label>Select Product</label>
                                <Select style={{ width: '100%' }}
                                    mode="multiple"
                                    allowClear
                                    onChange={(val) => setFProductIds(val)}
                                    // defaultValue="-1"
                                    optionFilterProp="children"
                                    className="select-content">
                                    {/* <Option value="-1">All</Option> */}
                                    {
                                        products.map(product => {
                                            return (<Option key={product.id} value={product.id}>{product.name}</Option>)
                                        })
                                    }
                                </Select>
                            </Col>

                            <Col xs={24} lg={1} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickSerach} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>

                            <Col xs={24} lg={1} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickDownload} className="btn btn-primary">Download</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Customer KYC Report - V3"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default CustomerKYCReportV3
