import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'

function DetailedAgeAnalysis() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);

    useEffect(() => {
        setLoading(true)
        ReportAPI.getDetaildAgeAnalysis()
            .then(res => {
                let data = [];

                res.data.policies.forEach(policy => {

                    let number_of_days_remaining;
                    if (policy.policyActivatedDate === "NA") {
                        number_of_days_remaining = moment().diff(policy.created_at, 'days', false)
                    } else {
                        number_of_days_remaining = moment().diff(policy.policyActivatedDate, 'days', false)
                    }

                    if(policy.customerName !== '-' && policy.customerName !== null){
                        policy.customerName = policy.customerName.toLowerCase().replace(/\b[a-z]/g, function(letra) {
                            return letra.toUpperCase();
                        });
                    }

                    let resData = [
                        policy.policyNumber,
                        policy.customerName,
                        'Payments',
                        policy.referenceNumber,
                        policy.productName,
                        policy.productPlanName,
                        policy.vehiclePlate,
                        policy.premium,
                        policy.policyActivatedDate ? moment(policy.policyActivatedDate).format('DD-MM-YYYY') : '-',
                        policy.policyActivatedDate ? moment(policy.policyActivatedDate).format('HH:mm:ss') : '-',
                        number_of_days_remaining
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false)
            }).catch(error => {
                setLoading(false)
            });
    }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100, 500],
        downloadOptions: { filename: "DetailedAgeAnalysisReport.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    if (columns[idx].name === 'Premium') {
                        temp[columns[idx].name] = parseFloat(v);
                    } else {
                        temp[columns[idx].name] = v;
                    }
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `DetailedAgeAnalysisReport`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "Policy Number",
        "Customer Name",
        "Classification",
        "referenceNumber",
        "Product",
        "Product Plan",
        "Vehicle Plate",
        "Premium",
        "Invoice Date",
        "Invoice Time",
        "Number Of Days"
    ];

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <MUIDataTable
                        title={"Detailed Age Analysis Report"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default DetailedAgeAnalysis
