import React, { useState, useEffect } from 'react'
import { Card, Col, Spin, Row } from 'antd';
import MUIDataTable from "mui-datatables";
import { ReportAPI } from '../../apis/ReportAPI';
import '../../assets/scss/Dashboard.scss'
import { useSelector } from 'react-redux';
export default function Dashboard() {
    const countryCode = useSelector(state => state.mainCountry);
    const [loading, setLoading] = useState(false);

    const [todayData, setTodayData] = useState([]);
    const [todayTotalActiveStore, setTodayTotalActiveStore] = useState('');
    const [todayTotalActiveAgent, setTodayTotalActiveAgent] = useState('');
    const [todayCollection, setTodayCollection] = useState(0);
    const [todayPaymentMethodTableData, setTodayPaymentMethodTableData] = useState([]);
    const [todayNotAttempted, setTodayNotAttempted] = useState(0);
    const [todayTopAgents, setTodayTopAgents] = useState([]);
    const [todayTopAgencies, setTodayTopAgencies] = useState([]);
    const [todayTopStores, setTodayTopStores] = useState([]);
    const [todaySold, setTodaySold] = useState(0);
    const [todayTotalActivatedPolicy, setTodayTotalActivatedPolicy] = useState(0);
    const [todayTotalDeactivatedPolicy, setTodayTotalDeactivatedPolicy] = useState(0);
    const [todayTotalCancelledPolicy, setTodayTotalCancelledPolicy] = useState(0);
    const [todayLoggedInAgents, setTodayLoggedInAgents] = useState(0);

    const [yesterdayData, setYesterdayData] = useState([]);
    const [yesterdayTotalActiveStore, setYesterdayTotalActiveStore] = useState('');
    const [yesterdayTotalActiveAgent, setYesterdayTotalActiveAgent] = useState('');
    const [yesterdayCollection, setYesterdayCollection] = useState(0);
    const [yesterdayPaymentMethodTableData, setYesterdayPaymentMethodTableData] = useState([]);
    const [yesterdaNotAttempted, setYesterdaNotAttempted] = useState(0);
    const [yesterdayTopAgents, setYesterdayTopAgents] = useState([]);
    const [yesterdayTopAgencies, setYesterdayTopAgencies] = useState([]);
    const [yesterdayTopStores, setYesterdayTopStores] = useState([]);
    const [yesterdaySold, setYesterdaySold] = useState(0);
    const [yesterdayTotalActivatedPolicy, setYesterdayTotalActivatedPolicy] = useState(0);
    const [yesterdayTotalDeactivatedPolicy, setYesterdayTotalDeactivatedPolicy] = useState(0);
    const [yesterdayTotalCancelledPolicy, setYesterdayTotalCancelledPolicy] = useState(0);

    const [currentMonthData, setCurrentMonthData] = useState([]);
    const [currentMonthTotalActiveStore, setCurrentMonthTotalActiveStore] = useState('');
    const [currentMonthTotalActiveAgent, setCurrentMonthTotalActiveAgent] = useState('');
    const [currentMonthCollection, setCurrentMonthCollection] = useState(0);
    const [currentMonthPaymentMethodTableData, setCurrentMonthPaymentMethodTableData] = useState([]);
    const [currentMonthNotAttempted, setCurrentMonthNotAttempted] = useState(0);
    const [currentMonthTopAgents, setCurrentMonthTopAgents] = useState([]);
    const [currentMonthTopAgencies, setCurrentMonthTopAgencies] = useState([]);
    const [currentMonthTopStores, setCurrentMonthTopStores] = useState([]);

    const [currentMonthSold, setCurrentMonthSold] = useState(0);
    const [currentMonthTotalActivatedPolicy, setCurrentMonthTotalActivatedPolicy] = useState(0);
    const [currentMonthTotalDeactivatedPolicy, setCurrentMonthTotalDeactivatedPolicy] = useState(0);
    const [currentMonthTotalCancelledPolicy, setCurrentMonthTotalCancelledPolicy] = useState(0);

    const [lastMonthData, setLastMonthData] = useState([]);
    const [lastMonthTotalActiveStore, setLastMonthTotalActiveStore] = useState('');
    const [lastMonthTotalActiveAgent, setLastMonthTotalActiveAgent] = useState('');
    const [lastMonthCollection, setLastMonthCollection] = useState(0);
    const [lastMonthPaymentMethodTableData, setLastMonthPaymentMethodTableData] = useState([]);
    const [lastMonthNotAttempted, setLastMonthNotAttempted] = useState(0);
    const [lastMonthTopAgents, setLastMonthTopAgents] = useState([]);
    const [lastMonthTopAgencies, setLastMonthTopAgencies] = useState([]);
    const [lastMonthTopStores, setLastMonthTopStores] = useState([]);
    const [lastMonthSold, setLastMonthSold] = useState(0);
    const [lastMonthTotalActivatedPolicy, setLastMonthTotalActivatedPolicy] = useState(0);
    const [lastMonthTotalDeactivatedPolicy, setLastMonthTotalDeactivatedPolicy] = useState(0);
    const [lastMonthTotalCancelledPolicy, setLastMonthTotalCancelledPolicy] = useState(0);


    useEffect(async () => {
        setLoading(true)

        await ReportAPI.fetchDashboardData(countryCode)
            .then(res => {
                // start Today 
                setTodayData(res.data.todayData);
                setTodayTotalActiveStore(res.data.todayTotalActiveStore)
                setTodayTotalActiveAgent(res.data.todayTotalActiveAgent)
                setTodayCollection(res.data.todayCollection)
                setTodaySold(res.data.todaySold)
                setTodayPaymentMethodTableData(res.data.todayPaymentMethod);
                setTodayNotAttempted(res.data.todaySold - res.data.todayTotalWayPayMethodPolices)


                let totalActivatedPolicy = 0;
                let totalDeactivatedPolicy = 0;
                let totalCancelledPolicy = 0;

                res.data.todayData.forEach(dd => {
                    totalActivatedPolicy = totalActivatedPolicy + dd.activated;
                    totalDeactivatedPolicy = totalDeactivatedPolicy + dd.deactivated;
                    totalCancelledPolicy = totalCancelledPolicy + dd.cancelled;
                })

                setTodayTotalActivatedPolicy(totalActivatedPolicy);
                setTodayTotalDeactivatedPolicy(totalDeactivatedPolicy);
                setTodayTotalCancelledPolicy(totalCancelledPolicy);

                let todaydata = [];
                res.data.todayTopAgents.forEach(dd => {

                    if (dd.agent_name !== null) {
                        dd.agent_name = dd.agent_name.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    let resData = [dd.agent_name, dd.activated_policy_count, dd.deactivated_policy_count, dd.cancelled_policy_count, dd.policy_count];
                    todaydata = [resData, ...todaydata]
                })
                setTodayTopAgents(todaydata);

                let todaydataAgencies = [];
                res.data.todayTopAgencies.forEach(dd => {

                    // if (dd.agency_name !== null) {
                    //     dd.agency_name = dd.agency_name.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                    //         return letra.toUpperCase();
                    //     });
                    // }

                    let resData = [dd.agency_name, dd.activated_policy_count, dd.deactivated_policy_count, dd.cancelled_policy_count, dd.policy_count];
                    todaydataAgencies = [resData, ...todaydataAgencies]
                })
                setTodayTopAgencies(todaydataAgencies);

                let todayStore = [];
                res.data.todayTopStores.forEach(dd => {
                    if (dd.store_name !== null) {
                        dd.store_name = dd.store_name.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }
                    let resData = [dd.store_name, dd.policy_count];
                    todayStore = [resData, ...todayStore]
                })
                setTodayTopStores(todayStore);
                setTodayLoggedInAgents(res.data.todayLoggedInAgents);
                // End Today 

                // Start Yesterday 

                setYesterdayData(res.data.yesterdayData);
                setYesterdayTotalActiveStore(res.data.yesterdayTotalActiveStore)
                setYesterdayTotalActiveAgent(res.data.yesterdayTotalActiveAgent)
                setYesterdayCollection(res.data.yesterdayCollection)
                setYesterdaySold(res.data.yesterDaySold)
                setYesterdayPaymentMethodTableData(res.data.yesterdayPaymentMethod);
                setYesterdaNotAttempted(res.data.yesterDaySold - res.data.yesterdayTotalWayPayMethodPolices)

                totalActivatedPolicy = 0;
                totalDeactivatedPolicy = 0;
                totalCancelledPolicy = 0;

                res.data.yesterdayData.forEach(dd => {
                    totalActivatedPolicy = totalActivatedPolicy + dd.activated;
                    totalDeactivatedPolicy = totalDeactivatedPolicy + dd.deactivated;
                    totalCancelledPolicy = totalCancelledPolicy + dd.cancelled;
                })

                setYesterdayTotalActivatedPolicy(totalActivatedPolicy);
                setYesterdayTotalDeactivatedPolicy(totalDeactivatedPolicy);
                setYesterdayTotalCancelledPolicy(totalCancelledPolicy);

                let yesterdaydata = [];
                res.data.yesterdayTopAgents.forEach(dd => {
                    if (dd.agent_name !== null) {
                        dd.agent_name = dd.agent_name.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }
                    let resData = [dd.agent_name, dd.activated_policy_count, dd.deactivated_policy_count, dd.cancelled_policy_count, dd.policy_count];
                    yesterdaydata = [resData, ...yesterdaydata]
                })
                setYesterdayTopAgents(yesterdaydata);

                let yesterdaydataAgencies = [];
                res.data.yesterdayTopAgencies.forEach(dd => {
                    // if (dd.agency_name !== null) {
                    //     dd.agency_name = dd.agency_name.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                    //         return letra.toUpperCase();
                    //     });
                    // }
                    let resData = [dd.agency_name, dd.activated_policy_count, dd.deactivated_policy_count, dd.cancelled_policy_count, dd.policy_count];
                    yesterdaydataAgencies = [resData, ...yesterdaydataAgencies]
                })
                setYesterdayTopAgencies(yesterdaydataAgencies);

                let yesterdayStore = [];
                res.data.yesterdayTopStores.forEach(dd => {
                    if (dd.store_name !== null) {
                        dd.store_name = dd.store_name.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }
                    let resData = [dd.store_name, dd.policy_count];
                    yesterdayStore = [resData, ...yesterdayStore]
                })
                setYesterdayTopStores(yesterdayStore);
                // End Yesterday 

                // Start Current Month 
                setCurrentMonthData(res.data.currentMonthData);
                setCurrentMonthTotalActiveStore(res.data.currentMonthTotalActiveStore)
                setCurrentMonthTotalActiveAgent(res.data.currentMonthTotalActiveAgent)
                setCurrentMonthCollection(res.data.currentMonthCollection)
                setCurrentMonthSold(res.data.currentMonthSold)
                setCurrentMonthPaymentMethodTableData(res.data.currentMonthPaymentMethod);
                setCurrentMonthNotAttempted(res.data.currentMonthSold - res.data.currentMonthTotalWayPayMethodPolices)

                totalActivatedPolicy = 0;
                totalDeactivatedPolicy = 0;
                totalCancelledPolicy = 0;

                res.data.currentMonthData.forEach(dd => {
                    totalActivatedPolicy = totalActivatedPolicy + dd.activated;
                    totalDeactivatedPolicy = totalDeactivatedPolicy + dd.deactivated;
                    totalCancelledPolicy = totalCancelledPolicy + dd.cancelled;
                })

                setCurrentMonthTotalActivatedPolicy(totalActivatedPolicy);
                setCurrentMonthTotalDeactivatedPolicy(totalDeactivatedPolicy);
                setCurrentMonthTotalCancelledPolicy(totalCancelledPolicy);

                let currentMonthdata = [];
                res.data.currentMonthTopAgents.forEach(dd => {
                    if (dd.agent_name !== null) {
                        dd.agent_name = dd.agent_name.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }
                    let resData = [dd.agent_name, dd.activated_policy_count, dd.deactivated_policy_count, dd.cancelled_policy_count, dd.policy_count];
                    currentMonthdata = [resData, ...currentMonthdata]
                })
                setCurrentMonthTopAgents(currentMonthdata);

                let currentMonthdataAgencies = [];
                res.data.currentMonthTopAgencies.forEach(dd => {
                    // if (dd.agency_name !== null) {
                    //     dd.agency_name = dd.agency_name.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                    //         return letra.toUpperCase();
                    //     });
                    // }
                    let resData = [dd.agency_name, dd.activated_policy_count, dd.deactivated_policy_count, dd.cancelled_policy_count, dd.policy_count];
                    currentMonthdataAgencies = [resData, ...currentMonthdataAgencies]
                })
                setCurrentMonthTopAgencies(currentMonthdataAgencies);

                let currentMonthStore = [];
                res.data.currentMonthTopStores.forEach(dd => {
                    if (dd.store_name !== null) {
                        dd.store_name = dd.store_name.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }
                    let resData = [dd.store_name, dd.policy_count];
                    currentMonthStore = [resData, ...currentMonthStore]
                })
                setCurrentMonthTopStores(currentMonthStore);
                // End Current Month 

                // Start Last Month 
                setLastMonthData(res.data.lastMonthData);
                setLastMonthTotalActiveStore(res.data.lastMonthTotalActiveStore)
                setLastMonthTotalActiveAgent(res.data.lastMonthTotalActiveAgent)
                setLastMonthCollection(res.data.lastMonthCollection)
                setLastMonthSold(res.data.lastMonthSold)
                setLastMonthPaymentMethodTableData(res.data.lastMonthPaymentMethod);
                setLastMonthNotAttempted(res.data.lastMonthSold - res.data.lastMonthTotalWayPayMethodPolices)

                totalActivatedPolicy = 0;
                totalDeactivatedPolicy = 0;
                totalCancelledPolicy = 0;

                res.data.lastMonthData.forEach(dd => {
                    totalActivatedPolicy = totalActivatedPolicy + dd.activated;
                    totalDeactivatedPolicy = totalDeactivatedPolicy + dd.deactivated;
                    totalCancelledPolicy = totalCancelledPolicy + dd.cancelled;
                })

                setLastMonthTotalActivatedPolicy(totalActivatedPolicy);
                setLastMonthTotalDeactivatedPolicy(totalDeactivatedPolicy);
                setLastMonthTotalCancelledPolicy(totalCancelledPolicy);

                let lastMonthdata = [];
                res.data.lastMonthTopAgents.forEach(dd => {
                    if (dd.agent_name !== null) {
                        dd.agent_name = dd.agent_name.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }
                    let resData = [dd.agent_name, dd.activated_policy_count, dd.deactivated_policy_count, dd.cancelled_policy_count, dd.policy_count];
                    lastMonthdata = [resData, ...lastMonthdata]
                })
                setLastMonthTopAgents(lastMonthdata);

                let lastMonthdataAgencies = [];
                res.data.lastMonthTopAgencies.forEach(dd => {
                    // if (dd.agency_name !== null) {
                    //     dd.agency_name = dd.agency_name.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                    //         return letra.toUpperCase();
                    //     });
                    // }
                    let resData = [dd.agency_name, dd.activated_policy_count, dd.deactivated_policy_count, dd.cancelled_policy_count, dd.policy_count];
                    lastMonthdataAgencies = [resData, ...lastMonthdataAgencies]
                })
                setLastMonthTopAgencies(lastMonthdataAgencies);

                let lastMonthStore = [];
                res.data.lastMonthTopStores.forEach(dd => {
                    if (dd.store_name !== null) {
                        dd.store_name = dd.store_name.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }
                    let resData = [dd.store_name, dd.policy_count];
                    lastMonthStore = [resData, ...lastMonthStore]
                })
                setLastMonthTopStores(lastMonthStore);
                // End Last Month 
            })

        setLoading(false)
    }, []);

    return (
        <div>
            <h1>Dashboard</h1>
            <Spin spinning={loading} delay={500}>
                <div className="site-card-border-less-wrapper">
                    <Row gutter={16} className="row-heading">
                        <h3>Today's Collection:</h3>
                    </Row>

                    <Row gutter={16}>
                        {
                            todayData.map(data => {
                                return (
                                    <Col key={Math.random()} sm={8} md={10} lg={8} >
                                        <Card title={data.productName} className="card_box">
                                            <p>Total Policy: {data.totalPolicy}</p>
                                            <p>Activated: {data.activated}</p>
                                            <p>Deactivated: {data.deactivated}</p>
                                            <p>Cancelled: {data.cancelled}</p>
                                        </Card>
                                    </Col>
                                )
                            })
                        }

                        <Col sm={16} md={10} lg={16} >
                            <Card title="Today Statistics" className="card_box">
                                <Row>
                                    <Col sm={8} md={10} lg={8} >
                                        <p>Total no Of Active Store: {todayTotalActiveStore}</p>
                                        <p>Total no Of Active Agents: {todayTotalActiveAgent}</p>
                                        <p>Total Collection: P {todayCollection.toFixed(2)}</p>
                                    </Col>
                                    <Col sm={8} md={10} lg={8} >
                                        <p>Total Policy: {todaySold}</p>
                                        <p>Total Activated: {todayTotalActivatedPolicy}</p>
                                        <p>Total Deactivated: {todayTotalDeactivatedPolicy}</p>
                                        <p>Total Cancelled: {todayTotalCancelledPolicy}</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row gutter={3} className="row-headingmpt">
                        <Col sm={24} md={24} lg={24} >
                            <MUIDataTable
                                // title={`Today Payment Method  [ Not Attempted (${todayNotAttempted}) ]`}
                                title={`Today Payment Method`}
                                data={todayPaymentMethodTableData}
                                columns={[
                                    "Payment Method",
                                    "No Of Policies",
                                    "Activated Policies",
                                    "Activation Rate",
                                    "Failed Transaction",
                                    "Payment not intiated"
                                ]}
                                options={{
                                    selectableRows: 'none',
                                    display: false, sort: false, empty: false,
                                    filter: false, print: false, download: false,
                                    search: false, pagination: false
                                }}
                            />
                        </Col>
                    </Row>

                    <Row gutter={3} className="row-headingmpt">
                        <Col sm={14} md={14} lg={14} >
                            <MUIDataTable
                                title={"Today's Agents"}
                                data={todayTopAgents}
                                columns={[
                                    "Agent Name",
                                    "Activated Policy",
                                    "Deactivated Policy",
                                    "Cancelled Policy",
                                    "Total Policy"
                                ]}
                                options={{
                                    selectableRows: 'none',
                                    display: false,
                                    sort: false,
                                    empty: false,
                                    filter: false,
                                    print: false,
                                    download: true,
                                    rowsPerPageOptions: [10],
                                    downloadOptions: { filename: "todayTopAgents.csv", separator: "," }
                                }}
                            />
                        </Col>

                        <Col sm={10} md={10} lg={10} >
                            <MUIDataTable
                                title={"Today's Stores"}
                                data={todayTopStores}
                                columns={[
                                    "Store Name",
                                    "Policy Count"
                                ]}
                                options={{
                                    selectableRows: 'none',
                                    display: false,
                                    sort: false,
                                    empty: false,
                                    filter: false,
                                    print: false,
                                    download: true,
                                    rowsPerPageOptions: [10],
                                    downloadOptions: { filename: "todayTopStores.csv", separator: "," }
                                }}
                            />
                        </Col>
                    </Row>

                    <Row gutter={3} className="row-headingmpt">
                        <Col sm={24} md={24} lg={24} >
                            <MUIDataTable
                                title={"Today's Agencies"}
                                data={todayTopAgencies}
                                columns={[
                                    "Agency Name",
                                    "Activated Policy",
                                    "Deactivated Policy",
                                    "Cancelled Policy",
                                    "Total Policy"
                                ]}
                                options={{
                                    selectableRows: 'none',
                                    display: false,
                                    sort: false,
                                    empty: false,
                                    filter: false,
                                    print: false,
                                    download: true,
                                    rowsPerPageOptions: [10],
                                    downloadOptions: { filename: "todayTopAgencies.csv", separator: "," }
                                }}
                            />
                        </Col>
                    </Row>

                    <Row gutter={16} className="row-heading">
                        <h3>Yesterday's Collection:</h3>
                    </Row>

                    <Row gutter={16}>
                        {
                            yesterdayData.map(data => {
                                return (
                                    <Col key={Math.random()} sm={8} md={10} lg={8} >
                                        <Card title={data.productName} className="card_box">
                                            <p>Total Policy: {data.totalPolicy}</p>
                                            <p>Activated: {data.activated}</p>
                                            <p>Deactivated: {data.deactivated}</p>
                                            <p>Cancelled: {data.cancelled}</p>
                                        </Card>
                                    </Col>
                                )
                            })
                        }

                        <Col sm={16} md={10} lg={16} >
                            <Card title="Yesterday Statistics" className="card_box">
                                <Row>
                                    <Col sm={8} md={10} lg={8} >
                                        <p>Total no Of Active Store: {yesterdayTotalActiveStore}</p>
                                        <p>Total no Of Active Agents: {yesterdayTotalActiveAgent}</p>
                                        <p>Total Collection: P {yesterdayCollection.toFixed(2)}</p>
                                    </Col>
                                    <Col sm={8} md={10} lg={8} >
                                        <p>Total Policy: {yesterdaySold}</p>
                                        <p>Total Activated: {yesterdayTotalActivatedPolicy}</p>
                                        <p>Total Deactivated: {yesterdayTotalDeactivatedPolicy}</p>
                                        <p>Total Cancelled: {yesterdayTotalCancelledPolicy}</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row gutter={16} className="row-headingmpt">
                        <Col sm={24} md={24} lg={24} >
                            <MUIDataTable
                                // title={`Yesterday Payment Method  [ Not Attempted (${yesterdaNotAttempted}) ]`}
                                title={`Yesterday Payment Method`}
                                data={yesterdayPaymentMethodTableData}
                                columns={[
                                    "Payment Method",
                                    "No Of Policies",
                                    "Activated Policies",
                                    "Activation Rate",
                                    "Failed Transaction",
                                    "Payment not intiated"
                                ]}
                                options={{
                                    selectableRows: 'none',
                                    display: false, sort: false, empty: false,
                                    filter: false, print: false, download: false,
                                    search: false, pagination: false
                                }}
                            />
                        </Col>
                    </Row>

                    <Row gutter={3} className="row-headingmpt">
                        <Col sm={14} md={14} lg={14} >
                            <MUIDataTable
                                title={"Yesterday's Agents"}
                                data={yesterdayTopAgents}
                                columns={[
                                    "Agent Name",
                                    "Activated Policy",
                                    "Deactivated Policy",
                                    "Cancelled Policy",
                                    "Total Policy"
                                ]}
                                options={{
                                    selectableRows: 'none',
                                    display: false,
                                    sort: false,
                                    empty: false,
                                    filter: false,
                                    print: false,
                                    download: true,
                                    rowsPerPageOptions: [10],
                                    downloadOptions: { filename: "yesterdayTopAgents.csv", separator: "," }
                                }}
                            />
                        </Col>

                        <Col sm={10} md={10} lg={10} >
                            <MUIDataTable
                                title={"Yesterday's Stores"}
                                data={yesterdayTopStores}
                                columns={[
                                    "Store Name",
                                    "Policy Count"
                                ]}
                                options={{
                                    selectableRows: 'none',
                                    display: false,
                                    sort: false,
                                    empty: false,
                                    filter: false,
                                    print: false,
                                    download: true,
                                    rowsPerPageOptions: [10],
                                    downloadOptions: { filename: "yesterdayTopStores.csv", separator: "," }
                                }}
                            />
                        </Col>
                    </Row>

                    <Row gutter={3} className="row-headingmpt">
                        <Col sm={24} md={24} lg={24} >
                            <MUIDataTable
                                title={"Yesterday's Agencies"}
                                data={yesterdayTopAgencies}
                                columns={[
                                    "Agency Name",
                                    "Activated Policy",
                                    "Deactivated Policy",
                                    "Cancelled Policy",
                                    "Total Policy"
                                ]}
                                options={{
                                    selectableRows: 'none',
                                    display: false,
                                    sort: false,
                                    empty: false,
                                    filter: false,
                                    print: false,
                                    download: true,
                                    rowsPerPageOptions: [10],
                                    downloadOptions: { filename: "yesterdayTopAgencies.csv", separator: "," }
                                }}
                            />
                        </Col>
                    </Row>

                    <Row gutter={16} className="row-heading">
                        <h3>This Month's Collection:</h3>
                    </Row>

                    <Row gutter={16}>
                        {
                            currentMonthData.map(data => {
                                return (
                                    <Col key={Math.random()} sm={8} md={10} lg={8} >
                                        <Card title={data.productName} className="card_box">
                                            <p>Total Policy: {data.totalPolicy}</p>
                                            <p>Activated: {data.activated}</p>
                                            <p>Deactivated: {data.deactivated}</p>
                                            <p>Cancelled: {data.cancelled}</p>
                                        </Card>
                                    </Col>
                                )
                            })
                        }

                        <Col sm={16} md={10} lg={16} >
                            <Card title="This Month's Statistics" className="card_box">
                                <Row>
                                    <Col sm={8} md={10} lg={8} >
                                        <p>Total no Of Active Store:{currentMonthTotalActiveStore}</p>
                                        <p>Total no Of Active Agents:{currentMonthTotalActiveAgent}</p>
                                        <p>Total Collection: P {currentMonthCollection.toFixed(2)}</p>

                                    </Col>
                                    <Col sm={8} md={10} lg={8} >
                                        <p>Total Policy: {currentMonthSold}</p>
                                        <p>Total Activated: {currentMonthTotalActivatedPolicy}</p>
                                        <p>Total Deactivated: {currentMonthTotalDeactivatedPolicy}</p>
                                        <p>Total Cancelled: {currentMonthTotalCancelledPolicy}</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row gutter={16} className="row-headingmpt">
                        <Col sm={24} md={24} lg={24} >
                            <MUIDataTable
                                // title={`This Month Payment Method  [ Not Attempted (${currentMonthNotAttempted}) ]`}
                                title={`This Month Payment Method`}
                                data={currentMonthPaymentMethodTableData}
                                columns={[
                                    "Payment Method",
                                    "No Of Policies",
                                    "Activated Policies",
                                    "Activation Rate",
                                    "Failed Transaction",
                                    "Payment not intiated"
                                ]}
                                options={{
                                    selectableRows: 'none',
                                    display: false, sort: false, empty: false,
                                    filter: false, print: false, download: false,
                                    search: false, pagination: false
                                }}
                            />
                        </Col>
                    </Row>

                    <Row gutter={3} className="row-headingmpt">
                        <Col sm={14} md={14} lg={14} >
                            <MUIDataTable
                                title={"This Month Agents"}
                                data={currentMonthTopAgents}
                                columns={[
                                    "Agent Name",
                                    "Activated Policy",
                                    "Deactivated Policy",
                                    "Cancelled Policy",
                                    "Total Policy"
                                ]}
                                options={{
                                    selectableRows: 'none',
                                    display: false,
                                    sort: false,
                                    empty: false,
                                    filter: false,
                                    print: false,
                                    download: true,
                                    downloadOptions: { filename: "ThisMonthAgents.csv", separator: "," }
                                }}
                            />
                        </Col>

                        <Col sm={10} md={10} lg={10} >
                            <MUIDataTable
                                title={"This Month Stores"}
                                data={currentMonthTopStores}
                                columns={[
                                    "Store Name",
                                    "Policy Count"
                                ]}
                                options={{
                                    selectableRows: 'none',
                                    display: false,
                                    sort: false,
                                    empty: false,
                                    filter: false,
                                    print: false,
                                    download: true,
                                    downloadOptions: { filename: "ThisMonthStores.csv", separator: "," }
                                }}
                            />
                        </Col>
                    </Row>

                    <Row gutter={3} className="row-headingmpt">
                        <Col sm={24} md={24} lg={24} >
                            <MUIDataTable
                                title={"This Month Agencies"}
                                data={currentMonthTopAgencies}
                                columns={[
                                    "Agency Name",
                                    "Activated Policy",
                                    "Deactivated Policy",
                                    "Cancelled Policy",
                                    "Total Policy"
                                ]}
                                options={{
                                    selectableRows: 'none',
                                    display: false,
                                    sort: false,
                                    empty: false,
                                    filter: false,
                                    print: false,
                                    download: true,
                                    rowsPerPageOptions: [10],
                                    downloadOptions: { filename: "currentMonthTopAgencies.csv", separator: "," }
                                }}
                            />
                        </Col>
                    </Row>

                    <Row gutter={16} className="row-heading">
                        <h3>Last Month's Collection:</h3>
                    </Row>

                    <Row gutter={16}>
                        {
                            lastMonthData.map(data => {
                                return (
                                    <Col key={Math.random()} sm={8} md={10} lg={8} >
                                        <Card title={data.productName} className="card_box">
                                            <p>Total Policy: {data.totalPolicy}</p>
                                            <p>Activated: {data.activated}</p>
                                            <p>Deactivated: {data.deactivated}</p>
                                            <p>Cancelled: {data.cancelled}</p>
                                        </Card>
                                    </Col>
                                )
                            })
                        }

                        <Col sm={16} md={10} lg={16} >
                            <Card title="Last Month Statistics" className="card_box">
                                <Row>
                                    <Col sm={8} md={10} lg={8} >
                                        <p>Total no Of Active Store Last: {lastMonthTotalActiveStore}</p>
                                        <p>Total no Of Active Agents Last: {lastMonthTotalActiveAgent}</p>
                                        <p>Total Collection Last: P {lastMonthCollection.toFixed(2)}</p>
                                    </Col>
                                    <Col sm={8} md={10} lg={8} >
                                        <p>Total Policy: {lastMonthSold}</p>
                                        <p>Total Activated: {lastMonthTotalActivatedPolicy}</p>
                                        <p>Total Deactivated: {lastMonthTotalDeactivatedPolicy}</p>
                                        <p>Total Cancelled: {lastMonthTotalCancelledPolicy}</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>

                <Row gutter={16} className="row-headingmpt">
                    <Col sm={24} md={24} lg={24} >
                        <MUIDataTable
                            // title={`Last Month Payment Method  [ Not Attempted (${lastMonthNotAttempted}) ]`}
                            title={`Last Month Payment Method`}
                            data={lastMonthPaymentMethodTableData}
                            columns={[
                                "Payment Method",
                                "No Of Policies",
                                "Activated Policies",
                                "Activation Rate",
                                "Failed Transaction",
                                "Payment not intiated"
                            ]}
                            options={{
                                selectableRows: 'none',
                                display: false, sort: false, empty: false,
                                filter: false, print: false, download: false,
                                search: false, pagination: false
                            }}
                        />
                    </Col>
                </Row>

                <Row gutter={3} className="row-headingmpt">
                    <Col sm={14} md={14} lg={14} >
                        <MUIDataTable
                            title={"Last Month Agents"}
                            data={lastMonthTopAgents}
                            columns={[
                                "Agent Name",
                                "Activated Policy",
                                "Deactivated Policy",
                                "Cancelled Policy",
                                "Total Policy"
                            ]}
                            options={{
                                selectableRows: 'none',
                                display: false,
                                sort: false,
                                empty: false,
                                filter: false,
                                print: false,
                                download: true,
                                // search: false,
                                // pagination: false,
                                downloadOptions: { filename: "LastMonthAgents.csv", separator: "," }
                            }}
                        />
                    </Col>

                    <Col sm={10} md={10} lg={10} >
                        <MUIDataTable
                            title={"Last Month Stores"}
                            data={lastMonthTopStores}
                            columns={[
                                "Store Name",
                                "Policy Count"
                            ]}
                            options={{
                                selectableRows: 'none',
                                display: false,
                                sort: false,
                                empty: false,
                                filter: false,
                                print: false,
                                download: true,
                                // search: false,
                                // pagination: false,
                                downloadOptions: { filename: "LastMonthStores.csv", separator: "," }
                            }}
                        />
                    </Col>
                </Row>

                <Row gutter={3} className="row-headingmpt">
                    <Col sm={24} md={24} lg={24} >
                        <MUIDataTable
                            title={"Last Month Agencies"}
                            data={lastMonthTopAgencies}
                            columns={[
                                "Agency Name",
                                "Activated Policy",
                                "Deactivated Policy",
                                "Cancelled Policy",
                                "Total Policy"
                            ]}
                            options={{
                                selectableRows: 'none',
                                display: false,
                                sort: false,
                                empty: false,
                                filter: false,
                                print: false,
                                download: true,
                                rowsPerPageOptions: [10],
                                downloadOptions: { filename: "lastMonthTopAgencies.csv", separator: "," }
                            }}
                        />
                    </Col>
                </Row>
            </Spin>
        </div>
    )
}
