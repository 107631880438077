import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Button, Spin, notification, Select, DatePicker, Row, Col, InputNumber } from 'antd';
import { ReportAPI } from '../../../apis/ReportAPI';
import moment from 'moment';
import { Config } from "../../../Config";
import { PlusOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import camelcase from "camelcase";
import { useSelector } from "react-redux";


const { Option } = Select;
const { RangePicker } = DatePicker;

export default function Quotes() {
    const countryCode = useSelector(state => state.mainCountry)
    const [loading, setLoading] = useState(false);
    const [quotes, setQuotes] = useState([]);
    const [agents, setAgents] = useState([]);

    const [fAgentID, setFAgentID] = useState('-1');
    const [fdateRange, setFdateRange] = useState([]);
    const [fPremiumRate, setFPremiumRate] = useState('');
    const [fPremiumRateAction, setPremiumRateAction] = useState('-1');
    const [fQuoteStatus, setFQuoteStatus] = useState('-1');

    useEffect(async () => {
        setLoading(true);
        await ReportAPI.qoutesFilters(countryCode)
            .then(res => {
                setAgents(res.data.agents)
            })
        setLoading(false);
    }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100, 500],
        // downloadOptions: { filename: "QuoteList.csv", separator: "," },
        elevation: 6,
        download: false,
        //   print: false,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    temp[columns[idx].name] = v
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `QuoteList`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "ID",
        "Customer Name",
        "Quite Code",
        "Policy Number",
        "Product",
        "Created By",
        "Premium Rate",
        "IP Address",
        "Status",
        "Created At",
    ];

    const onClickFilter = async () => {
        const data = {
            fAgentID: fAgentID,
            fQuoteStatus: fQuoteStatus,
            fdateRange: fdateRange,
            fPremiumRate: fPremiumRate,
            fPremiumRateAction: fPremiumRateAction,
            countryCode
        }

        setLoading(true);

        await ReportAPI.qoutes(data)
            .then(resApiData => {
                let data = [];
                // setAgents(res.data.agents)
                resApiData.quotes.forEach(quote => {

                    let status;
                    if (quote.status == "1") {
                        status = 'Active';
                    } else if (quote.status == "2") {
                        status = 'Used';
                    } else if (quote.status == "3" || quote.status == "0") {
                        status = 'Expired';
                    } else if (quote.status == "4") {
                        status = 'Rejected';
                    } else {
                        status = 'N/A';
                    }

                    const dateTimeAgo = moment(quote.created_at).fromNow();

                    if (quote.customerName !== 'N/A' && quote.customerName !== null) {
                        quote.customerName = quote.customerName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (quote.productName !== 'N/A' && quote.productName !== null) {
                        quote.productName = quote.productName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (quote.createdBy !== 'N/A' && quote.createdBy !== null) {
                        quote.createdBy = quote.createdBy.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    let resData = [
                        quote.id,
                        quote.customerName !== null ? quote.customerName : '-',
                        quote.quoteCode !== null ? quote.quoteCode : '-',
                        quote.policyNumber !== null ? quote.policyNumber : '--',
                        quote.productName !== null ? quote.productName : '-',
                        // quote.createdBy !== null ? camelcase(quote.createdBy, {locale: 'en-US'}) : '-',
                        quote.createdBy !== null ? quote.createdBy : '-',
                        quote.premiumRate !== '-' ? `${Math.round(quote.premiumRate).toFixed(2)}%` : '-',
                        quote.userIPAddress !== null ? quote.userIPAddress : '-',
                        status,
                        dateTimeAgo
                    ];

                    data = [resData, ...data]
                })
                setQuotes(data);
                setLoading(false)
            }).catch(error => {
                setLoading(false);
            });

        // .then(res => {
        //     let data = [];
        //     res.data.quotes.forEach(quote => {

        //         console.log(typeof (quote.status))
        //         let status;
        //         if (quote.status == "1") {
        //             status = 'Active';
        //         } else if (quote.status == "2") {
        //             status = 'Used';
        //         } else if (quote.status == "3" || quote.status == "0") {
        //             status = 'Expired';
        //         } else if (quote.status == "4") {
        //             status = 'Rejected';
        //         } else {
        //             status = 'N/A';
        //         }

        //         const dateTimeAgo = moment(quote.created_at).fromNow();

        //         let resData = [
        //             quote.id,
        //             quote.customerName !== null ? quote.customerName : '-',
        //             quote.quoteCode !== null ? quote.quoteCode : '-',
        //             quote.policyNumber !== null ? quote.policyNumber : '--',
        //             quote.productName !== null ? quote.productName : '-',
        //             // quote.createdBy !== null ? camelcase(quote.createdBy, {locale: 'en-US'}) : '-',
        //             quote.createdBy !== null ? quote.createdBy.toUpperCase() : '-',
        //             quote.premiumRate !== '-' ? `${Math.round(quote.premiumRate).toFixed(2)}%` : '-',
        //             quote.userIPAddress !== null ? quote.userIPAddress : '-',
        //             status,
        //             dateTimeAgo
        //         ];

        //         data = [resData, ...data]
        //     })
        //     setQuotes(data);
        //     setLoading(false)
        // }).catch(error => {
        //     setLoading(false);
        // });
    }

    const exportExcel = async () => {
        setLoading(true)

        const data = {
            fAgentID: fAgentID,
            fQuoteStatus: fQuoteStatus,
            fdateRange: fdateRange,
            fPremiumRate: fPremiumRate,
            fPremiumRateAction: fPremiumRateAction,
            countryCode
        }

        await ReportAPI.qouteExportExcel(data)
            .then(res => {
                setLoading(false)
                window.location.href = `${Config.apiNodeURL}/storage/QuotesExport.xlsx`;
                notification.success({ message: res.data.message });
            }).catch(err => {
                setLoading(false)
                notification.error({ message: "Server id not started." });
            })
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>

                            <Col xs={24} lg={4} className="select_content">
                                <label>Filter By Agent</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setFAgentID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    {
                                        agents.map((agent) => {
                                            return (
                                                <Option key={Math.random()} value={agent.id}>{agent.agentName}</Option>
                                            )
                                        })
                                    }
                                </Select>

                            </Col>

                            <Col xs={24} lg={4} className="select_content">
                                <label>Select Status</label>
                                <Select style={{ width: '100%' }}
                                    onChange={(val) => setFQuoteStatus(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    <Option value="1">Active</Option>
                                    <Option value="2">Used</Option>
                                    <Option value="3">Expired</Option>
                                    <Option value="4">Rejected</Option>
                                </Select>
                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date range</label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>

                            <Col xs={24} lg={3} className="select_content">
                                <label>Enter Preminum</label>
                                <InputNumber
                                    placeholder="Enter Number"
                                    style={{
                                        width: 200,
                                    }}
                                    min="0"
                                    max="10"
                                    step="0.01"
                                    value={fPremiumRate}
                                    onChange={(value) => setFPremiumRate(value)}
                                    stringMode
                                />

                            </Col>

                            <Col xs={24} lg={3} className="select_content">
                                <label>Select comparison</label>
                                <Select allowClear style={{ width: '100%' }}
                                    onChange={(val) => setPremiumRateAction(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    <Option value='greater'>{'> Grater Than'}</Option>
                                    <Option value='less'>{'< Less Than'}</Option>
                                </Select>
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button type="primary" onClick={onClickFilter} style={{ marginBottom: 16, marginRight: 20 }}>
                                        Search
                                    </Button>
                                    <Button type="primary" onClick={exportExcel} style={{ marginBottom: 16 }}>
                                        Export Excel <PlusOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Quote List"}
                        data={quotes}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}
