import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, Input, Button, Select, notification } from "antd";
import { ReportAPI } from '../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import { Config } from "../../Config";
import { Token } from "../../services/Token";
const { Option } = Select;

function UploadReports() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [fileName, setFileName] = useState('-1');
    const [file, setFile] = useState();

    useEffect(async () => {
        setLoading(true)
        onGetReport()
    }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "preDownloadedReport.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    temp[columns[idx].name] = v
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `preDownloadedReport`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        { name: "Report Name" },
        { name: "Uploaded Date" },
        { name: "Uploaded By" },
        {
            name: "Actions",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Button className="btn btn-primary" onClick={() => {
                            window.location.href = `${Config.apiNodeURL}/${value}`;

                        }}>
                            Download
                        </Button>
                    )
                }
            }
        }
    ];

    const onGetReport = () => {

        setLoading(true)
        ReportAPI.getPreDownloadedReport('uploaded')
            .then(res => {
                let data = [];
                res.data.reports.forEach(report => {
                    let uploadedDate = moment(report.updated_at).format('YYYY-MM-DD')
                    if (report.userName) {
                        report.userName = report.userName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }
                    let resData = [
                        report.report_name,
                        uploadedDate,
                        report.userName,
                        report.report_path
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const handleChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    }

    const onSubmit = () => {
        if (fileName == '-1') {
            return notification.error({ message: "Please select the File Type." });
        }

        if (!file) {
            return notification.error({ message: "Please choose the file." });
        }

        const data = new FormData();
        data.append('file_name', fileName);
        data.append('file', file);
        data.append('user_id', Token.getUserID());

        setLoading(true)
        ReportAPI.uploadFiles(data)
            .then(res => {
                notification.success({ message: res.data.message })
                setTimeout(() => { onGetReport(); }, 1000)

                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Select File Type</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setFileName(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content"
                                >
                                    <Option value="-1">Select File Type</Option>
                                    <Option value="Payment Transactions">Payment Transactions</Option>
                                </Select>
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Choose File</label>
                                <Input onChange={handleChange} type="file" accept=".xlsx, .xls" />
                            </Col>

                            <Col xs={12} lg={1} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onSubmit} className="btn btn-primary">Submit</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Uploaded Reports"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment >
    )
}

export default UploadReports
