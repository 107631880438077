import React, { Fragment, useState, useEffect } from 'react'
import "antd/dist/antd.css";
import '../assets/scss/Header.scss';
import { Link, useHistory } from "react-router-dom";
import { Menu, Dropdown, Avatar, Layout, Button, Select } from 'antd';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Token, getFullName, setMainCountry, getMainCountry, getUserRole } from '../services/Token';
import { AuthAPI } from '../apis/AuthAPI';
import headerlogo from '../assets/images/new_logo.png'
import botswanaFlag from '../assets/images/botswana-flag.jpg'
import zambiaFlag from '../assets/images/zambia-flag.jpg'
import PersonIcon from '@material-ui/icons/Person';
import {
    ExportOutlined, BarChartOutlined, MenuUnfoldOutlined,
    MenuFoldOutlined, PieChartOutlined, SettingOutlined
} from '@ant-design/icons';

import { connect, useSelector } from 'react-redux';
import { UserAPI } from '../apis/UserAPI';

const { Option } = Select;
const menu_for_zm = [
    1, 2, 3, 5, 8, 11, 16, 41, 43
]

function AppLayout(props) {
    const countryCode = useSelector(state => state.mainCountry);
    // let history = useHistory()
    const { Sider } = Layout;
    // const { Content, Footer, Sider } = Layout;

    const [loading, setLoading] = useState(false);
    const [currentCountryName, setCurrentCountryName] = useState(countryCode);

    const [collapsed, setCollapsed] = useState(false);
    const [showPermissionMenus, setShowPermissionMenus] = useState([]);
    const [loggedUser, setLoggedUser] = useState(Token.isLoggedIn());
    const [isPermissionForUserPermission, setIsPermissionForUserPermission] = useState(0);

    useEffect(() => {
        getUserPermissionMenus();
    }, []);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    }

    const logout = () => {
        setLoggedUser(true);

        Token.clearStorage();
        setLoggedUser(false);
        window.location.href = '/';
    }

    const selectCountry = (val) => {
        props.saveCountry(val)
        setMainCountry(val)
        setCurrentCountryName(val);
        window.location.reload();
    }

    const getUserPermissionMenus = () => {
        setLoading(true);
        let data = { agent_id: Token.getUserID(), countryCode }

        UserAPI.getUserPermission(data)
            .then(res => {

                let data = [];
                res.data.masterPermissions.forEach((per, i) => {

                    let isPermission = false;
                    if (res.data.usersPermissions[`is_master_report_permission_id_${per.id}`]) {
                        isPermission = true;
                    }

                    /**
                     * @author shubahm malewar 
                     * @description check user permission page
                     */

                    if (res.data.usersPermissions[44]) {
                        setIsPermissionForUserPermission(1)
                    }

                    data[i] = {
                        key: per.id,
                        permission_id: per.id,
                        menu_title: per.master_report_title,
                        menu_url: per.master_report_url,
                        is_show: isPermission
                    };
                })

                setShowPermissionMenus(data);
                setLoading(false);
            }).catch(err => {
                console.log(err);
                setLoading(false)
            });

    }

    const menu = (
        <Menu>
            <Menu.Item key="0">
                <Link to="/dashboard"><BarChartOutlined />Dashboard</Link>
            </Menu.Item>
            <Menu.Divider />
            {
                // (getUserRole() === 'Super Admin' && isPermissionForUserPermission) &&
                (getUserRole() === 'Super Admin' || isPermissionForUserPermission) &&
                <Menu.Item key="1">
                    <Link to="/user-permission"><SettingOutlined />User Permission</Link>
                </Menu.Item>
            }
            <Menu.Item key="2">
                <a href="#!" onClick={logout}><ExportOutlined />Logout</a>
            </Menu.Item>
        </Menu>
    );

    const { SubMenu } = Menu;

    return (
        <Fragment>
            {/* <div className="wapper_content">
                Dashboard
            </div> */}
            <Fragment>
                <nav className="header">
                    <Button className="menuButton" type="primary" onClick={toggleCollapsed} style={{ marginBottom: 16 }}>
                        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
                    </Button>
                    <Link to="/dashboard">
                        <h1 className="header-logo"><img src={headerlogo} alt="" /></h1>
                    </Link>
                    <Select style={{ width: '10%' }}
                        onChange={(val) => selectCountry(val)}
                        // defaultValue={getMainCountry() ? getMainCountry() : "botswana"}
                        // defaultValue={countryCode}
                        value={currentCountryName}
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                        className="select-content">
                        <Option value="bw"><img src={botswanaFlag} width="20" height="20" /> Botswana</Option>
                        <Option value="zm"><img src={zambiaFlag} width="20" height="20" /> Zambia</Option>
                    </Select>&nbsp;&nbsp;&nbsp;
                    <div className="header-nav">
                        {
                            loggedUser === false ?
                                <Fragment>
                                    <Link to="/" className="header_link">
                                        <div className="header_option">
                                            <AccountCircleIcon />
                                            <span className="header-login">Login</span>
                                        </div>
                                    </Link>
                                </Fragment> :
                                <Fragment >
                                    <Dropdown overlay={menu} trigger={['click']}>
                                        <a href="#!" className="ant-dropdown-link userlink" onClick={e => e.preventDefault()}>
                                            {/* <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" /> */}
                                            <PersonIcon className="usericon" />
                                            <span className="header_link">{getFullName()}</span>
                                        </a>
                                    </Dropdown>
                                </Fragment>
                        }
                    </div>
                    {/* //////////////////////////Responsive header modal-side-bar//////////////////// */}
                    <Layout className="sidenavbar-header">
                        <Sider
                            breakpoint="lg"
                            collapsedWidth="100"
                            onBreakpoint={broken => {
                                // console.log(broken);
                            }}
                            onCollapse={(collapsed, type) => {
                                // console.log(collapsed, type);
                            }}
                        >
                            <div className="logo" />
                            <Menu theme="dark" mode="inline" defaultSelectedKeys={['4']}>
                                <Menu.Item key="2">
                                    {
                                        loggedUser === false ?
                                            <Fragment>
                                                <Link to="/" className="header_link">
                                                    <div className="header_option">
                                                        <AccountCircleIcon />
                                                        <span className="header-login">Login</span>
                                                    </div>
                                                </Link>
                                            </Fragment> :
                                            <Fragment >
                                                <Dropdown overlay={menu} trigger={['click']}>
                                                    <a href="#!" className="ant-dropdown-link " onClick={e => e.preventDefault()}>
                                                        <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                                                        <span className="header_link">{getFullName()}</span>
                                                    </a>
                                                </Dropdown>
                                            </Fragment>
                                    }
                                </Menu.Item>
                            </Menu>
                        </Sider>
                    </Layout>

                </nav>

            </Fragment>

            <Fragment>
                <Sider
                    collapsible
                    collapsed={collapsed}
                    onCollapse={() => setCollapsed(!collapsed)}
                    style={{
                        overflow: "auto",
                        height: "100%",
                        // position: "sticky",
                        top: 0,
                        left: 0
                    }}
                >
                    <div className="logo" />
                    <Menu theme="light" mode="inline" defaultSelectedKeys={["1"]}>

                        {
                            showPermissionMenus.map(menu => {
                                if ((menu.is_show || getUserRole() === 'Super Admin') && menu.permission_id !== 44) {
                                    if (countryCode === "zm") {
                                        if (menu_for_zm.includes(menu.key)) {
                                            return (
                                                <Menu.Item key={menu.key} icon={<BarChartOutlined />}>
                                                    <Link to={`/${menu.menu_url}`} >
                                                        <span className="nav-text">{menu.menu_title}</span>
                                                    </Link>
                                                </Menu.Item>
                                            )
                                            if (menu.permission_id == 1 || menu.permission_id == 3) {
                                                return (
                                                    <Menu.Item key={menu.key} icon={<BarChartOutlined />}>
                                                        <Link to={`/${menu.menu_url}`} >
                                                            <span className="nav-text">{menu.menu_title}</span>
                                                        </Link>
                                                    </Menu.Item>
                                                )
                                            }
                                        }
                                    } else {
                                        return (
                                            <Menu.Item key={menu.key} icon={<BarChartOutlined />}>
                                                <Link to={`/${menu.menu_url}`} >
                                                    <span className="nav-text">{menu.menu_title}</span>
                                                </Link>
                                            </Menu.Item>
                                        )
                                    }
                                }
                            })
                        }
                        {/* 
                        <Menu.Item key="57" icon={<BarChartOutlined />}>
                            <Link to="/customerDuplicateEmailReports" >
                                <span className="nav-text">Customer Duplicate Email Reports</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="58" icon={<BarChartOutlined />}>
                            <Link to="/revioUpload" >
                                <span className="nav-text">Revio upload</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="59" icon={<BarChartOutlined />}>
                            <Link to="/monthlyCollectionProductWiseReport" >
                                <span className="nav-text">Monthly Collection Product Wise Report</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="60" icon={<BarChartOutlined />}>
                            <Link to="/monthlyCollectionPaymentWiseReport" >
                                <span className="nav-text">Monthly Collection Payment Wise Report</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="61" icon={<BarChartOutlined />}>
                            <Link to="/activatedPoliciesKYCPendingReport" >
                                <span className="nav-text">Activated Policies KYC Pending Report</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="63" icon={<BarChartOutlined />}>
                            <Link to="/blacklistCustomerReport" >
                                <span className="nav-text">Blacklist Customer Report</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="64" icon={<BarChartOutlined />}>
                            <Link to="/vcsRecurringPaymentImport" >
                                <span className="nav-text">VCS Recurring Payment Import</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="65" icon={<BarChartOutlined />}>
                            <Link to="/vcsFirstPaymentImport" >
                                <span className="nav-text">VCS First Payment Import</span>
                            </Link>
                        </Menu.Item> */}

                    </Menu>
                </Sider>
            </Fragment>

        </Fragment>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveCountry: (data) => {
            dispatch({ type: 'SET_MAIN_COUNTRY', payload: data })
        },
        // isPlanActive: (data) => {
        //     dispatch({ type: 'SAVE_PROFILE', payload: data })
        // }
    }
}

export default connect(null, mapDispatchToProps)(AppLayout)