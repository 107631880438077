import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, DatePicker, Button } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'

const { RangePicker } = DatePicker;

function ReInsuranceClaimsProfile() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);

    const [fdateRange, setFdateRange] = useState([]);

    // useEffect(async () => {
    //     setLoading(true)
    //     await onClickFilter();
    // }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "ReInsuranceClaimsProfile.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    if (columns[idx].name === 'Reserve Payment Amt') {
                        temp[columns[idx].name] = parseFloat(v);
                    } else {
                        temp[columns[idx].name] = v;
                    }
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `ReInsuranceClaimsProfile`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "Claim No",
        "Policy Number",
        "Product Name",
        "Insured Name",
        "Reported Date",
        "Date Of Loss",
        "Claim Type",
        "Payment Date",
        "Trans Type",
        "Trans Sub Type",
        "Reserve Payment Amt",
        "Coverag Name",
        "Motor Desc",
        "Motor Desc2",
        "Term Start Date",
        "Term End Date",
        "VAT Include"
    ];

    const onClickFilter = () => {
        const data = { fdateRange: fdateRange }

        setLoading(true)
        ReportAPI.getReInsuranceClaimsProfile(data)
            .then(res => {
                let data = [];

                res.data.records.forEach(report => {

                    let reserve_payment_amt;
                    if (report.created_at) {
                        if (report.tranType === 'Lost') {
                            reserve_payment_amt = `-${report.reserve_amount}`;
                        } else {
                            reserve_payment_amt = report.reserve_amount;
                        }
                    } else {
                        reserve_payment_amt = 'N/A';
                    }

                    let term_start_date = 'N/A';

                    if (report.billingStartDate) {
                        term_start_date = moment(report.billingStartDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
                    }

                    let term_end = 'N/A';

                    if (report.billingStartDate) {
                        if (report.premium_freq > 1) {
                            term_end = moment(report.billingStartDate, 'YYYY-MM-DD').add(1, 'years').subtract(1, 'days').format('DD-MM-YYYY');
                        } else {
                            term_end = moment(report.billingStartDate, 'YYYY-MM-DD').add(1, 'months').subtract(1, 'days').format('DD-MM-YYYY');
                        }
                    }

                    let productName = report.productName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                        return letra.toUpperCase();
                    });
                    let customerName = report.customerName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                        return letra.toUpperCase();
                    });

                    let resData = [
                        report.claim_number,
                        report.policyNumber,
                        productName,
                        customerName,
                        moment(report.created_at).format('DD-MM-YYYY'),
                        report.date_of_accident ? moment(report.date_of_accident).format('DD-MM-YYYY') : 'N/A',
                        report.claim_type,
                        moment(report.updated_at).format('DD-MM-YYYY'),
                        report.tranType ? report.tranType : 'N/A',
                        report.tranSubType ? report.tranSubType : 'N/A',
                        reserve_payment_amt,
                        report.coveragesName ? report.coveragesName : 'N/A',
                        report.motor_desc ? report.motor_desc : 'N/A',
                        report.motor_desc2 ? report.motor_desc2 : 'N/A',
                        term_start_date,
                        term_end,
                        report.vat_include === 1 ? "Yes" : "No"
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date   </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Insurance Claims Profile"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default ReInsuranceClaimsProfile
