import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, Button, Select, notification } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import moment from "moment";

const { Option } = Select;

function MonthlyCollection() {

    const [loading, setLoading] = useState(false);
    const [year, setYear] = useState('-1');

    const [collections, setCollections] = useState([]);

    // useEffect(async () => {
    //     await onClickFilter();
    // }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        // rowsPerPage: 10,
        // rowsPerPageOptions: [10, 25, 50, 100],
        pagination: false,
        downloadOptions: { filename: "monthlyCollection.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    if (columns[idx].name === 'Collection') {
                        temp[columns[idx].name] = parseFloat(v);
                    } else {
                        temp[columns[idx].name] = v;
                    }
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `monthlyCollection`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "Month",
        "Year",
        "Collection",
    ];

    const onClickFilter = () => {

        if (year == '-1') {
            return notification.error({ message: "Please select the year." });
        }

        setLoading(true)
        var data = { year: year }
        ReportAPI.getMonthlyCollections(data)
            .then(res => {
                let data = [];

                let getCurrentNumM = moment().add(1, 'months').format('MM');
                res.data.collections.forEach(collection => {

                    let getNumM = moment().month(collection.month).format('MM');

                    // console.log(getCurrentNumM, getNumM, collection.month, collection.collection)
                    // if ((getCurrentNumM > getNumM) && year == 2021) {
                    if (year == 2021) {

                        let resData = [
                            collection.month,
                            year,
                            parseFloat(collection.collection).toFixed(2)
                        ];

                        data = [resData, ...data]
                    } else if (year != 2021) {
                        let resData = [
                            collection.month,
                            year,
                            parseFloat(collection.collection).toFixed(2)
                        ];

                        data = [resData, ...data]
                    }
                })
                setCollections(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content"><div className="form_wapper_content">
                    <Row>
                        <Col xs={24} lg={5} className="select_content">
                            <label>Select Year</label>
                            <Select showSearch style={{ width: '100%' }}
                                onChange={(val) => setYear(val)}
                                defaultValue="-1"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                className="select-content">
                                <Option value="-1">Select Year</Option>
                                <Option value="2020">2020</Option>
                                <Option value="2021">2021</Option>
                                <Option value="2022">2022</Option>
                            </Select>
                        </Col>

                        <Col xs={24} lg={5} className="select_content">
                            <label>&nbsp;</label>
                            <div className="button_search_content">
                                <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                            </div>
                        </Col>
                    </Row>
                </div>

                    <MUIDataTable
                        title={"Monthly Collection"}
                        data={collections}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default MonthlyCollection
