import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, Select, Button, DatePicker } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'

const { Option } = Select;
const { RangePicker } = DatePicker;

function CustomerDuplicateEmailReports() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);

    const [fdateRange, setFdateRange] = useState([]);

    useEffect(async () => {
        await onClickFilter();
    }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "CustomerDuplicateEmailReports.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    temp[columns[idx].name] = v
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `CustomerDuplicateEmailReports`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "Customer Id",
        "Customer Name",
        "Email",
        "Cellphone",
        "Policy Number",
        "Policy Status",
        "Premium",
        "Agent",
        "Agency Name",
    ];

    const onClickFilter = () => {
        const data = {
            fdateRange: fdateRange
        }
        setLoading(true)
        ReportAPI.getCustomerDuplicateEmailReports(data)
            .then(res => {
                let data = [];
                let collectEmails = [];

                res.data.records.forEach((report, i) => {

                    if (report.customerName) {
                        report.customerName = report.customerName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (report.agentName) {
                        report.agentName = report.agentName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (report.agencyName) {
                        report.agencyName = report.agencyName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if(!collectEmails.includes(report.email)){
                        let resData = [
                            '-',
                            '-',
                            '-',
                            '-',
                            '-',
                            '-',
                            '-',
                            '-',
                            '-',
                        ];
                        data = [resData, ...data]
                    }

                    let resData = [
                        report.customerId,
                        report.customerName,
                        report.email,
                        report.cellphone,
                        report.policyNumber,
                        report.policy_status,
                        report.premium,
                        report.agentName,
                        report.agencyName ? report.agencyName : '-',
                    ];
                    data = [resData, ...data]

                    collectEmails[i] = report.email
                })
                setReports(data);
                setLoading(false)
            }).catch(error => {
                setLoading(false)
            });

    }
    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    {/* <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </div> */}
                    <MUIDataTable
                        title={"Customer Duplicate Email Reports"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default CustomerDuplicateEmailReports
