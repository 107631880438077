import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, DatePicker, Button, Select, notification } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import moment from "moment";
import { Config } from "../../../Config";

const { Option } = Select;
const { RangePicker } = DatePicker;

function CustomerReport() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [genratedDateRange, setGenratedDateRange] = useState([]);

    const [fdateRange, setFdateRange] = useState([]);
    const [fPreDownloadReportID, setPreDownloadReportID] = useState('-1');

    const [search, setSearch] = useState(null);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);

    useEffect(() => {
        setLoading(true)
        const reportName = "Customer Report";
        ReportAPI.fetchProducts(reportName)
            .then(res => {
                // setProducts(res.data.products);
                setGenratedDateRange(res.data.genratedDateRange);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }, [])

    const options = {
        selectableRows: 'none',
        download: false,
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "CustomerReport.csv", separator: "," },
        elevation: 6,
        onChangeRowsPerPage: (numberOfRows) => {
            loadData(numberOfRows, page, search)
        },
        serverSide: true,
        count: Math.ceil(count),
        page: page,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    loadData(limit, tableState.page, search)
                    break;
                case 'search':
                    loadData(limit, tableState.page, tableState.searchText)
                    break;
            }
        },
        // onDownload: (buildHead, buildBody, columns, values) => {
        //     const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        //     const fileExtension = '.xlsx'
        //     // build the json, this is one way to do it
        //     const json = values.reduce((result, val) => {
        //         const temp = {}
        //         val.data.forEach((v, idx) => {
        //             temp[columns[idx].name] = v
        //         })
        //         result.push(temp)
        //         return result
        //     }, [])

        //     const fileName = `CustomerReport`
        //     const ws = utils.json_to_sheet(json)
        //     const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
        //     const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
        //     const data = new Blob([excelBuffer], { type: fileType })
        //     saveAs(data, fileName + fileExtension)
        //     // cancel default  CSV download from table
        //     return false
        // }
    };

    const columns = [
        "Customer Name",
        "Email",
        "Cellphone",
        "DOB",
        "Gender",
        "Policy Number",
        "Product Name",
        "Source Of Income",
        "Monthly Salary",
        "Quote Status"
    ];

    const loadData = async (limit, page, search) => {
        const data = {
            search: search,
            limit: limit,
            page: page,
            fdateRange: fdateRange,
            isDownload: 'no'
        }

        setSearch(search)
        setLimit(limit)
        setPage(page)
        setLoading(true)

        ReportAPI.getCustomerReports(data)
            .then(res => {
                let data = [];
                const records = res.data.records

                if (records.length > 0) {
                    setCount(records[0].totalCount)
                }
                
                records.forEach(report => {
                    let sourceOfIncome = '';
                    let monthlyIncome = '';
                    if (report.sourceOfIncome != '' && report.sourceOfIncome != 'null') {
                        if (typeof report.sourceOfIncome == 'string') {
                            if (report.sourceOfIncome.substring(0, 1) == '{') {
                                report.sourceOfIncome = JSON.parse(report.sourceOfIncome)
                                let outerJSON = Object.keys(report.sourceOfIncome)
                                if (typeof report.sourceOfIncome == 'object') {
                                    outerJSON.forEach((oval) => {
                                        try {
                                            let innerJSON = Object.keys(report.sourceOfIncome[oval]);
                                            innerJSON.forEach(ival => {
                                                sourceOfIncome += `#${oval} => ${ival}:${report.sourceOfIncome[oval][ival]}; `;
                                                if (ival == 'what_is_your_monthly_salary_?' || ival == 'what_is_your_monthly_income_?') {
                                                    monthlyIncome = report.sourceOfIncome[oval][ival];
                                                }
                                            });
                                        } catch (err) {
                                            console.log(err)
                                        }
                                        // {"bussiness\/Self-employment":{"name_of_your_bussiness":"Public transportation",
                                        // "bussiness_address":"Tlokweng","what_is_your_monthly_income_?":"5000.00"}}

                                        // {"employment":{"where_are_you_employed_?":"CA SALES","what_is_your_monthly_salary_?":"2000.00"}}
                                        // #employment => where_are_you_employed_?:Gaborone; 
                                        // #employment => what_is_your_monthly_salary_?:3000.00;
                                    });
                                }
                            }
                        }

                    } else {
                        sourceOfIncome = report.sourceOfIncome;
                    }

                    let customerName = '';

                    if (report.customerName) {
                        customerName = report.customerName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    let resData = [
                        report.customerName !== null ? customerName : '-',
                        report.email !== null && report.email != '' ? report.email : '-',
                        report.cellphone !== null ? report.cellphone : '-',
                        report.dob !== null ? moment(report.dob).format('DD-MM-YYYY') : '-',
                        report.gender == 1 ? 'Male' : 'Female',
                        report.policyNumber !== null ? report.policyNumber : 'NA',
                        report.productName !== null ? report.productName : 'NA',
                        sourceOfIncome,
                        monthlyIncome,
                        report.Quote_status !== null ? report.Quote_status : 'NA'
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickDownload = async () => {

        const reqData = {
            fdateRange: fdateRange,
            isDownload: 'yes'
        }

        setLoading(true);
        await ReportAPI.getCustomerReports(reqData)
            .then(res => {
                window.location.href = `${Config.apiNodeURL}/${res.data.filePath}`;

                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickPreDownload = async () => {
        if (fPreDownloadReportID == '-1') {
            return notification.error({ message: "Please select the Pre Generated Report." });
        }
        const reqData = {
            fPreDownloadReportID,
            isPreDownload: 'yes'
        }

        setLoading(true);

        await ReportAPI.getCustomerReports(reqData)
            .then(res => {
                window.location.href = `${Config.apiNodeURL}/${res.data.filePath}`;

                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickSerach = () => {
        loadData(limit, 0, search)
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Pre-Generated Report</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setPreDownloadReportID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content"
                                >
                                    <Option value="-1">Select Report Range</Option>
                                    {
                                        genratedDateRange.map((dr) => {
                                            return (
                                                <Option key={Math.random()} value={dr.id}>{`${dr.start_date} - ${dr.end_date}`}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col>
                                <div className="button_search_content">
                                    <Button onClick={onClickPreDownload} className="btn btn-success">Download Generated</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date   </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>
                            <Col xs={24} lg={1} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickSerach} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>

                            <Col xs={24} lg={1} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickDownload} className="btn btn-primary">Download</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Customer Report"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default CustomerReport
