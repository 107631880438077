import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, Input, DatePicker, Select, Button } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'

const { Option } = Select;
const { RangePicker } = DatePicker;

function TransactionReport() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);

    const [products, setProducts] = useState([]);
    const [productPlans, setProductPlans] = useState([]);
    const [agents, setAgents] = useState([]);

    const [fProductID, setFProductID] = useState('-1');
    const [fProductPlanID, setFProductPlanID] = useState('-1');
    const [fAgentID, setFAgentID] = useState('-1');
    const [fPaymentDateRange, setFPaymentDateRange] = useState([]);
    const [fPaymnetStatus, setFPaymnetStatus] = useState('-1');
    const [fTransactionType, setFTransactionType] = useState('-1');
    const [fPolicyNumber, setFPolicyNumber] = useState('');
    const [fReferenceNumber, setFReferenceNumber] = useState('');

    useEffect(async () => {
        setLoading(true)
        await ReportAPI.getTransactionReportsFilters()
            .then(res => {
                setProducts(res.data.products);
                setProductPlans(res.data.productPlans);
                setAgents(res.data.agents);

                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });

        // await onClickFilter();

    }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "VCSTransactionList.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    if (columns[idx].name === 'Premium Value') {
                        temp[columns[idx].name] = parseFloat(v);
                    } else {
                        temp[columns[idx].name] = v;
                    }
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `VCSTransactionList`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "Policy Number",
        "Product",
        "Product Plan",
        // "First Premium",
        "Premium Value",
        "Frquency",
        // "Billing Day",
        "Billing Date",
        "Agent",
        "Agency Name",
        "Store",
        "Reference Number",
        "Transaction Type",
        "Policy Status",
        "Transaction",
        "Transaction Reference",
        "Date Of Transaction",
        "Time Of Transaction",
    ];

    const onClickFilter = () => {
        const data = {
            fProductID: fProductID,
            fProductPlanID: fProductPlanID,
            fAgentID: fAgentID,
            fPaymentDateRange: fPaymentDateRange,
            fPaymnetStatus: fPaymnetStatus,
            fTransactionType: fTransactionType,
            fPolicyNumber: fPolicyNumber,
            fReferenceNumber: fReferenceNumber
        }

        setLoading(true)
        ReportAPI.getTransactionReport(data)
            .then(res => {
                let data = [];

                res.data.records.forEach(report => {

                    let premium_freq;
                    if (parseInt(report.premium_freq) === 1 || report.premium_freq === null) {
                        premium_freq = 'Monthly';
                    } else if (parseInt(report.premium_freq) === 2) {
                        premium_freq = 'Three Installments';
                    } else if (parseInt(report.premium_freq) === 3) {
                        premium_freq = 'Annual';
                    } else {
                        premium_freq = 'Frequency not described';
                    }

                    if (report.agentName) {
                        report.agentName = report.agentName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    
                    if(report.billingStartDate !== null){
                        if( report.billingStartDate.indexOf("/") !== -1){
                            report.billingStartDate = moment(report.billingStartDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
                        }
                    }

                    if(report.authorision_Date !== null){
                        if( report.authorision_Date.indexOf("/") !== -1){
                            report.authorision_Date = moment(report.authorision_Date, 'DD/MM/YYYY hh:mm A').format('YYYY-MM-DD hh:mm A');
                        }
                    }

                    let resData = [
                        report.policyNumber,
                        report.product_name,
                        report.product_plans_name,
                        // report.first_premium !== null ? report.first_premium : '-',
                        report.txn_amount !== null ? report.txn_amount : '0',
                        premium_freq,
                        // report.billing_day !== null ? report.billing_day : '-',
                        report.billingStartDate !== null ? moment(report.billingStartDate).format("DD-MM-YYYY") : '-',
                        report.agentName,
                        report.agencyName ? report.agencyName : '-',
                        report.store_name !== null ? report.store_name : '-',
                        report.reference !== null ? report.reference : 'N/A',
                        report.transType,
                        report.policy_status,
                        report.paymentStatus,
                        report.statusRef,
                        report.authorision_Date !== null ? moment(report.authorision_Date).format("DD-MM-YYYY") : 'N/A',
                        report.authorision_Date !== null ? moment(report.authorision_Date).format("hh:mm A") : 'N/A'
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Product</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setFProductID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    {
                                        products.map((product) => {
                                            return (
                                                <Option key={Math.random()} value={product.id}>{product.name}</Option>
                                            )
                                        })
                                    }
                                </Select>

                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Product Plan</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setFProductPlanID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    placeholder="All" className="select-content">
                                    <Option value="-1">All</Option>
                                    {
                                        productPlans.map((productPlan) => {
                                            return (
                                                <Option key={Math.random()} value={productPlan.id}>{productPlan.name}</Option>
                                            )
                                        })
                                    }
                                </Select>

                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Agent</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setFAgentID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    {
                                        agents.map((agent) => {
                                            return (
                                                <Option key={Math.random()} value={agent.id}>{`${agent.firstName} ${agent.lastName}`}</Option>
                                            )
                                        })
                                    }
                                </Select>

                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date of payment </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFPaymentDateRange(dateString) : setFPaymentDateRange([])
                                        }
                                    }
                                />
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By VCS Payment Status</label>
                                <Select style={{ width: '100%' }}
                                    onChange={(val) => setFPaymnetStatus(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    <Option value="Success">Success</Option>
                                    <Option value="Failed">Failed</Option>
                                </Select>
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Transaction Type</label>
                                <Select style={{ width: '100%' }}
                                    onChange={(val) => setFTransactionType(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    <Option value="First">First</Option>
                                    <Option value="Recurring">Recurring</Option>
                                </Select>
                            </Col>
                            <Col xs={24} lg={5} className="select_content ">
                                <label>Please enter policy number</label>
                                <div className="">
                                    <Input placeholder="Please enter policy number" onChange={(e) => setFPolicyNumber(e.target.value)} />
                                </div>
                            </Col>
                            <Col xs={24} lg={5} className="select_content ">
                                <label>Filter By Reference Number</label>
                                <div className="">
                                    <Input placeholder="Please enter reference number" onChange={(e) => setFReferenceNumber(e.target.value)} />
                                </div>
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"VCS Transaction List"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment >
    )
}

export default TransactionReport
