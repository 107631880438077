import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Col, Row, DatePicker, Button, Spin } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'

const { RangePicker } = DatePicker;
export default function NewWrittenPremiumReport() {

    const [loading, setLoading] = useState(false);
    const [policies, setPolicies] = useState([]);
    const [fdateRange, setFdateRange] = useState([]);

    // useEffect(async () => {
    //     await onClickFilter();
    // }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100, 200],
        downloadOptions: { filename: "writtenPremiumReport.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    if (columns[idx].name === 'Written Premum') {
                        temp[columns[idx].name] = parseFloat(v);
                    } else {
                        temp[columns[idx].name] = v;
                    }
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `writtenPremiumReport`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "Policy No.",
        "Insured Name",
        "Seq.",
        "Trans Type",
        "Term Start",
        "Term End",
        "Booking Date",
        "Trans Eft. Start",
        "Trans Eft. End",
        "Written Premum",
        // "Payment Method",
        "Product Name",
        "Make",
        "Model"
    ];

    const onClickFilter = () => {

        setLoading(true)
        const data = { fdateRange: fdateRange }

        ReportAPI.fetchFilterNewWrittenPremiumReports(data)
            .then(res => {

                let data = [];
                let cancelledPolicies = [];
                var instWithPolicy = {
                    "0": {
                        policy_number: ''
                    }
                };

                res.data.policies.forEach((policy, index) => {
                    if (cancelledPolicies[policy.policyNumber] != undefined) {
                        return false;
                    }

                    let trans_type;

                    if (policy.status === 2) {
                        trans_type = "Cancelled";
                        cancelledPolicies[policy.policyNumber] = policy.policyNumber;
                    } else {
                        // var lastYear = moment().subtract(1, 'years').format('YYYY');
                        // let isafter = moment(policy.created_at).isAfter(moment(`${lastYear}-07-01`))
                        // let isafter = moment(policy.invoice_date).isAfter(moment(`${lastYear}-07-01`))

                        // if (isafter) {
                        //     trans_type = "New Business";
                        // } else {
                        //     trans_type = "Renew"
                        // }

                        if (policy.invoice_no.substr(policy.invoice_no.length - 3) == '001') {
                            trans_type = "New Business";
                        } else {
                            trans_type = "Renew"
                        }
                    }

                    let term_start = "N/A";
                    let term_end = "N/A";
                    let booking_date = "N/A";
                    let trans_eft_start = "N/A";

                    term_start = policy.accounting_date === "" ? "N/A" : moment(policy.accounting_date).format('DD-MM-YYYY');

                    var instWithPolicySize = 1;

                    if (instWithPolicy[`${policy.policyNumber}`] == undefined) {

                        var cd = `{
                            "${policy.policyNumber}": 
                                { 
                                    "policy_number": "${policy.policyNumber}"
                                }
                            }`;
                        Object.assign(instWithPolicy, JSON.parse(cd));
                        instWithPolicySize = Object.keys(instWithPolicy[`${policy.policyNumber}`]).length;
                    } else {
                        instWithPolicySize = Object.keys(instWithPolicy[`${policy.policyNumber}`]).length;
                    }

                    if (
                        policy.product_id == '3'
                        && parseFloat(policy.policies_written_premum) > parseFloat(policy.policy_ledger_written_premum)
                        && instWithPolicySize == 1
                    ) {

                        cd = `{
                                "${policy.policyNumber}": 
                                    { 
                                        "policy_number": "${policy.policyNumber}",
                                        "inst": "1"
                                    }
                                }`;
                        Object.assign(instWithPolicy, JSON.parse(cd));
                        term_end = moment(policy.billingStartDate, 'YYYY-MM-DD').subtract(1, 'days').format('DD-MM-YYYY');

                    } else if (policy.premium_freq == '1' || policy.premium_freq == null) {
                        term_end = moment(policy.accounting_date, 'YYYY-MM-DD').add(1, 'months').subtract(1, 'days').format('DD-MM-YYYY');

                    } else {
                        term_end = moment(policy.accounting_date, 'YYYY-MM-DD').add(1, 'years').subtract(1, 'days').format('DD-MM-YYYY');
                    }

                    booking_date = policy.invoice_date === "" ? "N/A" : moment(policy.invoice_date).format('DD-MM-YYYY');
                    trans_eft_start = policy.eff_date === "" ? "N/A" : moment(policy.eff_date).format('DD-MM-YYYY');

                    let written_premum = policy.policy_ledger_written_premum;

                    if (policy.insured_name !== '-' && policy.insured_name !== null) {
                        policy.insured_name = policy.insured_name.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    let resData = [
                        policy.policyNumber,
                        policy.insured_name ? policy.insured_name : '-',
                        policy.seq,
                        
                        trans_type,
                        term_start,
                        term_end,
                        booking_date,
                        trans_eft_start,
                        
                        policy.trans_eft_end,
                        written_premum,
                        // policy.paymentMethod,
                        policy.product_name,
                        policy.make,
                        policy.model
                    ];
                    
                    data = [resData, ...data]
                });
                
                setLoading(false)
                setPolicies(data)
            }).catch(error => {
                setLoading(false)
            });

    }
    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date range </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Written Premium Report"}
                        data={policies}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}
