import jsPDF from "jspdf";
import "jspdf-autotable";
// Date Fns is used to format the dates we receive
// from our API call
// import { format } from "date-fns";

// define a GeneratePDF function that accepts a tickets argument
const GeneratePDF = (accidental, motorThirdParty, motorComprehensive) => {

  // initialize jsPDF
  const doc = new jsPDF();

  // define the columns we want and their titles

  const accidentalTableColumn = accidental.accidentalColumns;
  const accidentalData = accidental.accidentalData;

  const motorThirdPartyTableColumn = motorThirdParty.motorThirdPartyColumns;
  const motorThirdPartyData = motorThirdParty.motorThirdPartyData;

  const motorComprehensiveTableColumn = motorComprehensive.motorComprehensiveColumns;
  const motorComprehensiveData = motorComprehensive.motorComprehensiveData;

  // define an empty array of rows
  const accidentalDataTableRows = [];

  accidentalData.forEach(life => {
    let data = [life.policycount, life.premium, life.claimcount, life.payments, life.totalreserve];
    accidentalDataTableRows.push(data);
  });

  const motorThirdPartyDataTableRows = [];
  motorThirdPartyData.forEach(veh => {
    let data = [veh.riskband, veh.suminsured, veh.policycount, veh.premium, veh.claimcount, veh.payments, veh.totalreserve];
    motorThirdPartyDataTableRows.push(data);
  });

  const motorComprehensiveDataTableRows = [];
  motorComprehensiveData.forEach(veh => {
    let data = [veh.riskband, veh.suminsured, veh.policycount, veh.premium, veh.claimcount, veh.payments, veh.totalreserve];
    motorComprehensiveDataTableRows.push(data);
  });

  // ticket title. and margin-top + margin-left
  doc.text("Reinsurance Risk Profile Summary Report", 50, 17);
  doc.text("Accidental Insurance", 14, 25);

  // startY is basically margin-top
  doc.autoTable(accidentalTableColumn, accidentalDataTableRows, {
    startY: 28,
    styles: {
      cellPadding: 0,
      rowHeight: 7,
      fillStyle: 'S',
      lineWidth: 0.01,
      halign: 'center',
      valign: 'middle'
    },
    margin: [10, 14, 0, 14],
    bodyStyles: { lineColor: [0, 0, 0, 0] },
    drawHeaderRow: function () { return false; }
  });

  doc.text("Motor Third-party Insurance", 14, 52);
  doc.autoTable(motorThirdPartyTableColumn, motorThirdPartyDataTableRows,
    {
      startY: 55,
      styles: {
        cellPadding: 0,
        rowHeight: 7,
        fillStyle: 'S',
        lineWidth: 0.01,
        halign: 'center',
        valign: 'middle'
      },
      margin: [10, 14, 0, 14],
      bodyStyles: { lineColor: [0, 0, 0, 0] },
      drawHeaderRow: function () { return false; }
    });
  // doc.autoTable(motorThirdPartyTableColumn, motorThirdPartyDataTableRows, { startY: 55 });
  doc.text("Motor Comprehensive Insurance", 14, 92);
  doc.autoTable(motorComprehensiveTableColumn, motorComprehensiveDataTableRows, {
    startY: 95,
    styles: {
      cellPadding: 0,
      rowHeight: 7,
      fillStyle: 'S',
      lineWidth: 0.01,
      halign: 'center',
      valign: 'middle'
    },
    margin: [10, 14, 0, 14],
    bodyStyles: { lineColor: [0, 0, 0, 0] },
    drawHeaderRow: function () { return false; }
  });

  // const date = Date().split(" ");
  // // we use a date string to generate our filename.
  // const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];

  // doc.save(`report_${dateStr}.pdf`);
  doc.save(`Reinsurance_Summary.pdf`);
};

export default GeneratePDF;