import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, DatePicker, Button } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'

const { RangePicker } = DatePicker;

function ReInsuranceRiskProfile() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);

    const [fdateRange, setFdateRange] = useState([]);

    // useEffect(async () => {
    //     setLoading(true)
    //     await onClickFilter();
    // }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "ReInsuranceRiskProfile.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    if (columns[idx].name === 'Total Sum Insured' || columns[idx].name === 'Total Premium') {
                        temp[columns[idx].name] = parseFloat(v);
                    } else {
                        temp[columns[idx].name] = v;
                    }
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `ReInsuranceRiskProfile`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "Policy No",
        "Term Start Date",
        "Term End Date",
        "Insured Name",
        "Tran Type",
        "Riks Name",
        "Motor Desc",
        "Group Code",
        "Total Sum Insured",
        "Total Premium",
        "Regulatory Mapping Name",
        "Risk Band",
        "MAX TRANS YES / NO",
        "Booking Date"
    ];

    const onClickFilter = () => {

        const data = { fdateRange: fdateRange }

        setLoading(true)
        ReportAPI.getReInsuranceRiskProfile(data)
            .then(res => {
                let data = [];

                res.data.records.forEach(report => {

                    let term_start = report.accounting_date;
                    if (report.accounting_date !== '0000-00-00') {
                        term_start = moment(report.accounting_date, 'YYYY-MM-DD').format('DD-MM-YYYY');
                    }

                    let term_end = report.accounting_date;

                    if (report.premium_freq > 1 && report.accounting_date !== '0000-00-00') {
                        term_end = moment(report.accounting_date, 'YYYY-MM-DD').add(1, 'years').subtract(1, 'days').format('DD-MM-YYYY');
                    } else if(report.accounting_date !== '0000-00-00'){
                        term_end = moment(report.accounting_date, 'YYYY-MM-DD').add(1, 'months').subtract(1, 'days').format('DD-MM-YYYY');
                    }


                    let trans_type = "RE ISSUE";
                    if (moment() > moment(report.created_at)) {
                        trans_type = "NEW BUSINESS";
                    }

                    let group_code = 'PERSONALACCIDENTDOM';
                    if (report.product_id === 2 || report.product_id === 3) {
                        group_code = 'MOTORDOMTP';
                    }

                    let netretntion = report.sum_assured;
                    let risk_band;
                    if (netretntion >= 0 && netretntion <= 250000) {
                        risk_band = '0 To 250000';
                    } else if (netretntion >= 250001 && netretntion <= 500000) {
                        risk_band = '250001 To 500000';
                    } else if (netretntion >= 500001 && netretntion <= 1000000) {
                        risk_band = '500001 To 1000000';
                    } else if (netretntion >= 1000001 && netretntion <= 5000000) {
                        risk_band = '1000001 To 5000000';
                    }

                    let booking_date = moment(report.policy_ledger_created_at).format('DD-MM-YYYY')

                    let resData = [
                        report.policyNumber,
                        term_start,
                        term_end,
                        report.customerName,
                        trans_type,
                        report.risk_name ? report.risk_name : 'N/A',
                        report.vehiclePlate ? report.vehiclePlate : 'N/A',
                        group_code,
                        report.sum_assured,
                        report.premium,
                        "Motor",
                        risk_band,
                        report.status === 'Paid' ? 'YES' : 'NO',
                        booking_date
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date   </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Re Insurance Risk Profile"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default ReInsuranceRiskProfile
