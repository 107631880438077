import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, DatePicker, Button } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'

const { RangePicker } = DatePicker;

function ClaimCoverageAllocationReport() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);

    const [fdateRange, setFdateRange] = useState([]);

    // useEffect(async () => {
    //     setLoading(true)
    //     await onClickFilter();
    // }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "ClaimBordereauxOutstandingPayment.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    temp[columns[idx].name] = v
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `ClaimBordereauxOutstandingPayment`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "Claim No",
        "Policy Number",
        "Product Name",
        "Insured Name",
        "Date Of Loss",
        "Reserve Date",
        "Reported Date",
        "Claim Type",
        "Trans Type",
        "Trans Sub Type",
        "Group Name",
        "Coverag Name",
        "Reserves / Payment",
        "Motor Desc",
        "NetRetenstion %",
        "Quota %",
        "Surplus %",
        "Facultative %",
        "Netretention_Amt",
        "Quotasharing_Amt",
        "Surplus_Amt",
        "Facultative_Amt",
        "PORiskMasterFK",
        "ParentCoverageCode",
        "s_TreatyName"
    ];

    const onClickFilter = () => {
        const data = { fdateRange: fdateRange }

        setLoading(true)
        ReportAPI.getClaimCoverageAllocationReport(data)
            .then(res => {
                let data = [];

                res.data.records.forEach(report => {

                    let productName = report.productName.toLowerCase().replace(/\b[a-z]/g, function(letra) {
                        return letra.toUpperCase();
                    });
                    let customerName = report.customerName.toLowerCase().replace(/\b[a-z]/g, function(letra) {
                        return letra.toUpperCase();
                    });

                    let resData = [
                        report.claim_number,
                        report.policyNumber,
                        productName,
                        customerName,
                        report.lossDate !== null ? report.lossDate : 'NA',
                        report.claim_number !== null ? '-' : 'NA',
                        report.date_reported_to_alpha !== null ? report.date_reported_to_alpha : 'NA',
                        report.claim_type !== null ? report.claim_type : 'NA',
                        report.transactionType !== null ? report.transactionType : 'NA',
                        report.transSubType !== null ? report.transSubType : 'NA',
                        report.claim_number !== null ? '-' : 'NA',
                        report.claim_number !== null ? '-' : 'NA',
                        report.reserve_amount !== null ? report.reserve_amount : 'NA',
                        report.claim_number !== null ? '-' : 'NA',
                        report.claim_number !== null ? '-' : 'NA',
                        report.claim_number !== null ? '-' : 'NA',
                        report.claim_number !== null ? '-' : 'NA',
                        report.claim_number !== null ? '-' : 'NA',
                        report.claim_number !== null ? '-' : 'NA',
                        report.claim_number !== null ? '-' : 'NA',
                        report.claim_number !== null ? '-' : 'NA',
                        report.claim_number !== null ? '-' : 'NA',
                        report.claim_number !== null ? '-' : 'NA',
                        report.claim_number !== null ? '-' : 'NA',
                        report.claim_number !== null ? '-' : 'NA'
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date   </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Claim Coverage Allocation Report"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default ClaimCoverageAllocationReport
