import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Col, Row, DatePicker, Button, Spin, notification, Select } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import { Config } from "../../../Config";

const { Option } = Select;
const { RangePicker } = DatePicker;
export default function EarnedPremiumWithUnearned() {

    const [loading, setLoading] = useState(false);
    const [policies, setPolicies] = useState([]);
    const [genratedDateRange, setGenratedDateRange] = useState([]);

    const [fdateRange, setFdateRange] = useState([]);
    const [fPreDownloadReportID, setPreDownloadReportID] = useState('-1');

    const [search, setSearch] = useState(null);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);

    useEffect(() => {
        setLoading(true)
        const reportName = "Earned Premium With Unearned";
        ReportAPI.fetchProducts(reportName)
            .then(res => {
                // setProducts(res.data.products);
                setGenratedDateRange(res.data.genratedDateRange);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }, [])

    const options = {
        selectableRows: 'none',
        download: false,
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100, 200, 500],
        downloadOptions: { filename: "EarnedPremiumWithUnearned.csv", separator: "," },
        elevation: 6,
        onChangeRowsPerPage: (numberOfRows) => {
            loadData(numberOfRows, page, search)
        },
        serverSide: true,
        count: Math.ceil(count),
        page: page,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    loadData(limit, tableState.page, search)
                    break;
                case 'search':
                    loadData(limit, tableState.page, tableState.searchText)
                    break;
            }
        },
        // onDownload: (buildHead, buildBody, columns, values) => {
        //     const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        //     const fileExtension = '.xlsx'
        //     // build the json, this is one way to do it
        //     const json = values.reduce((result, val) => {
        //         const temp = {}
        //         val.data.forEach((v, idx) => {
        //             if (columns[idx].name === 'Written Premium' || columns[idx].name === 'Period Earned Premium'
        //                 || columns[idx].name === 'Unearned Premium' || columns[idx].name === 'Total Earned Premium'
        //                 || columns[idx].name === 'VAT Earned' || columns[idx].name === 'VAT Unearned'
        //                 || columns[idx].name === 'Levy Earned' || columns[idx].name === 'Levy Unearned') {
        //                 temp[columns[idx].name] = parseFloat(v);
        //             } else {
        //                 temp[columns[idx].name] = v;
        //             }
        //         })
        //         result.push(temp)
        //         return result
        //     }, [])

        //     const fileName = `EarnedPremiumWithUnearned`
        //     const ws = utils.json_to_sheet(json)
        //     const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
        //     const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
        //     const data = new Blob([excelBuffer], { type: fileType })
        //     saveAs(data, fileName + fileExtension)
        //     // cancel default  CSV download from table
        //     return false
        // }
    };

    const columns = [
        "Policy No ",
        "Product",
        "Insured Name",
        "Transaction Type",
        "No Of Days",
        "Written Premium",
        "Period Earned Premium",
        "Unearned Premium",
        "Total Earned Premium",
        "Term Start Date",
        "Term End Date",
        "Trans Effective From",
        "Trans Effective To",
        "Booking Date",
        "VAT Earned",
        "VAT Unearned",
        "Levy Earned",
        "Levy Unearned"
    ];

    const loadData = async (limit, page, search) => {
        const data = {
            isDownload: 'no',
            search: search,
            limit: limit,
            page: page,
            fdateRange: fdateRange
        }

        setSearch(search)
        setLimit(limit)
        setPage(page)
        setLoading(true)

        ReportAPI.fetchFilterEarnedPremiumWithUnearnedReports(data)
            .then(res => {
                const policies = res.data.policies
                if (policies.length > 0) {
                    setCount(policies[0].totalCount)
                }

                let data = [];
                let now = moment();
                let financialYearStart = moment();
                financialYearStart.set('month', 6);
                financialYearStart.set('date', 1);
                if (financialYearStart > now) {
                    financialYearStart.subtract(1, 'years')
                }

                let financialYearEnd = moment();
                financialYearEnd.set('month', 6);
                financialYearEnd.set('date', 1);
                if (financialYearEnd < now) {
                    financialYearStart.add(1, 'years')
                }

                policies.forEach(policy => {

                    let s_transaction_type = moment().diff(policy.created_at, 'years', false);

                    if (s_transaction_type === 2) {
                        s_transaction_type = "Cancel";
                    } else if (s_transaction_type === 0) {
                        s_transaction_type = "New Business";
                    } else {
                        s_transaction_type = "Renew";
                    }

                    let financialYearDays = moment(financialYearEnd).diff(financialYearStart, 'days', false);

                    let no_of_days;
                    if (moment(policy.created_at) > financialYearStart) {
                        no_of_days = moment(now).diff(policy.created_at, 'days', false);
                    } else {
                        no_of_days = moment(policy.created_at).diff(financialYearStart, 'days', false);
                    }

                    let written_premum;
                    if (policy.premium_freq === 1) { //For Monthly Motor Comp remove 8% Sales Tax
                        written_premum = Math.round((((policy.premium * 12) * 0.88) * 0.92)).toFixed(2);
                    } else {
                        written_premum = Math.round((policy.premium * 12) * 0.88).toFixed(2);
                    }

                    let earned_premium = Math.round((written_premum / 365.25) * no_of_days).toFixed(2);

                    var earned_premium_1year = (written_premum / 365.25) * financialYearDays;
                    let unearned_premium = Math.round((earned_premium_1year - earned_premium)).toFixed(2);

                    let total_earned_premium = Math.round((written_premum / 365.25) * no_of_days).toFixed(2);

                    let term_start_date = moment(policy.billingStartDate, 'YYYY-MM-DD').format('DD-MM-YYYY');

                    let term_end_date;
                    let d_trance_effective_from = moment(policy.billingStartDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
                    let d_trance_effective_to;

                    if (policy.premium_freq > 1) {
                        term_end_date = moment(policy.billingStartDate, 'YYYY-MM-DD').add(1, 'years').subtract(1, 'days').format('DD-MM-YYYY');
                        d_trance_effective_to = moment(policy.billingStartDate, 'YYYY-MM-DD').add(1, 'years').subtract(1, 'days').format('DD-MM-YYYY');
                    } else {
                        term_end_date = moment(policy.billingStartDate, 'YYYY-MM-DD').add(1, 'months').subtract(1, 'days').format('DD-MM-YYYY');
                        d_trance_effective_to = moment(policy.billingStartDate, 'YYYY-MM-DD').add(1, 'months').subtract(1, 'days').format('DD-MM-YYYY');
                    }

                    if (policy.productName !== 'N/A' && policy.productName !== null) {
                        policy.productName = policy.productName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (policy.customerName !== 'N/A' && policy.customerName !== null) {
                        policy.customerName = policy.customerName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    let resData = [
                        policy.policyNumber,
                        policy.productName ? policy.productName : "N/A",
                        policy.customerName ? policy.customerName : "N/A",
                        s_transaction_type,
                        no_of_days,
                        written_premum,
                        earned_premium,
                        unearned_premium,
                        total_earned_premium,

                        term_start_date,
                        term_end_date,
                        d_trance_effective_from,
                        d_trance_effective_to,
                        moment(policy.booking_date).format('DD-MM-YYYY'),
                        (earned_premium * 0.12).toFixed(2),
                        (unearned_premium * 0.12).toFixed(2),
                        (earned_premium * 0.08).toFixed(2),
                        (unearned_premium * 0.08).toFixed(2)
                    ];
                    data = [resData, ...data]
                });

                setLoading(false)
                setPolicies(data)
            }).catch(error => {
                setLoading(false)
            });
    }

    const onClickDownload = async () => {

        const reqData = {
            isDownload: 'yes',
            fdateRange: fdateRange
        }

        setLoading(true)
        ReportAPI.fetchFilterEarnedPremiumWithUnearnedReports(reqData)
            .then(res => {
                window.location.href = `${Config.apiNodeURL}/${res.data.filePath}`;
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickPreDownload = async () => {
        if (fPreDownloadReportID == '-1') {
            return notification.error({ message: "Please select the Pre Generated Report." });
        }
        const reqData = {
            fPreDownloadReportID,
            isPreDownload: 'yes'
        }

        setLoading(true);

        await ReportAPI.fetchFilterEarnedPremiumWithUnearnedReports(reqData)
            .then(res => {
                window.location.href = `${Config.apiNodeURL}/${res.data.filePath}`;

                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickSerach = () => {
        loadData(limit, 0, search)
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Pre-Generated Report</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setPreDownloadReportID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content"
                                >
                                    <Option value="-1">Select Report Range</Option>
                                    {
                                        genratedDateRange.map((dr) => {
                                            return (
                                                <Option key={Math.random()} value={dr.id}>{`${dr.start_date} - ${dr.end_date}`}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col>
                                <div className="button_search_content">
                                    <Button onClick={onClickPreDownload} className="btn btn-success">Download Generated</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date range </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>

                            <Col xs={24} lg={1} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickSerach} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>

                            <Col xs={24} lg={1} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickDownload} className="btn btn-primary">Download</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="muidTable">
                        <MUIDataTable
                            title={"Earned Premium With Unearned"}
                            data={policies}
                            columns={columns}
                            options={options}
                        />
                    </div>

                </div>
            </Spin>
        </Fragment>
    )
}
