import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, DatePicker, Button, Select } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'

const { Option } = Select;
const { RangePicker } = DatePicker;

function VirtualBoxReport() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [agents, setAgents] = useState([]);
    const [agencies, setAgencies] = useState([]);

    const [fAgentID, setFAgentID] = useState('-1');
    const [fAgencyID, setFAgencyID] = useState('-1');
    const [fdateRange, setFdateRange] = useState([]);

    useEffect(async () => {
        setLoading(true)
        ReportAPI.getAgentsForVirtualBoxReport()
            .then(res => {
                setAgents(res.data.agents);
                setAgencies(res.data.agencies);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "VirtualBoxReport.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    temp[columns[idx].name] = v
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `VirtualBoxReport`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        "Customer",
        "Email",
        "Agent",
        "Agency Name",
        "Policy Number",
        "Name",
        "Quote Status",
        "Reference"
    ];

    const onClickFilter = () => {
        const data = {
            fdateRange: fdateRange,
            fAgentID: fAgentID,
            fAgencyID: fAgencyID
        }

        setLoading(true)
        ReportAPI.getVirtualBoxReport(data)
            .then(res => {
                let data = [];

                res.data.records.forEach(report => {

                    let customerName = report.Customer.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                        return letra.toUpperCase();
                    });
                    let agentName = report.Agent.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                        return letra.toUpperCase();
                    });

                    let resData = [
                        customerName,
                        report.email ? report.email : '-',
                        agentName,
                        report.agencyName ? report.agencyName : '-',
                        report.policyNumber,
                        report.name,
                        report.Quote_status,
                        report.reference
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Agent</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setFAgentID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    {
                                        agents.map((agent) => {
                                            agent.agentName = agent.agentName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                                                return letra.toUpperCase();
                                            });

                                            return (
                                                <Option key={Math.random()} value={agent.id}>{agent.agentName}</Option>
                                            )
                                        })
                                    }
                                </Select>

                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Agency</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setFAgencyID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    {
                                        agencies.map((agency) => {

                                            agency.name = agency.name.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                                                return letra.toUpperCase();
                                            });

                                            return (
                                                <Option key={Math.random()} value={agency.id}>{agency.name}</Option>
                                            )
                                        })
                                    }
                                </Select>

                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date   </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Virtual Box Report"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default VirtualBoxReport
