import React, { Fragment, useState, useEffect } from "react";
import { Spin, Col, Row, DatePicker, Select, Input, Button, notification } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import readXlsxFile from 'read-excel-file'
import MUIDataTable from "mui-datatables";
import { Config } from "../../../Config";
import moment from "moment";

function VCSRecurringPaymentImport() {

    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState([]);
    const [filesData, setFileData] = useState([]);

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async (e) => {
        ReportAPI.fetchPaymentTransactionSettlement("Recurring")
            .then(res => {
                let data = [];
                res.data.records.forEach(record => {
                    let resData = [
                        moment(record.datetime).format('YYYY-MM-DD HH:mm:ss'),
                        record.file_path ? 'Completed' : 'Inprocessing',
                        record.file_path
                    ];

                    data = [resData, ...data]
                })

                setFileData(data);

                setLoading(false)
            }).catch(error => {
                setLoading(false)
                console.log(error)
            })
    }

    const handleChange = async (e) => {
        if (e.target.files[0] != undefined) {
            readXlsxFile(e.target.files[0]).then((rows) => {
                setSelectedFile(rows)
            })
        } else {
            setSelectedFile([])
        }
    };

    const onClickSerach = () => {
        if (selectedFile.length == 0) {
            return notification.error({ message: "Please select the file." });
        }
        setLoading(false)
        ReportAPI.importRecurringPaymentTransactionSettlement(selectedFile)
            .then(res => {
                loadData()
                // notification.success({ message: res.data.message })
                setLoading(false)
            }).catch(error => {
                setLoading(false)
                console.log(error)
            })

        setTimeout(() => { loadData() }, 1000)
        // notification.success({ message: 'Record has been saved successfully.' })
    }

    const options = {
        selectableRows: 'none',
        download: false,
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        elevation: 6
    };

    const columns = [
        { name: "Requested Date" },
        { name: "Status" },
        {
            name: "Action",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            {
                                value ?
                                    <Button className="btn btn-primary" onClick={() => {
                                        window.location.href = `${Config.apiNodeURL}/${value}`;
                                    }}>
                                        Download
                                    </Button> : '-'
                            }
                        </>
                    )
                }
            }
        }
    ];

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">

                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={24} className="select_content">
                                <h4 >Import VCS Recurring Payment Transaction Settlement</h4>
                                <a className="ant-dropdown-link userlink" href={`${Config.apiNodeURL}/storage/sample/sample_RecurringPayment.xlsx`}>Download Sample</a>
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Choose File</label>
                                <Input onChange={handleChange} type="file" accept=".xlsx, .xls" />
                            </Col>

                            <Col xs={12} lg={1} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickSerach} className="btn btn-primary">Submit</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"VCS Recurring Payment Import (Background Result)"}
                        data={filesData}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment >
    )
}

export default VCSRecurringPaymentImport
