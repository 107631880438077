import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Col, Row, Select, Button, DatePicker, notification } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import { Config } from "../../../Config";
import { useSelector } from "react-redux";

const { Option } = Select;
const { RangePicker } = DatePicker;

function CustomerAllKYCDocumentsReport() {
    const countryCode = useSelector(state => state.mainCountry);
    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [products, setProducts] = useState([]);
    const [genratedDateRange, setGenratedDateRange] = useState([]);

    const [fProductIds, setFProductIds] = useState([]);
    const [fComplianceStatus, setFComplianceStatus] = useState('-1');
    const [fdateRange, setFdateRange] = useState([]);
    const [fPolicyStatus, setFPolicyStatus] = useState('-1');
    const [fPreDownloadReportID, setPreDownloadReportID] = useState('-1');

    const [search, setSearch] = useState(null);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);

    useEffect(async () => {
        setLoading(true)
        const reportName = "Customer All KYC Documents Report";
        ReportAPI.fetchProducts(reportName, countryCode)
            .then(res => {
                setProducts(res.data.products);
                setGenratedDateRange(res.data.genratedDateRange);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });

    }, []);

    const options = {
        selectableRows: 'none',
        download: false,
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "CustomerAllKYCDocumentsReport.csv", separator: "," },
        elevation: 6,
        onChangeRowsPerPage: (numberOfRows) => {
            loadData(numberOfRows, page, search)
        },
        serverSide: true,
        count: Math.ceil(count),
        page: page,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    loadData(limit, tableState.page, search)
                    break;
                case 'search':
                    loadData(limit, tableState.page, tableState.searchText)
                    break;
            }
        },
    };

    const columns = [
        "Policy Number",
        "Customer Name",
        "Contact Number",
        "Agent Name",
        "Agency name",
        "Policy Status",
        "Product Name",
        // "Payment Status",
        "Omang Front",
        "Omang Back",
        "Passport",
        "Proof of Residence",
        "Proof of Income",
        "License",
        "Compliance",
        "Remark",
        "Reason",
        "Mati Verification"
    ];

    const loadData = async (limit, page, search) => {
        const data = {
            isDownload: 'no',
            search: search,
            limit: limit,
            page: page,
            fComplianceStatus: fComplianceStatus,
            fdateRange: fdateRange,
            fProductIds: fProductIds,
            fPolicyStatus: fPolicyStatus
        }

        setSearch(search)
        setLimit(limit)
        setPage(page)
        setLoading(true)
        ReportAPI.getCustomerAllKYCDocumentsReport(data)
            .then(res => {
                let data = [];
                const reports = res.data.reports
                if (reports.length > 0) {
                    setCount(reports[0].totalCount)
                }

                reports.forEach(policy => {

                    if (policy.Customer) {
                        policy.Customer = policy.Customer.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (policy.Agent) {
                        policy.Agent = policy.Agent.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (policy.agencyName) {
                        policy.agencyName = policy.agencyName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (policy.productName) {
                        policy.productName = policy.productName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    let resData = [
                        policy.policyNumber,
                        policy.Customer,
                        policy.contact_number,
                        policy.Agent,
                        policy.agencyName ? policy.agencyName : '-',
                        // '-',
                        policy.policy_status,
                        policy.productName,
                        policy.omang,
                        policy.omangBack,
                        policy.passport,
                        policy.proof_residence,
                        policy.proof_income,
                        policy.driving_license,
                        policy.compliance,
                        policy.customer_kyc_remark,
                        policy.customer_kyc_reason,
                        policy.mati_identity
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false)
            }).catch(error => {
                setLoading(false)
            });
    }

    const onClickDownload = async () => {

        const reqData = {
            isDownload: 'yes',
            fComplianceStatus: fComplianceStatus,
            fdateRange: fdateRange,
            fProductIds: fProductIds,
            fPolicyStatus: fPolicyStatus
        }

        setLoading(true)
        ReportAPI.getCustomerAllKYCDocumentsReport(reqData)
            .then(res => {
                window.location.href = `${Config.apiNodeURL}/${res.data.filePath}`;
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickPreDownload = async () => {
        if (fPreDownloadReportID == '-1') {
            return notification.error({ message: "Please select the Pre Generated Report." });
        }
        const reqData = {
            fPreDownloadReportID,
            isPreDownload: 'yes'
        }

        setLoading(true);

        await ReportAPI.getCustomerAllKYCDocumentsReport(reqData)
            .then(res => {
                window.location.href = `${Config.apiNodeURL}/${res.data.filePath}`;

                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickSerach = () => {
        loadData(limit, 0, search)
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Pre-Generated Report</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setPreDownloadReportID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content"
                                >
                                    <Option value="-1">Select Report Range</Option>
                                    {
                                        genratedDateRange.map((dr) => {
                                            let isInceptionReport = ''
                                            if (dr.is_inception_to_till_date == 1) {
                                                isInceptionReport = '(Inception to Till Date)'
                                            }

                                            return (
                                                <Option key={Math.random()} value={dr.id}>{`${dr.start_date} - ${dr.end_date} ${isInceptionReport}`}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col lg={4}>
                                <div className="button_search_content">
                                    <Button onClick={onClickPreDownload} className="btn btn-success">Download Generated</Button>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="button_search_content">
                                    <Button onClick={() => {
                                        genratedDateRange.map((dr) => {
                                            if (dr.is_inception_to_till_date == 1) {
                                                window.location.href = `${Config.apiNodeURL}/${dr.report_path}`;
                                            }
                                        })
                                    }} className="btn btn-primary">Download From Inception to Till Date</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date </label>
                                <RangePicker allowClear
                                    format="YYYY-MM-DD"
                                    onChange={
                                        (value, dateString) => {
                                            value !== null ? setFdateRange(dateString) : setFdateRange([])
                                        }
                                    }
                                />
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Compliance</label>
                                <Select style={{ width: '100%' }}
                                    onChange={(val) => setFComplianceStatus(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    <Option value="0">Verification pending/Not uploaded</Option>
                                    <Option value="1">KYC Compliant</Option>
                                    <Option value="2">KYC Not-Compliant</Option>
                                </Select>
                            </Col>

                            <Col xs={24} lg={4} className="select_content">
                                <label>Filter By Policy Status</label>
                                <Select style={{ width: '100%' }}
                                    onChange={(val) => setFPolicyStatus(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    <Option value="0">Deactivated</Option>
                                    <Option value="1">Activated</Option>
                                    <Option value="2">Cancelled</Option>
                                </Select>
                            </Col>


                            <Col xs={24} lg={4} className="select_content">
                                <label>Select Product</label>
                                <Select style={{ width: '100%' }}
                                    mode="multiple"
                                    allowClear
                                    onChange={(val) => setFProductIds(val)}
                                    // defaultValue="-1"
                                    optionFilterProp="children"
                                    className="select-content">
                                    {/* <Option value="-1">All</Option> */}
                                    {
                                        products.map(product => {
                                            return (<Option key={product.id} value={product.id}>{product.name}</Option>)
                                        })
                                    }
                                </Select>
                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickSerach} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickDownload} className="btn btn-primary">Download</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Customer All KYC Documents Report"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default CustomerAllKYCDocumentsReport
