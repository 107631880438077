import React, { useState, useEffect } from 'react'
import { Col, Spin, Row, Select, Table, Radio, Divider, notification } from 'antd';
import { UserAPI } from '../../apis/UserAPI';
import { getUserRole } from '../../services/Token';

const { Option } = Select;

function UserPermission() {

    const [loading, setLoading] = useState(false);
    const [agentID, setAgentID] = useState('0');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [users, setUsers] = useState([]);
    const [masterPermissions, setMasterPermissions] = useState([]);

    useEffect(() => {

        setLoading(true);
        let data = { agent_id: 'testing' } //todat: will be revert

        UserAPI.getPermissionData(data)
            .then(res => {
                // console.log(res.data)
                setUsers(res.data.users);

                let data = [];
                let selectedKeyData = [];
                res.data.masterPermissions.forEach((per, i) => {

                    // let isPermission = false;
                    if (res.data.usersPermissions[`is_master_report_permission_id_${per.id}`]) {
                        // isPermission = true;
                        selectedKeyData[i] = per.id;
                    }

                    if (getUserRole() === 'Super Admin' && per.id === 44) {
                        data[i] = {
                            key: per.id,
                            permission_id: per.id,
                            report_title: per.master_report_title,
                            // is_permission: isPermission
                        };
                    } else if (per.id !== 44) {
                        data[i] = {
                            key: per.id,
                            permission_id: per.id,
                            report_title: per.master_report_title,
                            // is_permission: isPermission
                        };
                    }

                })

                setMasterPermissions(data);
                setSelectedRowKeys(selectedKeyData);
                setLoading(false)
            }).catch(err => {
                setLoading(false)
            });

    }, []);


    const columns = [
        {
            title: 'Reports',
            dataIndex: 'report_title',
            render: (text) => <a>{text}</a>,
        }
    ];

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            if (agentID == 0) {
                notification.error({ message: 'Please select a user.' });
                return false;
            }
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelectedRowKeys(selectedRowKeys)
            saveUserPermission(selectedRows);
        },
        // getCheckboxProps: (record) => ({
        //     disabled: record.report_title === 'Dashboard',
        //     key: record.key
        // })
    };

    const onChangeUser = (id) => {
        setSelectedRowKeys([])
        setAgentID(id)

        setLoading(true);
        let data = { agent_id: id }

        UserAPI.getUserPermission(data)
            .then(res => {

                let data = [];
                let selectedKeyData = [];
                res.data.masterPermissions.forEach((per, i) => {
                    if (res.data.usersPermissions[`is_master_report_permission_id_${per.id}`]) {
                        selectedKeyData[i] = per.id;
                    }

                    data[i] = {
                        key: per.id,
                        permission_id: per.id,
                        report_title: per.master_report_title
                    };

                })

                setMasterPermissions(data);
                setSelectedRowKeys(selectedKeyData);
                setLoading(false)
            }).catch(err => {
                setLoading(false)
            });

    }

    const saveUserPermission = (data) => {
        data = {
            agent_id: agentID,
            data
        }

        UserAPI.saveUserPermissions(data)
            .then(res => {
                notification.success({ message: `You have been changed permissions successfully.` });
            })
    }

    return (
        <div>
            <Spin spinning={loading} delay={500}>
                <div className="site-card-border-less-wrapper">
                    <Row gutter={16} className="row-heading">
                        <h3>User Permission</h3>
                    </Row>
                </div>

                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label >Select User</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => onChangeUser(val)}
                                    defaultValue="0"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    placeholder="All" className="select-content">
                                    <Option value="0">Select User</Option>
                                    {
                                        users.map((user) => {
                                            user.userName = user.userName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                                                return letra.toUpperCase();
                                            });

                                            return (
                                                <Option key={Math.random()} value={user.id}>{user.userName}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                        </Row>

                        <div>

                            <Table
                                rowSelection={{
                                    type: 'checkbox',
                                    ...rowSelection,
                                }}
                                columns={columns}
                                dataSource={masterPermissions}
                                pagination={false}
                            />
                        </div>
                    </div>

                </div>
            </Spin>
        </div>
    )
}

export default UserPermission
